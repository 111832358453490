import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Button,
  Tabs,
  Tab,
  Table,
  Form,
  Modal,
  Container,
  Alert
} from "react-bootstrap";
import { Link, Route, Switch } from "react-router-dom";
import api from "../config/api";
import {baseURL} from "../config/api";
import { getRequestConfig } from "../utils/getRequestConfig";
//add QC
const AddQualityControl = ({
  showAddQualityControl,
  closeAddQualityControl,
  addQualityControlHandler,
  projects,
  modules,
  employees,
  message
}) => {
  const [projectId, setProjectId] = useState("");
  const [moduleId, setModuleId] = useState("");
  const [type, setType] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [status, setStatus] = useState("New");
  const [devEstHours, setDevEstHours] = useState("");
  const [qaEstHours, setQaEstHours] = useState("");
  const [baEstHours, setBaEstHours] = useState("");
  const [devActHours, setDevActHours] = useState("");
  const [qaActHours, setQaActHours] = useState("");
  const [baActHours, setBaActHours] = useState("");
  const [devRemHours, setDevRemHours] = useState("");
  const [qaRemHours, setQaRemHours] = useState("");
  const [baRemHours, setBaRemHours] = useState("");
  const [priority, setPriority] = useState("");
  const [size, setSize] = useState("");
  const [sprint, setSprint] = useState("");
  const [dateAssigned, setDateAssigned] = useState("");
  const [dateCompletion, setDateCompletion] = useState("");
  const [devAssigned, setDevAssigned] = useState("");
  const [qaAssigned, setQaAssigned] = useState("");
  const [baAssigned, setBaAssigned] = useState("");
  const [attachmentUrl, setAttachmentUrl] = useState({});
  const [comments, setComments] = useState("");

  //task submit form handler
  const onSubmitHandler = (event) => {
    event.preventDefault();
    addQualityControlHandler(
      projectId,
      moduleId,
      type,
      title,
      description,
      status,
      devEstHours,
      qaEstHours,
      baEstHours,
      devActHours,
      qaActHours,
      baActHours,
      devRemHours,
      qaRemHours,
      baRemHours,
      priority,
      size,
      sprint,
      dateAssigned,
      dateCompletion,
      devAssigned,
      qaAssigned,
      baAssigned
    );
  };
  return (
    <Modal
      show={showAddQualityControl}
      onHide={closeAddQualityControl}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Add Task</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={onSubmitHandler}>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Project</Form.Label>
                <span style={{ color: "red" }}>*</span>
                <Form.Control
                  as="select"
                  onChange={(event) => setProjectId(event.target.value)}
                  required
                >
                  <option value="">Select</option>
                  {projects.map((project, index) => {
                    return (
                      <option key={index} value={project.id}>
                        {project.title}
                      </option>
                    );
                  })}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Module</Form.Label>
                <span style={{ color: "red" }}>*</span>
                <Form.Control
                  as="select"
                  onChange={(event) => setModuleId(event.target.value)}
                  required
                >
                  <option value="">Select</option>
                  {modules
                    .filter(
                      (selectedProject) =>
                        selectedProject.projectId == projectId
                    )
                    .map((module, index) => {
                      return (
                        <option key={index} value={module.id}>
                          {module.title}
                        </option>
                      );
                    })}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Type</Form.Label>
                <span style={{ color: "red" }}>*</span>
                <Form.Control
                  as="select"
                  onChange={(event) => setType(event.target.value)}
                  required
                >
                  <option>Select</option>
                  <option>Defect</option>
                  <option>Requirement</option>
                  <option>Enhancement</option>
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <Row>
           
            <Col>
              <Form.Group>
                <Form.Label>Priority</Form.Label>
                <Form.Control
                  as="select"
                  onChange={(event) => setPriority(event.target.value)}
                  
                >
                  <option value="">Select</option>
                  <option>Very high</option>
                  <option>High</option>
                  <option>Normal</option>
                  <option>Low</option>
                  <option>Very low</option>
                </Form.Control>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Size</Form.Label>
                <Form.Control
                  as="select"
                  onChange={(event) => setSize(event.target.value)}
                  
                >
                  <option value="">Select</option>
                  <option>X-small</option>
                  <option>Small</option>
                  <option>Medium</option>
                  <option>Large</option>
                  <option>X-large</option>
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <Form.Group>
            <Form.Label>Sprint</Form.Label>
            <Form.Control
              as="select"
              onChange={(event) => setSprint(event.target.value)}
            >
              <option value="">Select</option>
              <option>Sprint 1</option>
              <option>Sprint 2</option>
              <option>Sprint 3</option>
              <option>Sprint 4</option>
              <option>Sprint 5</option>
              <option>Sprint 6</option>
              <option>Sprint 7</option>
              <option>Sprint 8</option>
              <option>Sprint 9</option>
              <option>Sprint 10</option>
              <option>Sprint 11</option>
              <option>Sprint 12</option>
              <option>Sprint 13</option>
              <option>Sprint 14</option>
              <option>Sprint 15</option>
              <option>Sprint 16</option>
              <option>Sprint 17</option>
              <option>Sprint 18</option>
              <option>Sprint 19</option>
              <option>Sprint 20</option>
            </Form.Control>
          </Form.Group>

          <Form.Group>
            <Form.Label>Title</Form.Label>
            <span style={{ color: "red" }}>*</span>
            <Form.Control
              type="text"
              placeholder="title"
              onChange={(event) => setTitle(event.target.value)}
              required
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Description</Form.Label>
            <textarea
              placeholder="description"
              className="form-control"
              onChange={(event) => setDescription(event.target.value)}
            />
          </Form.Group>
          <h5>Estimate Hours</h5>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Ba Est Hours</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Ba Est Hours"
                  onChange={(event) => setBaEstHours(event.target.value)}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Qa Est Hours</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Qa Est Hours"
                  onChange={(event) => setQaEstHours(event.target.value)}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Dev Est Hours</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Dev Est Hours"
                  onChange={(event) => setDevEstHours(event.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>
          <h5>Actual Hours</h5>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Ba Act Hours</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Ba Act Hours"
                  onChange={(event) => setBaActHours(event.target.value)}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Qa Act Hours</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Qa Act Hours"
                  onChange={(event) => setQaActHours(event.target.value)}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>devActHours</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Dev Act Hours"
                  onChange={(event) => setDevActHours(event.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>
          <h5>Remaining Hours</h5>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Ba Rem Hours</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Ba rem hours"
                  onChange={(event) => setBaRemHours(event.target.value)}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Qa Rem Hours</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Qa Rem Hours"
                  onChange={(event) => setQaRemHours(event.target.value)}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Dev Rem Hours</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Dev Rem Hours"
                  onChange={(event) => setDevRemHours(event.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Date Assigned</Form.Label>
                <Form.Control
                  type="date"
                  placeholder="Ba rem hours"
                  onChange={(event) => setDateAssigned(event.target.value)}
                 
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Date Completion</Form.Label>
                <Form.Control
                  type="date"
                  placeholder="Date Completion"
                  onChange={(event) => setDateCompletion(event.target.value)}
               
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Ba Assigned</Form.Label>
                <Form.Control
                  as="select"
                  onChange={(event) => setBaAssigned(event.target.value)}
                >
                  <option value="">Select</option>
                  {employees.map((employee, index) => {
                    return (
                      <option key={index} value={employee.employeeId}>
                        {employee.name}
                      </option>
                    );
                  })}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Qa Assigned</Form.Label>
                <Form.Control
                  as="select"
                  onChange={(event) => setQaAssigned(event.target.value)}
                >
                  <option value="">Select</option>
                  {employees.map((employee, index) => {
                    return (
                      <option key={index} value={employee.employeeId}>
                        {employee.name}
                      </option>
                    );
                  })}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Dev Assigned</Form.Label>
                <Form.Control
                  as="select"
                  onChange={(event) => setDevAssigned(event.target.value)}
                >
                  <option value="">Select</option>
                  {employees.map((employee, index) => {
                    return (
                      <option key={index} value={employee.employeeId}>
                        {employee.name}
                      </option>
                    );
                  })}
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
            <br/>
            {
              message?message:""
            }
          <Button type="submit" className="box secondary">
            Add
          </Button>
          <Button
            style={{ marginLeft: "25px" }}
            className="box"
            onClick={closeAddQualityControl}
          >
            Close
          </Button>
        </Form>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
};

const EditQualityControl = ({
  task,
  showEditQualityControl,
  closeEditQualityControl,
  editQualityControlHandler,
  projects,
  modules,
  employees,
  attachments,
  comments,
  addAttachments,
  addComments,
  message,
  commentMessage,
  attachmentMessage
}) => {
  const [projectId, setProjectId] = useState(task.projectId);
  const [moduleId, setModuleId] = useState(task.moduleId);
  const [type, setType] = useState(task.type);
  const [title, setTitle] = useState(task.title);
  const [description, setDescription] = useState(task.description);
  const [status, setStatus] = useState(task.status);
  const [devEstHours, setDevEstHours] = useState(task.devEstHours);
  const [qaEstHours, setQaEstHours] = useState(task.qaEstHours);
  const [baEstHours, setBaEstHours] = useState(task.baEstHours);
  const [devActHours, setDevActHours] = useState(task.devActHours);
  const [qaActHours, setQaActHours] = useState(task.qaActHours);
  const [baActHours, setBaActHours] = useState(task.baActHours);
  const [devRemHours, setDevRemHours] = useState(task.devRemHours);
  const [qaRemHours, setQaRemHours] = useState(task.baRemHours);
  const [baRemHours, setBaRemHours] = useState(task.baRemHours);
  const [priority, setPriority] = useState(task.priority);
  const [size, setSize] = useState(task.size);
  const [sprint, setSprint] = useState(task.sprint);
  const [dateAssigned, setDateAssigned] = useState(task.dateAssigned);
  const [dateCompletion, setDateCompletion] = useState(task.dateCompletion);
  const [devAssigned, setDevAssigned] = useState(task.devAssigned);
  const [qaAssigned, setQaAssigned] = useState(task.qaAssigned);
  const [baAssigned, setBaAssigned] = useState(task.baAssigned);
  const [attachmentUrl, setAttachmentUrl] = useState({});
  const [comment, setComment] = useState("");
  const [tabSelected, setTabSelected] = useState("Comment");
  const taskId = task.id;

  //attachment submit form handler
  const onSubmitAttachmentHandler = (e) => {
    e.preventDefault();
    addAttachments(taskId, attachmentUrl);
  };

  //task submit form handler
  const onSubmitHandler = (event) => {
    event.preventDefault();
    editQualityControlHandler(
      taskId,
      projectId,
      moduleId,
      type,
      title,
      description,
      status,
      devEstHours,
      qaEstHours,
      baEstHours,
      devActHours,
      qaActHours,
      baActHours,
      devRemHours,
      qaRemHours,
      baRemHours,
      priority,
      size,
      sprint,
      dateAssigned,
      dateCompletion,
      devAssigned,
      qaAssigned,
      baAssigned
    );
  };
  return (
    <Modal
      show={showEditQualityControl}
      onHide={closeEditQualityControl}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Edit Task</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={onSubmitHandler} encType="multipart/form-data">
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Project</Form.Label>
                <span style={{ color: "red" }}>*</span>
                <Form.Control
                  as="select"
                  value={projectId}
                  onChange={(event) => setProjectId(event.target.value)}
                  required
                >
                  <option value="">Select</option>
                  {projects.map((project, index) => {
                    return (
                      <option key={index} value={project.id}>
                        {project.title}
                      </option>
                    );
                  })}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Module</Form.Label>
                <span style={{ color: "red" }}>*</span>
                <Form.Control
                  as="select"
                  value={moduleId}
                  onChange={(event) => setModuleId(event.target.value)}
                  required
                >
                  <option value="">Select</option>
                  {modules
                    .filter(
                      (selectedProject) =>
                        selectedProject.projectId == projectId
                    )
                    .map((module, index) => {
                      return (
                        <option key={index} value={module.id}>
                          {module.title}
                        </option>
                      );
                    })}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Type</Form.Label>
                <span style={{ color: "red" }}>*</span>
                <Form.Control
                  as="select"
                  value={type}
                  onChange={(event) => setType(event.target.value)}
                  required
                >
                  <option>Select</option>
                  <option>Defect</option>
                  <option>Requirement</option>
                  <option>Enhancement</option>
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Status</Form.Label>
                <span style={{ color: "red" }}>*</span>
                <Form.Control
                  as="select"
                  value={status}
                  onChange={(event) => setStatus(event.target.value)}
                  required
                >
                  <option value="">Select</option>
                  <option>New</option>
                  <option>Ready</option>
                  <option>Progress</option>
                  <option>Completed</option>
                  <option>Verify BA</option>
                  <option>Verify QA</option>
                  <option>Verify Dev</option>
                </Form.Control>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Priority</Form.Label>
                <Form.Control
                  as="select"
                  value={priority}
                  onChange={(event) => setPriority(event.target.value)}
             
                >
                  <option value="">Select</option>
                  <option>Very high</option>
                  <option>High</option>
                  <option>Normal</option>
                  <option>Low</option>
                  <option>Very low</option>
                </Form.Control>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Size</Form.Label>
                <Form.Control
                  as="select"
                  value={size}
                  onChange={(event) => setSize(event.target.value)}
                  
                >
                  <option value="">Select</option>
                  <option>X-small</option>
                  <option>Small</option>
                  <option>Medium</option>
                  <option>Large</option>
                  <option>X-large</option>
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <Form.Group>
            <Form.Label>Sprint</Form.Label>
            <Form.Control
              as="select"
              value={sprint}
              onChange={(event) => setSprint(event.target.value)}
            >
              <option value="">Select</option>
              <option>Sprint 1</option>
              <option>Sprint 2</option>
              <option>Sprint 3</option>
              <option>Sprint 4</option>
              <option>Sprint 5</option>
              <option>Sprint 6</option>
              <option>Sprint 7</option>
              <option>Sprint 8</option>
              <option>Sprint 9</option>
              <option>Sprint 10</option>
              <option>Sprint 11</option>
              <option>Sprint 12</option>
              <option>Sprint 13</option>
              <option>Sprint 14</option>
              <option>Sprint 15</option>
              <option>Sprint 16</option>
              <option>Sprint 17</option>
              <option>Sprint 18</option>
              <option>Sprint 19</option>
              <option>Sprint 20</option>
            </Form.Control>
          </Form.Group>

          <Form.Group>
            <Form.Label>Title</Form.Label>
            <span style={{ color: "red" }}>*</span>
            <Form.Control
              type="text"
              value={title}
              placeholder="title"
              onChange={(event) => setTitle(event.target.value)}
              required
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Description</Form.Label>
            <textarea
              placeholder="description"
              value={description}
              className="form-control"
              onChange={(event) => setDescription(event.target.value)}
            />
          </Form.Group>
          <h5>Estimate Hours</h5>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Ba Est Hours</Form.Label>
                <Form.Control
                  type="text"
                  value={baEstHours}
                  placeholder="Ba Est Hours"
                  onChange={(event) => setBaEstHours(event.target.value)}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Qa Est Hours</Form.Label>
                <Form.Control
                  type="text"
                  value={qaEstHours}
                  placeholder="Qa Est Hours"
                  onChange={(event) => setQaEstHours(event.target.value)}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Dev Est Hours</Form.Label>
                <Form.Control
                  type="text"
                  value={devEstHours}
                  placeholder="Dev Est Hours"
                  onChange={(event) => setDevEstHours(event.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>
          <h5>Actual Hours</h5>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Ba Act Hours</Form.Label>
                <Form.Control
                  type="text"
                  value={baActHours}
                  placeholder="Ba Act Hours"
                  onChange={(event) => setBaActHours(event.target.value)}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Qa Act Hours</Form.Label>
                <Form.Control
                  type="text"
                  value={qaActHours}
                  placeholder="Qa Act Hours"
                  onChange={(event) => setQaActHours(event.target.value)}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>devActHours</Form.Label>
                <Form.Control
                  type="text"
                  value={devActHours}
                  placeholder="Dev Act Hours"
                  onChange={(event) => setDevActHours(event.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>
          <h5>Remaining Hours</h5>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Ba Rem Hours</Form.Label>
                <Form.Control
                  type="text"
                  value={baRemHours}
                  placeholder="Ba rem hours"
                  onChange={(event) => setBaRemHours(event.target.value)}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Qa Rem Hours</Form.Label>
                <Form.Control
                  type="text"
                  value={qaRemHours}
                  placeholder="Qa Rem Hours"
                  onChange={(event) => setQaRemHours(event.target.value)}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Dev Rem Hours</Form.Label>
                <Form.Control
                  type="text"
                  value={devRemHours}
                  placeholder="Dev Rem Hours"
                  onChange={(event) => setDevRemHours(event.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Date Assigned</Form.Label>
                <Form.Control
                  type="date"
                  value={dateAssigned}
                  placeholder="Ba rem hours"
                  onChange={(event) => setDateAssigned(event.target.value)}
                  
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Date Completion</Form.Label>
                <Form.Control
                  type="date"
                  value={dateCompletion}
                  placeholder="Date Completion"
                  onChange={(event) => setDateCompletion(event.target.value)}
                  
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Ba Assigned</Form.Label>
                <Form.Control
                  as="select"
                  value={baAssigned}
                  onChange={(event) => setBaAssigned(event.target.value)}
                >
                  <option value="">Select</option>
                  {employees.map((employee, index) => {
                    return (
                      <option key={index} value={employee.employeeId}>
                        {employee.name}
                      </option>
                    );
                  })}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Qa Assigned</Form.Label>
                <Form.Control
                  as="select"
                  value={qaAssigned}
                  onChange={(event) => setQaAssigned(event.target.value)}
                >
                  <option value="">Select</option>
                  {employees.map((employee, index) => {
                    return (
                      <option key={index} value={employee.employeeId}>
                        {employee.name}
                      </option>
                    );
                  })}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Dev Assigned</Form.Label>
                <Form.Control
                  as="select"
                  value={devAssigned}
                  onChange={(event) => setDevAssigned(event.target.value)}
                >
                  <option value="">Select</option>
                  {employees.map((employee, index) => {
                    return (
                      <option key={index} value={employee.employeeId}>
                        {employee.name}
                      </option>
                    );
                  })}
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>

          <hr />
          {
            message?message:""
          }
          <Button type="submit" className="box secondary">
            Update
          </Button>
        </Form>
        <hr />
        <Tabs
          id="controlled-tab-example"
          activeKey={tabSelected}
          onSelect={(k) => setTabSelected(k)}
          style={{}}
        >
          <Tab eventKey="Comment" title="Comment">
            <br />


                <Form.Group>
                  <textarea
                    placeholder="comment"
                    className="form-control"
                    onChange={(event) => setComment(event.target.value)}
                    required
                  ></textarea>
                </Form.Group>
             
                {comment === ""? 
                  <Button
                    variant="dark"
                    className="box secondary"
                    onClick={() => addComments(taskId, comment)}
                    disabled
                  >
                    Add comment
                  </Button>
                :
                <Button
                    className="box secondary"
                    onClick={() => addComments(taskId, comment)}
                  >
                    Add comment
                  </Button>
              }
             
            {
              commentMessage?commentMessage:""
            }
            <Container
              style={{ display: "flex", flexDirection: "row", height: "15vh" }}
            >
              <div style={{ overflowY: "scroll", flex: "1" }}>
                {comments
                  .filter((filterComment) => filterComment.taskid === taskId)
                  .map((comment, index) => {
                    console.log(comment);
                    return (
                      <>
                        <hr />
                        <h5>
                          {comment.createdBy}
                          <small style={{ padding: "100px" }}>
                            {comment.createdAt}
                          </small>
                        </h5>
                        <p>{comment.comment}</p>

                      </>
                    );
                  })}
              </div>
            </Container>
          </Tab>
          <Tab eventKey="Attachment" title="Attachment">
            <br />
            <Form onSubmit={onSubmitAttachmentHandler}>
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Control
                      type="file"
                      placeholder="Attachment"
                      onChange={(event) =>
                        setAttachmentUrl(event.target.files[0])
                      }
                      required
                    ></Form.Control>
                  </Form.Group>
                </Col>
                <Col>
                  <Button type="submit" className="box secondary">
                    Add Attachment
                  </Button>
                </Col>
              </Row>
            </Form>
            {
              attachmentMessage?attachmentMessage:""
            }
            <Container
              style={{ display: "flex", flexDirection: "row", height: "15vh" }}
            >
              <div style={{ overflowY: "scroll", flex: "1" }}>
                {attachments
                  .filter(
                    (filterAttachment) => filterAttachment.taskid === taskId
                  )
                  .map((attachment, index) => {
                    return (
                      <>
                        <hr />
                        <h5>
                          {attachment.createdBy}
                          <small style={{ padding: "100px" }}>
                            {attachment.createdAt}
                          </small>
                        </h5>
                        <a href={baseURL +"/"+ attachment.attachmentUrl} download>
                          {attachment.attachmentUrl}
                        </a>
                      </>
                    );
                  })}
              </div>
            </Container>
          </Tab>
        </Tabs>
      </Modal.Body>
      <Modal.Footer>
        <Button
          style={{ marginLeft: "25px" }}
          className="box"
          onClick={closeEditQualityControl}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

//view task

const TaskItems = ({
  task,
  projects,
  employees,
  modules,
  attachments,
  comments,
  fetchTasks,
  fetchAttachments,
  fetchComments,
  setMessage,
  message,
  setTasks
}) => {
  const [showEditQualityControl, setShowEditQualityControl] = useState(false);
  const [commentMessage, setCommentMessage] = useState("");
  const [attachmentMessage, setAttachmentMessage] = useState("");
  const closeEditQualityControl = () => {
    setShowEditQualityControl(false);
  };
  const openEditQualityControl = () => {
    setShowEditQualityControl(true);
  };
  const editQualityControlHandler = (
    taskId,
    projectId,
    moduleId,
    type,
    title,
    description,
    status,
    devEstHours,
    qaEstHours,
    baEstHours,
    devActHours,
    qaActHours,
    baActHours,
    devRemHours,
    qaRemHours,
    baRemHours,
    priority,
    size,
    sprint,
    dateAssigned,
    dateCompletion,
    devAssigned,
    qaAssigned,
    baAssigned
  ) => {
    let content = {"projectId":projectId,"moduleId":moduleId,"type":type,"title":title,"description":description,"status":status,"devEstHours":devEstHours,"qaEstHours":qaEstHours,"baEstHours":baEstHours,"devActHours":devActHours,"qaActHours":qaActHours,"baActHours":baActHours,"devRemHours":devRemHours,"qaRemHours":qaRemHours,"baRemHours": baRemHours,"priority":priority,"size":size,"sprint":sprint,"dateAssigned":dateAssigned,"dateCompletion":dateCompletion,"devAssigned":devAssigned,"qaAssigned":qaAssigned,"baAssigned":baAssigned};
    
    api
      .put(`task/?id=${taskId}`, content, getRequestConfig())
      .then((response) => {
        setShowEditQualityControl(true);
        fetchTasks();
        setMessage(<Alert variant="success">Task Edited Successfully</Alert>)
    
    }).catch((ex)=>{
      setMessage(<Alert variant="danger">There is some error</Alert>)
    });
  };

  // add attchments
  const addAttachments = (taskId, attachmentUrl) => {
    let formData = new FormData();
    formData.append("taskId", taskId);
    formData.append("attachmentUrl", attachmentUrl);
    api
      .post(`task_attachment/`, formData, getRequestConfig())
      .then((response) => {
        fetchAttachments();
        fetchTasks();
        setAttachmentMessage(<Alert variant="success">Attachement Added Successfully</Alert>)
    
    }).catch((ex)=>{
      setAttachmentMessage(<Alert variant="danger">There is some error</Alert>)
    });
  };
  // add comments
  const addComments = (taskId, comment) => {
    let content = { taskId: taskId, comment: comment };
    api.post(`task_comments/`, content, getRequestConfig()).then((response) => {
      fetchComments();
      fetchTasks();
      setCommentMessage(<Alert variant="success">Comment Added Successfully</Alert>)
    
    }).catch((ex)=>{
      setCommentMessage(<Alert variant="danger">There is some error</Alert>)
    });
  };
  setTimeout(()=>{
    setAttachmentMessage("");
    setCommentMessage("");
    
  },10000);

  return (
    <>
      <tr className="task-item" onClick={openEditQualityControl}>
        <td>{task.project}</td>
        <td>{task.module}</td>
        <td>{task.type}</td>
        <td>{task.title}</td>
        <td>{task.status}</td>
        <td>{task.baAssigned}</td>
        <td>{task.devAssigned}</td>
        <td>{task.qaAssigned}</td>
        <td>{task.priority}</td>
        <td>{task.sprint}</td>
        
      </tr>
     
      <EditQualityControl
        showEditQualityControl={showEditQualityControl}
        closeEditQualityControl={closeEditQualityControl}
        editQualityControlHandler={editQualityControlHandler}
        projects={projects}
        modules={modules}
        task={task}
        employees={employees}
        attachments={attachments}
        comments={comments}
        fetchAttachments={fetchAttachments}
        fetchComments={fetchComments}
        addAttachments={addAttachments}
        addComments={addComments}
        message = {message}
        commentMessage = {commentMessage}
        attachmentMessage = {attachmentMessage}
      />
    </>
  );
};

const QualityControl = (userId) => {
  const [projects, setProjects] = useState([]);
  const [modules, setModules] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [attachments, setAttachments] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [comments, setComments] = useState([]);

  const [filterProject, setFilterProject] = useState(localStorage.getItem(`project1${userId}`)?localStorage.getItem(`project1${userId}`):[]);
  const [filterBA, setFilterBA] = useState(localStorage.getItem(`baAssigned${userId}`)?localStorage.getItem(`baAssigned${userId}`):[]);
  const [filterDev, setFilterDev] = useState(localStorage.getItem(`devAssigned${userId}`)?localStorage.getItem(`devAssigned${userId}`):[]);
  const [filterQA, setFilterQA] = useState(localStorage.getItem(`qaAssigned${userId}`)?localStorage.getItem(`qaAssigned${userId}`):[]);
  const [filterModule, setFilterModule] = useState(localStorage.getItem(`module${userId}`)?localStorage.getItem(`module${userId}`):[]);
  const [filterStatus, setFilterStatus] = useState(localStorage.getItem(`status${userId}`)?localStorage.getItem(`status${userId}`):[]);
  const [filterSprint, setFilterSprint] = useState(localStorage.getItem(`sprint${userId}`)?localStorage.getItem(`sprint${userId}`):[]);

  const [showAddQualityControl, setShowAddQualityControl] = useState(false);
  const [message, setMessage] = useState("");
  

  useEffect(() => {
    fetchProjects();
    fetchModules();
    fetchEmployees();

    fetchAttachments();
    fetchComments();
  }, []);

  useEffect(() => {
    fetchTasks(
      filterProject,
      filterBA,
      filterDev,
      filterQA,
      filterModule,
      filterStatus,
      filterSprint
    );
  }, [filterProject, filterBA,filterDev,filterQA, filterModule, filterStatus, filterSprint]);
  //reset filter
  const resetFilter = () => {
    fetchTasks([]);
    localStorage.setItem(`project1${userId}`,"");
    setFilterProject(localStorage.getItem(`project1${userId}`));
    localStorage.setItem(`baAssigned${userId}`,"");
    setFilterBA(localStorage.getItem(`baAssigned${userId}`));
    localStorage.setItem(`devAssigned${userId}`,"");
    setFilterDev(localStorage.getItem(`devAssigned${userId}`));
    localStorage.setItem(`qaAssigned${userId}`,"");
    setFilterQA(localStorage.getItem(`qaAssigned${userId}`));
    localStorage.setItem(`module${userId}`,"");
    setFilterModule(localStorage.getItem(`module${userId}`));
    localStorage.setItem(`status${userId}`,"");
    setFilterStatus(localStorage.getItem(`status${userId}`));
    localStorage.setItem(`sprint${userId}`,"");
    setFilterSprint(localStorage.getItem(`sprint${userId}`));
  };
  //filter session handler 
  const sessionHandler = (event)=>{
    let name = event.target.name;
    let value=event.target.value;
    if(name=="project")
    {
        localStorage.setItem(`project1${userId}`, value);
        setFilterProject(localStorage.getItem(`project1${userId}`));
    }
    else if(name=="module")
    {
      localStorage.setItem(`module${userId}`, value);
      setFilterModule(localStorage.getItem(`module${userId}`));
    }
    else if(name=="status")
    {
      localStorage.setItem(`status${userId}`, value);
      setFilterStatus(localStorage.getItem(`status${userId}`));
    }
    else if(name=="baAssigned")
    {
      localStorage.setItem(`baAssigned${userId}`, value);
      setFilterBA(localStorage.getItem(`baAssigned${userId}`));
    }
    else if(name=="qaAssigned")
    {
      localStorage.setItem(`qaAssigned${userId}`, value);
      setFilterQA(localStorage.getItem(`qaAssigned${userId}`));
    }
    else if(name=="devAssigned")
    {
      localStorage.setItem(`devAssigned${userId}`, value);
      setFilterDev(localStorage.getItem(`devAssigned${userId}`));
    }
    else if(name=="sprint")
    {
      localStorage.setItem(`sprint${userId}`, value);
      setFilterSprint(localStorage.getItem(`sprint${userId}`));
    }

}
  const fetchTasks = (
    filterProject,
    filterBA,
    filterDev,
    filterQA,
    filterModule,
    filterStatus,
    filterSprint
  ) => {
    api
      .get(
        `task/?project_id=${filterProject ? filterProject : localStorage.getItem(`project1${userId}`)}&ba=${
          filterBA ? filterBA : localStorage.getItem(`baAssigned${userId}`)
        }&dev=${
          filterDev ? filterDev : localStorage.getItem(`devAssigned${userId}`)
        }&qa=${
          filterQA ? filterQA : localStorage.getItem(`qaAssigned${userId}`)
        }&module_id=${filterModule ? filterModule : localStorage.getItem(`module${userId}`)}&status=${
          filterStatus ? filterStatus : localStorage.getItem(`status${userId}`)
        }&sprint=${filterSprint ? filterSprint : localStorage.getItem(`sprint${userId}`)}`,
        getRequestConfig()
      )
      .then((response) => {
        setTasks([]);
        setTasks(response.data);
       
      });
  };
  //get projects
  const fetchProjects = () => {
    api.get("project/", getRequestConfig()).then((response) => {
      setProjects(response.data);
    });
  };
  //get modules
  const fetchModules = () => {
    api.get("module/", getRequestConfig()).then((response) => {
      setModules(response.data);
     
    });
  };

  //get attachment
  const fetchAttachments = () => {
    api.get("task_attachment/", getRequestConfig()).then((response) => {
      setAttachments(response.data);
    });
  };

  //get comments
  const fetchComments = () => {
    api.get("task_comments/", getRequestConfig()).then((response) => {
      setComments(response.data);
    });
  };
  //get employee
  //get project
  const fetchEmployees = () => {
    api.get("employee/", getRequestConfig()).then((response) => {
      setEmployees(response.data);
      console.log(response.data);
    });
  };

  //add quality control
  const closeAddQualityControl = () => {
    setShowAddQualityControl(false);
  };
  const openAddQualityControl = () => {
    setShowAddQualityControl(true);
  };
  const addQualityControlHandler = (
    projectId,
    moduleId,
    type,
    title,
    description,
    status,
    devEstHours,
    qaEstHours,
    baEstHours,
    devActHours,
    qaActHours,
    baActHours,
    devRemHours,
    qaRemHours,
    baRemHours,
    priority,
    size,
    sprint,
    dateAssigned,
    dateCompletion,
    devAssigned,
    qaAssigned,
    baAssigned
  ) => {

    let content = {"projectId":projectId,"moduleId":moduleId,"type":type,"title":title,"description":description,"status":status,"devEstHours":devEstHours,"qaEstHours":qaEstHours,"baEstHours":baEstHours,"devActHours":devActHours,"qaActHours":qaActHours,"baActHours":baActHours,"devRemHours":devRemHours,"qaRemHours":qaRemHours,"baRemHours": baRemHours,"priority":priority,"size":size,"sprint":sprint,"dateAssigned":dateAssigned,"dateCompletion":dateCompletion,"devAssigned":devAssigned,"qaAssigned":qaAssigned,"baAssigned":baAssigned};
    

    api.post("task/", content, getRequestConfig()).then((response) => {
      console.log(response.data);
      setShowAddQualityControl(true);
      fetchTasks();
      setMessage(<Alert variant="success">Task Added Successfully</Alert>)
    
    }).catch((ex)=>{
      setMessage(<Alert variant="danger">There is some error</Alert>)
    });
  };
  setTimeout(()=>{
    setMessage("");
    
  },10000);
  return (
    <>
      <Row>
        <Col sm={12} md={6} lg={6} style={{ padding: "5px" }}>
          {""}
          <h3>Quality Control</h3>
        </Col>
        <Col sm={12} md={6} lg={6} style={{ padding: "5px",textAlign:"right" }}>
        <Button
           
            variant="dark"
            onClick={resetFilter}
          >
            Reset
          </Button>
          <Button onClick={openAddQualityControl} style={{ marginLeft: "5px" }}>
        Add Task
      </Button>
        </Col>
      </Row>
      <Row>
        
        <Col sm={12} md={2} lg={2} style={{ padding: "5px" }}>
          <Form.Group>
            <Form.Label>Project</Form.Label>
            <Form.Control
              as="select"
              value={filterProject}
              name="project"
              onChange={sessionHandler}
              required
            >
              <option value="">All</option>
              {projects.map((project, index) => {
                return (
                  <option key={index} value={project.id}>
                    {project.title}
                  </option>
                );
              })}
            </Form.Control>
          </Form.Group>
        </Col>
        <Col sm={12} md={2} lg={2} style={{ padding: "5px" }}>
          <Form.Group>
            <Form.Label>Module</Form.Label>
            <Form.Control
              as="select"
              value={filterModule}
              name="module"
              onChange={sessionHandler}
              required
            >
              <option value="">All</option>
              { modules
                    .filter(
                      (module) =>
                        module.projectId === filterProject
                    )
                .map((module, index) => {
                return (
                  <option key={index} value={module.id}>
                    {module.title}
                  </option>
                );
              })}
            </Form.Control>
          </Form.Group>
        </Col>
        <Col sm={12} md={1} lg={1} style={{ padding: "5px" }}>
          <Form.Group>
            <Form.Label>Status</Form.Label>
            <Form.Control
              as="select"
              value={filterStatus}
              name="status"
              onChange={sessionHandler}
            >
              <option value="">All</option>
              <option>New</option>
              <option>Ready</option>
              <option>Progress</option>
              <option>Completed</option>
              <option>Verify dev</option>
            </Form.Control>
          </Form.Group>
        </Col>
        <Col sm={12} md={2} lg={2} style={{ padding: "5px" }}>
          <Form.Group>
            <Form.Label>BA Assigned</Form.Label>
            <Form.Control
              as="select"
              value={filterBA}
              name="baAssigned"
              onChange={sessionHandler}
            >
              <option value="">All</option>
              {employees.map((employee, index) => {
                return (
                  <option key={index} value={employee.employeeId}>
                    {employee.name}
                  </option>
                );
              })}
            </Form.Control>
          </Form.Group>
        </Col>
        <Col sm={12} md={2} lg={2} style={{ padding: "5px" }}>
          <Form.Group>
            <Form.Label>Dev Assigned</Form.Label>
            <Form.Control
              as="select"
              value={filterDev}
              name="devAssigned"
              onChange={sessionHandler}
            >
              <option value="">All</option>
              {employees.map((employee, index) => {
                return (
                  <option key={index} value={employee.employeeId}>
                    {employee.name}
                  </option>
                );
              })}
            </Form.Control>
          </Form.Group>
        </Col>
        <Col sm={12} md={2} lg={2} style={{ padding: "5px" }}>
          <Form.Group>
            <Form.Label>QA Assigned</Form.Label>
            <Form.Control
              as="select"
              value={filterQA}
              name="qaAssigned"
              onChange={sessionHandler}
            >
              <option value="">All</option>
              {employees.map((employee, index) => {
                return (
                  <option key={index} value={employee.employeeId}>
                    {employee.name}
                  </option>
                );
              })}
            </Form.Control>
          </Form.Group>
        </Col>
        <Col sm={12} md={1} lg={1} style={{ padding: "5px" }}>
          <Form.Group>
            <Form.Label>Sprint</Form.Label>
            <Form.Control
              as="select"
              value={filterSprint}
              name="sprint"
              onChange={sessionHandler}
            >
              <option value="">All</option>
              <option>Sprint 1</option>
              <option>Sprint 2</option>
              <option>Sprint 3</option>
              <option>Sprint 4</option>
              <option>Sprint 5</option>
              <option>Sprint 6</option>
              <option>Sprint 7</option>
              <option>Sprint 8</option>
              <option>Sprint 9</option>
              <option>Sprint 10</option>
              <option>Sprint 11</option>
              <option>Sprint 12</option>
              <option>Sprint 13</option>
              <option>Sprint 14</option>
              <option>Sprint 15</option>
              <option>Sprint 16</option>
              <option>Sprint 17</option>
              <option>Sprint 18</option>
              <option>Sprint 19</option>
              <option>Sprint 20</option>
            </Form.Control>
          </Form.Group>
        </Col>
       
              
      </Row>
      

     

      <Table className="table table-bordered">
        <thead>
          <tr>
            
            <th>Project</th>
            <th>Module</th>
            <th>Type</th>
            <th>Title</th>
            <th>Status</th>
            <th>BA Assigned</th>
            <th>Dev Assigned</th>
            <th>QA Assigned</th>
            <th>Priority</th>
            <th>Sprint</th>
          </tr>
        </thead>
        <tbody>
          {tasks.length === 0 && <h4>No Record Found</h4>}
          {tasks.length !== 0 &&
            tasks.map((task, index) => (
              <TaskItems
                key={index}
                task={task}
                projects={projects}
                modules={modules}
                employees={employees}
                attachments={attachments}
                comments={comments}
                fetchTasks={fetchTasks}
                fetchAttachments={fetchAttachments}
                fetchComments={fetchComments}
                setMessage = {setMessage}
                message = {message}
                setTasks={setTasks}
              />
            ))}
        </tbody>
      </Table>
      <AddQualityControl
        showAddQualityControl={showAddQualityControl}
        closeAddQualityControl={closeAddQualityControl}
        addQualityControlHandler={addQualityControlHandler}
        projects={projects}
        modules={modules}
        employees={employees}
        message = {message}
      />
    </>
  );
};
export default QualityControl;
