import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Table,
  span,
  Nav,
  Button,
  Modal,
  Form,
} from "react-bootstrap";
import api from "../../config/api";

import { generatePdf } from "../../utils/payslipPdf";

import { getRequestConfig } from "../../utils/getRequestConfig";

//shows all employee pay slip items

const PaySlipItems = ({ paySlip, downloadPayslip }) => {
  return (
    <tr>
      <td>{paySlip.monthYear}</td>
      <td>{paySlip.grossEarnings}</td>
      <td>{paySlip.grossDeductions}</td>
      <td>{paySlip.netpay}</td>
      <td>
        <Button onClick={() => downloadPayslip(paySlip)}>
          Download Payslip
        </Button>
      </td>
    </tr>
  );
};
const PaySlip = (userId) => {
  const [paySlips, setPaySlips] = useState([]);
  const [filterMonth, setFilterMonth] = useState(localStorage.getItem(`month6${userId}`)?localStorage.getItem(`month6${userId}`):[]);
  const [filterYear, setFilterYear] = useState(localStorage.getItem(`year6${userId}`)?localStorage.getItem(`year6${userId}`):[]);
 
  useEffect(() => {
    fetchPaySlips(filterMonth, filterYear);
  }, [filterMonth, filterYear]);
  const resetFilter = () => {
    fetchPaySlips("");
    localStorage.setItem(`month6${userId}`,"");
    setFilterMonth(localStorage.getItem(`month6${userId}`));
    localStorage.setItem(`year6${userId}`,"");
    setFilterYear(localStorage.getItem(`year6${userId}`));
  };

  
  const sessionHandler = (event)=>{
    let name = event.target.name;
    let value=event.target.value;
    if(name=="month")
    {
      localStorage.setItem(`month6${userId}`, value);
      setFilterMonth(localStorage.getItem(`month6${userId}`));
    }
    else if(name=="year")
    {
      localStorage.setItem(`year6${userId}`, value);
      setFilterYear(localStorage.getItem(`year6${userId}`));
    }

}
  //get Daily report
  const fetchPaySlips = (filterMonth, filterYear) => {
    api
      .get(
        `salary/?month=${filterMonth ? filterMonth : localStorage.getItem(`month6${userId}`)}&year=${
          filterYear ? filterYear : localStorage.getItem(`year6${userId}`)
        }`,
        getRequestConfig()
      )
      .then((response) => {
        setPaySlips([]);
        setPaySlips(response.data);
      })
      .catch((err) => console.log(err));
  };

  //get years
  let rangeYears = [];
  for (let i = 12; i > 0; i--) {
    rangeYears.push(new Date().getFullYear() - i);
  }
  for (let i = 0; i < 12; i++) {
    rangeYears.push(new Date().getFullYear() + i);
  }
  //download payslip
  const downloadPayslip = (paySlip) => {
    generatePdf(paySlip);
  };
  return (
    <>
      <Row>
        <Col sm={12} md={6} lg={6} style={{ padding: "5px" }}>
          {" "}
          <h3>Pay Slip</h3>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group>
            <Form.Label>Month</Form.Label>
            <Form.Control
              as="select"
              value={filterMonth}
              name="month"
              onChange={sessionHandler}
              required
            >
              <option value="">Select</option>
              <option>01</option>
              <option>02</option>
              <option>03</option>
              <option>04</option>
              <option>05</option>
              <option>06</option>
              <option>07</option>
              <option>08</option>
              <option>09</option>
              <option>10</option>
              <option>11</option>
              <option>12</option>
            </Form.Control>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Label>Year</Form.Label>
            <Form.Control
              as="select"
              value={filterYear}
              name="year"
              onChange={sessionHandler}
              required
            >
              <option value="">Select</option>
              {rangeYears.map((rangeYear, index) => {
                return <option key={index}>{rangeYear}</option>;
              })}
            </Form.Control>
          </Form.Group>
        </Col>

        <Col style={{ padding: "12px" }}>
          <br />

          <Button
            style={{ marginLeft: "10px" }}
            variant="dark"
            onClick={resetFilter}
          >
            Reset
          </Button>
        </Col>
      </Row>
      <Table className="table table-bordered" id="tb1">
        <thead>
          <tr>
            <th>Month Year</th>
            <th>Gross Earnings</th>
            <th>Gross Deductions</th>
            <th>Net Pay</th>
            <th>Download PaySlip</th>
          </tr>
        </thead>
        <tbody>
          {paySlips.length === 0 && <h3>Loading...</h3>}
          {paySlips.length !== 0 &&
            paySlips.map((paySlip, index) => (
              <PaySlipItems
                key={index}
                paySlip={paySlip}
                downloadPayslip={downloadPayslip}
              />
            ))}
        </tbody>
      </Table>
    </>
  );
};
export default PaySlip;
