import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Table,
  span,
  Nav,
  Button,
  Modal,
  Form,
  Alert
} from "react-bootstrap";
import api from "../../config/api";
import { getRequestConfig } from "../../utils/getRequestConfig";

const AddModule = ({
  showAddModule,
  closeAddModule,
  addModuleHandler,
  projects,
  message
}) => {
  const [projectId, setProjectId] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  const onSubmitHandler = (event) => {
    event.preventDefault();
    addModuleHandler(projectId, title, description);
  };
  return (
    <Modal
      show={showAddModule}
      onHide={closeAddModule}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Add Module</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={onSubmitHandler}>
          <Form.Group>
            <Form.Label>Project</Form.Label>
            <span style={{ color: "red" }}>*</span>
            <Form.Control
              as="select"
              value={projectId}
              onChange={(event) => setProjectId(event.target.value)}
              required
            >
              <option value="">Select</option>
              {projects.map((project, index) => {
                return (
                  <option key={index} value={project.id}>
                    {project.title}
                  </option>
                );
              })}
            </Form.Control>
          </Form.Group>

          <Form.Group>
            <Form.Label>Title</Form.Label>
            <span style={{ color: "red" }}>*</span>
            <Form.Control
              type="text"
              onChange={(event) => setTitle(event.target.value)}
              required
            />
          </Form.Group>

          <Form.Group>
            <Form.Label>Description</Form.Label>
            <textarea
              className="form-control"
              placeholder="description"
              onChange={(event) => setDescription(event.target.value)}
            />

          </Form.Group>
          <br/>
          {
            message?message:""
          }
          <Button type="submit" className="box secondary">
            Add
          </Button>
          <Button
            style={{ marginLeft: "25px" }}
            className="box"
            onClick={closeAddModule}
          >
            Close
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

//edit daily report
const EditModule = ({
  showEditModule,
  closeEditModule,
  editModuleHandler,
  module,
  projects,
  message
}) => {
  const [projectId, setProjectId] = useState(module.projectId);
  const [title, setTitle] = useState(module.title);
  const [description, setDescription] = useState(module.description);
  const moduleId = module.id;

  const onSubmitHandler = (event) => {
    event.preventDefault();
    editModuleHandler(moduleId, projectId, title, description);
  };
  return (
    <Modal
      show={showEditModule}
      onHide={closeEditModule}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Edit Module</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={onSubmitHandler}>
          <Form.Group>
            <Form.Label>Project</Form.Label>
            <span style={{ color: "red" }}>*</span>
            <Form.Control
              as="select"
              value={projectId}
              onChange={(event) => setProjectId(event.target.value)}
              required
            >
              <option value="">Select</option>
              {projects.map((project, index) => {
                return (
                  <option key={index} value={project.id}>
                    {project.title}
                  </option>
                );
              })}
            </Form.Control>
          </Form.Group>

          <Form.Group>
            <Form.Label>Title</Form.Label>
            <span style={{ color: "red" }}>*</span>
            <Form.Control
              type="text"
              value={title}
              onChange={(event) => setTitle(event.target.value)}
              required
            />
          </Form.Group>

          <Form.Group>
            <Form.Label>Description</Form.Label>
            <textarea
              className="form-control"
              placeholder="description"
              value={description}
              onChange={(event) => setDescription(event.target.value)}
            />
          </Form.Group>
          <br/>
          {
            message?message:""
          }
          <Button type="submit" className="box secondary">
            Edit
          </Button>
          <Button
            style={{ marginLeft: "25px" }}
            className="box"
            onClick={closeEditModule}
          >
            Close
          </Button>
        </Form>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
};

// shows all employee daily reports

const ModuleItems = ({ module, projects, fetchModules,setMessage,message }) => {
  const [showEditModule, setShowEditModule] = useState(false);
  const closeEditModule = () => {
    setShowEditModule(false);
  };
  const openEditModule = () => {
    setShowEditModule(true);
  };

  const editModuleHandler = (moduleId, projectId, title, description) => {
    let content = {
      projectId: projectId,
      title: title,
      description: description,
    };
    api
      .put(`module/?id=${moduleId}`, content, getRequestConfig())
      .then((response) => {
        setShowEditModule(true);
        fetchModules();
        setMessage(<Alert variant="success">Module Edited Successfully</Alert>);
    
    }).catch((ex)=>{
      setMessage(<Alert variant="danger">There is some error</Alert>);
    });
  }
  return (
    <>
      <tr>
        <td>{module.projectTitle} </td>
        <td>{module.title} </td>
        <td>{module.description} </td>
        <td>
          <Button style={{ marginRight: "10px" }} onClick={openEditModule}>
            Edit Module
          </Button>
        </td>
      </tr>

      <EditModule
        showEditModule={showEditModule}
        closeEditModule={closeEditModule}
        editModuleHandler={editModuleHandler}
        module={module}
        projects={projects}
        message = {message}
      />
    </>
  );
};

const Module = () => {
  const [projects, setProjects] = useState([]);
  const [modules, setModules] = useState([]);
  const [showAddModule, setShowAddModule] = useState(false);
  const [message, setMessage] = useState("");
  useEffect(() => {
    fetchProjects();
    fetchModules();
  }, []);

  //get Daily report
  const fetchModules = () => {
    api.get(`module/`, getRequestConfig()).then((response) => {
      setModules(response.data);
    });
  };
  //get project
  const fetchProjects = () => {
    api.get("project/", getRequestConfig()).then((response) => {
      setProjects(response.data);
    });
  };

  const closeAddModule = () => {
    setShowAddModule(false);
  };
  const openAddModule = () => {
    setShowAddModule(true);
  };

  const addModuleHandler = (projectId, title, description) => {
    let content = {
      projectId: projectId,
      title: title,
      description: description,
    };

    api.post("module/", content, getRequestConfig()).then((response) => {
      setShowAddModule(true);
      fetchModules();
      setMessage(<Alert variant="success">Module Added Successfully</Alert>)
    
    }).catch((ex)=>{
      setMessage(<Alert variant="danger">There is some error</Alert>)
    });
  };

  setTimeout(()=>{
    setMessage("");
    
  },10000);
  return (
    <div>
      <Row>
        <Col sm={12} md={6} lg={6} style={{ padding: "5px" }}>
          {" "}
          <h3>Module</h3>
        </Col>
        <Col sm={12} md={6} lg={6} style={{ padding: "5px" }}>
          <Button onClick={openAddModule} style={{ float: "right" }}>
            Add Module
          </Button>
        </Col>
      </Row>

      <br />
      <Table className="table table-bordered">
        {modules.length === 0 && <h3>Loading...</h3>}
        <thead>
          <tr>
            <th>Project</th>
            <th>Title</th>
            <th>Description</th>
            <th>Edit</th>
          </tr>
        </thead>
        <tbody>
          {modules.length !== 0 &&
            modules.map((module, index) => (
              <ModuleItems
                key={index}
                module={module}
                projects={projects}
                fetchModules={fetchModules}
                setMessage = {setMessage}
                message={message}
              />
            ))}
        </tbody>
      </Table>
      <AddModule
        showAddModule={showAddModule}
        closeAddModule={closeAddModule}
        addModuleHandler={addModuleHandler}
        projects={projects}
        message={message}
      />
    </div>
  );
};
export default Module;
