import React,{useState,useEffect} from "react";
import { Row, Col,Button,Dropdown,Modal,Form,Alert } from "react-bootstrap";
import api from "../config/api";
import {baseURL} from "../config/api";
import {Link} from "react-router-dom";
import ChangePassword from "./ChangePassword";
import EditProfile from "./EditProfile";
import { getRequestConfig } from "../utils/getRequestConfig";
const Header = ({ user, setUser }) => {

  let role = JSON.parse(user).employee.role;
  let name = JSON.parse(user).employee.name;
  let employeeId = JSON.parse(user).employee.employee_id;
  let profileImage = JSON.parse(user).employee.photo_url;
  const [showChangePassword, setShowChangePassword] = useState(false);
  const [showEditProfile, setShowEditProfile] = useState(false);
  

  const logoutSubmit=() =>{
    localStorage.removeItem("loggedUser");
    setUser("");
  }


  const closeEditProfile = () =>{
    setShowEditProfile(false);
  }
  const openEditProfile = () =>{
    setShowEditProfile(true);
  }
  const editProfileHandler = (formData,setMessage) =>{
   
    api.post("employee/", formData, getRequestConfig()).then((response)=>{
      setShowEditProfile(true);
      console.log(response.data);
    setMessage(<Alert variant="success">{"successfully updated"}</Alert>);
    
  
    }).catch((err) => {
      setMessage(<Alert variant="danger">{"there is some error"}</Alert>);
        console.log(err);
    })
    
   
  }


  //change password
  const closeChangePassword = () =>{
    setShowChangePassword(false);
  }
  const openChangePassword = () =>{
      setShowChangePassword(true);
  }
  const changePasswordHandler = (newPassword,confirmPassword,setErrors, setMessage,resetFields) =>{
    let content = {"newPassword":confirmPassword}
    console.log(newPassword, confirmPassword);
    if(newPassword===confirmPassword)
    {
      api.put(`change_password/?employeeId=${employeeId}`,content,getRequestConfig()).then((response)=>{
        setShowChangePassword(true);
        setMessage(<Alert variant="success">{"password successfully changed"}</Alert>);
        resetFields("");
        setErrors("");
      })
    }else{
      setMessage(<Alert variant="danger">{"there is some error"}</Alert>);
    }

    
    
}
  return (
    <>
    <Row className="header">
      <Col>
        <h4>
          <img
            src={baseURL+"/"+ profileImage}
            className="avatar rounded-circle"
            alt="avatar"
          />
          Welcome {name}
        </h4>
      </Col>
      <Col style={{ textAlign: "right", paddingRight: "15px" }}>
      <Dropdown>
        <Dropdown.Toggle nvariant="success" id="dropdown-basic">
          Settings
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {
            role ==="admin"?"":<Dropdown.Item onClick={openEditProfile}>Edit Profile</Dropdown.Item>
          }
          <Dropdown.Item onClick={openChangePassword}>Change Password</Dropdown.Item>
          <Dropdown.Item><Link onClick={logoutSubmit} to="/home">Logout</Link></Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
        
      </Col>
    </Row>
    <EditProfile  
    showEditProfile = {showEditProfile}
    closeEditProfile = {closeEditProfile}
    editProfileHandler = {editProfileHandler}
    />
    <ChangePassword  
    showChangePassword = {showChangePassword}
    closeChangePassword = {closeChangePassword}
    changePasswordHandler = {changePasswordHandler}
    />

    </>
  );
};
export default Header;
