import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Table,
  span,
  Nav,
  Button,
  Modal,
  Form,
  Alert
} from "react-bootstrap";
import api from "../../config/api";
import { getRequestConfig } from "../../utils/getRequestConfig";

const AddDailyReport = ({
  showAddReport,
  closeAddReport,
  addDailyReportHandler,
  projects,
  tasks,
  message
}) => {
  // get current date
  var today = new Date(),
    currentdate =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1 < 10
        ? "0" + (today.getMonth() + 1)
        : today.getMonth() + 1) +
      "-" +
      today.getDate();

  const [projectId, setProjectId] = useState([]);
  const [taskId, setTaskId] = useState([]);
  const [date, setDate] = useState(currentdate);
  const [activity, setActivity] = useState([]);
  const [hoursSpent, setHoursSpent] = useState([]);
  const [comment, setComment] = useState([]);

  const onSubmitHandler = (event) => {
    event.preventDefault();
    addDailyReportHandler(
      projectId,
      taskId,
      date,
      activity,
      hoursSpent,
      comment
    );
  };
  return (
    <Modal
      show={showAddReport}
      onHide={closeAddReport}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Add Report</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={onSubmitHandler}>
          <Form.Group>
            <Form.Label>Project</Form.Label>
            <span style={{ color: "red" }}>*</span>

            <Form.Control
              as="select"
              onChange={(event) => setProjectId(event.target.value)}
              required
            >
              <option value="">Select</option>
              {projects.map((project, index) => {
                return (
                  <option key={index} value={project.id}>
                    {project.title}
                  </option>
                );
              })}
            </Form.Control>
          </Form.Group>
          <Form.Group>
            <Form.Label>Task</Form.Label>
            <span style={{ color: "red" }}>*</span>
            <Form.Control
              as="select"
              onChange={(event) => setTaskId(event.target.value)}
              required
            >
              <option value="">Select</option>
              {tasks
                .filter(
                  (selectedProject) => selectedProject.projectId == projectId
                )
                .map((task, index) => {
                  return (
                    <option key={index} value={task.id}>
                      {task.title}
                    </option>
                  );
                })}
            </Form.Control>
          </Form.Group>
          <Form.Group>
            <Form.Label>Date</Form.Label>
            <span style={{ color: "red" }}>*</span>
            <Form.Control
              type="date"
              defaultValue={date}
              onChange={(event) => setDate(event.target.value)}
              required
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Activity</Form.Label>
            <span style={{ color: "red" }}>*</span>
            <Form.Control
              as="select"
              onChange={(event) => setActivity(event.target.value)}
              required
            >
              <option value="">Select</option>
              <option>Development</option>
              <option>Design</option>
              <option>Testing</option>
              <option>Data Entry</option>
              <option>Documentation</option>
            </Form.Control>
          </Form.Group>
          <Form.Group>
            <Form.Label>Hours Spent</Form.Label>
            <span style={{ color: "red" }}>*</span>
            <Form.Control
              type="text"
              placeholder="hours spend"
              onChange={(event) => setHoursSpent(event.target.value)}
              required
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Comment</Form.Label>
            <textarea
              className="form-control"
              placeholder="comment"
              onChange={(event) => setComment(event.target.value)}
            />
          </Form.Group>
          <hr />
          {
            message?message:""
          }
          <Button type="submit" className="box secondary">
            Add
          </Button>
          <Button
            style={{ marginLeft: "25px" }}
            className="box"
            onClick={closeAddReport}
          >
            Close
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

//edit daily report
const EditDailyReport = ({
  showEditDailyReport,
  closeEditReport,
  editDailyReportHandler,
  dailyReport,
  projects,
  tasks,
  message
}) => {
  const [projectId, setProjectId] = useState(dailyReport.projectId);
  const [taskId, setTaskId] = useState(dailyReport.taskId);
  const [date, setDate] = useState(dailyReport.date);
  const [activity, setActivity] = useState(dailyReport.activity);
  const [hoursSpent, setHoursSpent] = useState(dailyReport.hoursSpent);
  const [comment, setComment] = useState(dailyReport.comment);
  const dailyReportId = dailyReport.id;

  const onSubmitHandler = (event) => {
    event.preventDefault();
    editDailyReportHandler(
      dailyReportId,
      projectId,
      taskId,
      date,
      activity,
      hoursSpent,
      comment
    );
  };
  return (
    <Modal
      show={showEditDailyReport}
      onHide={closeEditReport}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Edit Report</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={onSubmitHandler}>
          <Form.Group>
            <Form.Label>Project</Form.Label>
            <span style={{ color: "red" }}>*</span>
            <Form.Control
              as="select"
              value={projectId}
              onChange={(event) => setProjectId(event.target.value)}
              required
            >
              <option value="">Select</option>
              {projects.map((project, index) => {
                return (
                  <option key={index} value={project.id}>
                    {project.title}
                  </option>
                );
              })}
            </Form.Control>
          </Form.Group>
          <Form.Group>
            <Form.Label>Task</Form.Label>
            <span style={{ color: "red" }}>*</span>
            <Form.Control
              as="select"
              value={taskId}
              onChange={(event) => setTaskId(event.target.value)}
              required
            >
              <option value="">Select</option>
              {tasks
                .filter(
                  (selectedProject) => selectedProject.projectId == projectId
                )
                .map((task, index) => {
                  return (
                    <option key={index} value={task.id}>
                      {task.title}
                    </option>
                  );
                })}
            </Form.Control>
          </Form.Group>
          <Form.Group>
            <Form.Label>Date</Form.Label>
            <span style={{ color: "red" }}>*</span>
            <Form.Control
              type="date"
              value={date}
              onChange={(event) => setDate(event.target.value)}
              required
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Activity</Form.Label>
            <span style={{ color: "red" }}>*</span>
            <Form.Control
              as="select"
              value={activity}
              onChange={(event) => setActivity(event.target.value)}
              required
            >
              <option value="">Select</option>
              <option>Development</option>
              <option>Design</option>
              <option>Testing</option>
              <option>Data Entry</option>
              <option>Documentation</option>
            </Form.Control>
          </Form.Group>
          <Form.Group>
            <Form.Label>Hours Spent</Form.Label>
            <span style={{ color: "red" }}>*</span>
            <Form.Control
              type="text"
              value={hoursSpent}
              onChange={(event) => setHoursSpent(event.target.value)}
              required
            />
          </Form.Group>

          <Form.Group>
            <Form.Label>Comment</Form.Label>
            <textarea
              className="form-control"
              value={comment}
              onChange={(event) => setComment(event.target.value)}
            />
          </Form.Group>
          <hr/>
          {
            message?message:""
          }
          <Button type="submit" className="box secondary">
            Update
          </Button>
          <Button
            style={{ marginLeft: "25px" }}
            className="box"
            onClick={closeEditReport}
          >
            Close
          </Button>
        </Form>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
};

// shows all employee daily reports

const DailyReportItems = ({
  dailyReport,
  projects,
  tasks,
  fetchDailyReports,
  setMessage,
  message,
  filterProject,
  fromDate,
  toDate
}) => {
  const [showEditDailyReport, setShowEditDailyReport] = useState(false);
  const closeEditReport = () => {
    setShowEditDailyReport(false);
  };
  const openEditReport = () => {
    setShowEditDailyReport(true);
  };

  const editDailyReportHandler = (
    dailyReportId,
    projectId,
    taskId,
    date,
    activity,
    hoursSpent,
    comment
  ) => {
    let content = {
      projectId: projectId,
      taskId: taskId,
      date: date,
      activity: activity,
      hoursSpent: hoursSpent,
      comment: comment,
      status: "Added",
    };
    api
      .put(`daily_report/?id=${dailyReportId}`, content, getRequestConfig())
      .then((response) => {
        setShowEditDailyReport(true);
        fetchDailyReports(filterProject, fromDate,toDate);

        setMessage(<Alert variant="success">Daily Report Edited Successfully</Alert>)
    
      }).catch((ex)=>{
        setMessage(<Alert variant="danger">There is some error</Alert>)
      });
  };
  return (
    <>
      <tr>
        <td>{dailyReport.date} </td>
        <td>{dailyReport.project} </td>
        <td>{dailyReport.task} </td>
        <td>{dailyReport.activity} </td>
        <td>{dailyReport.hoursSpent} </td>
        <td>{dailyReport.comment} </td>
        {dailyReport.status==="Approved"?
                <td>
         
                <Button variant="dark" style={{ marginRight: "10px" }} onClick={openEditReport} disabled>
                  Edit Report
                </Button>
              </td>
        :
        <td>
         
        <Button style={{ marginRight: "10px" }} onClick={openEditReport}>
          Edit Report
        </Button>
      </td>
        }

      </tr>

      <EditDailyReport
        showEditDailyReport={showEditDailyReport}
        closeEditReport={closeEditReport}
        editDailyReportHandler={editDailyReportHandler}
        dailyReport={dailyReport}
        projects={projects}
        tasks={tasks}
        message ={message}
      />
    </>
  );
};

const DailyReport = (userId) => {
  const [projects, setProjects] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [dailyReports, setDailyReports] = useState([]);
  const [filterProject, setFilterProject] = useState(localStorage.getItem(`project${userId}`)?localStorage.getItem(`project${userId}`):[]);

  var today = new Date(),
  // get month in two digit
  month =
    (today.getMonth() + 1) < "10"
      ? "0" + (today.getMonth() + 1)
      : (today.getMonth() + 1),
  // get month in two digit

  previousday = (today.getDate()-1)< "10" ? "0" + (today.getDate()-1) : (today.getDate()-1),
  currentday = today.getDate()  < "10" ? "0" + today.getDate(): today.getDate(),
  // get current date
  yesterday = today.getFullYear()+"-" + month + "-" +previousday;
  today = today.getFullYear()+"-" + month + "-" +currentday;
 
  const [fromDate, setFromDate] = useState(localStorage.getItem("fromDate"+userId)?localStorage.getItem("fromDate"+userId):yesterday);
  const [toDate, setToDate] = useState(localStorage.getItem("toDate"+userId)?localStorage.getItem("toDate"+userId):today);


  const [showAddReport, setShowAddReport] = useState(false);
  const [message,setMessage] = useState("");
  useEffect(() => {
    fetchProjects();
    fetchTasks();
  }, []);

  useEffect(() => {
    fetchDailyReports(filterProject, fromDate,toDate);
  }, [filterProject, fromDate,toDate]);
  //reset filter

  const resetFilter = (yesterday,today) => {
    fetchDailyReports([]);
    localStorage.setItem(`project${userId}`,"");
    setFilterProject(localStorage.getItem(`project${userId}`));
    localStorage.setItem(`fromDate${userId}`,yesterday);
    setFromDate(localStorage.getItem(`fromDate${userId}`));
    localStorage.setItem(`toDate${userId}`,today);
    setToDate(localStorage.getItem(`toDate${userId}`));
  };

  const sessionHandler = (event)=>{
    let name = event.target.name;
    let value=event.target.value;
    if(name=="project")
    {
      localStorage.setItem(`project${userId}`, value);
      setFilterProject(localStorage.getItem(`project${userId}`));
    }
    else if(name=="fromDate")
    {
      localStorage.setItem(`fromDate${userId}`, value);
      setFromDate(localStorage.getItem(`fromDate${userId}`));
    }
    else if(name=="toDate")
    {
      localStorage.setItem(`toDate${userId}`, value);
      setToDate(localStorage.getItem(`toDate${userId}`));
    }

}
  //get Daily report
  const fetchDailyReports = (filterProject,fromDate,toDate) => {
    api
      .get(
        `daily_report/?project_id=${filterProject ? filterProject : localStorage.getItem(`project${userId}`)}&startDate=${fromDate?fromDate:localStorage.getItem(`fromDate${userId}`)}&endDate=${toDate?toDate:localStorage.getItem(`toDate${userId}`)}`,
        getRequestConfig()
      )
      .then((response) => {
        setDailyReports([]);
        setDailyReports(response.data);
      });
  };
  //get project
  const fetchProjects = () => {
    api.get("project/", getRequestConfig()).then((response) => {
      setProjects(response.data);
    });
  };
  //get task
  const fetchTasks = () => {
    api.get("task/", getRequestConfig()).then((response) => {
      setTasks(response.data);
    });
  };

  const closeAddReport = () => {
    setShowAddReport(false);
  };
  const openAddReport = () => {
    setShowAddReport(true);
  };

  const addDailyReportHandler = (
    projectId,
    taskId,
    date,
    activity,
    hoursSpent,
    comment
  ) => {
    let content = {
      projectId: projectId,
      taskId: taskId,
      date: date,
      activity: activity,
      hoursSpent: hoursSpent,
      comment: comment,
      status:"Added"
    };
    api.post("daily_report/", content, getRequestConfig()).then((response) => {
      setShowAddReport(true);
      fetchDailyReports(filterProject, fromDate,toDate);
      setMessage(<Alert variant="success">Daily Report Added Successfully</Alert>)
    
    }).catch((ex)=>{
      setMessage(<Alert variant="danger">There is some error</Alert>)
    });
  };
  setTimeout(()=>{
    setMessage("");
    
  },10000);
  return (
    <div>
      <Row>
        <Col sm={12} md={6} lg={6} style={{ padding: "5px" }}>
          {" "}
          <h3>Daily Report</h3>
        </Col>
        <Col sm={12} md={6} lg={6} style={{ padding: "5px",textAlign:"right" }}><Button onClick={openAddReport} style={{ float: "right" }}>
        Add Report
      </Button></Col>
      </Row>

      <Row>
        
        <Col sm={12} md={3} lg={3} style={{ padding: "5px" }}>
          <Form.Group>
            <Form.Label>Select Project</Form.Label>
            <Form.Control
              as="select"
              value={filterProject}
              name="project"
              onChange={sessionHandler}
            >
              <option value="">Select</option>
              {projects.map((project, index) => {
                return (
                  <option key={index} value={project.id}>
                    {project.title}
                  </option>
                );
              })}
            </Form.Control>
          </Form.Group>
        </Col>
        <Col sm={12} md={3} lg={3} style={{ padding: "5px" }}>
          <Form.Group>
            <Form.Label>From Date</Form.Label>
            <Form.Control
              type="date"
              value={fromDate}
              name="fromDate"
              onChange={sessionHandler}
            />
          </Form.Group>
          </Col>
          <Col sm={12} md={3} lg={3} style={{ padding: "5px" }}>
          <Form.Group>
            <Form.Label>To Date</Form.Label>
            <Form.Control
              type="date"
              value={toDate}
              name="toDate"
              onChange={sessionHandler}
            />
          </Form.Group>
        </Col>
        <Col style={{ padding: "12px" }}>
          <br />

          <Button
            style={{ marginLeft: "10px" }}
            variant="dark"
            onClick={()=>resetFilter(yesterday,today)}
          >
            Reset
          </Button>
        </Col>
      </Row>
      
      
        {dailyReports.length === 0 && <h3>No Record Found</h3>}
        
          {dailyReports.length !== 0 &&
          <Table className="table table-bordered">
          <thead>
            <tr>
              <th>Date</th>
              <th>Project</th>
              <th>Task</th>
              <th>Activity</th>
              <th>Hours Spent</th>
              <th>Comment</th>
              <th>Edit</th>
            </tr>
          </thead>
          <tbody>
            {

              dailyReports.map((dailyReport, index) => (
              <DailyReportItems
                key={index}
                dailyReport={dailyReport}
                projects={projects}
                tasks={tasks}
                fetchDailyReports={fetchDailyReports}
                setMessage = {setMessage}
                message ={message}
                filterProject={filterProject}
                fromDate={fromDate}
                toDate={toDate}
              />
            ))}
        </tbody>
      </Table>
      }
      <AddDailyReport
        showAddReport={showAddReport}
        closeAddReport={closeAddReport}
        addDailyReportHandler={addDailyReportHandler}
        projects={projects}
        tasks={tasks}
        message ={message}
      />
              
    </div>
  );
};
export default DailyReport;
