import React from "react";
import jsPDF from "jspdf";
import 'jspdf-autotable';
      // get weekly pdf

      
 export const invoicePdf = (invoices) => {
        var doc = new jsPDF({ format: "A4", orientation: "portrait" });
       
        doc.setFont("times","bold");
    
        doc.setFontSize(14);
        doc.text("INVOICE", 95, 30);
       
        doc.setFontSize(10);
       
        //generate table data
        var head = new Array("Invoice Number","Client","Month Year","Invoice Amount","Payment Status","Payment Mode","Payment Date");
        var body =new Array();
        let l = invoices.length;
        for(let i = 0; i<l; i++)
        {
          body.push(
            [
              invoices[i].invoiceNumber,
              invoices[i].client.clientName,
              invoices[i].monthYear,
              invoices[i].invoiceAmount,
              invoices[i].paymentStatus,
              invoices[i].paymentMode,
              invoices[i].paymentDate,
            ])
        }

        //create table
        doc.autoTable(head, body, {
          headerStyles:{
            halign:'center',
            fillColor: 'Lavender',
            textColor: 'black',
            lineWidth: 0.5,
            lineColor: ["black"]
          },
          bodyStyles:{
              fontSize: 10,
              columnWidth: 'auto',
              valign: 'middle',
              halign: 'center',
              overflow: 'linebreak',
              textColor: 'black',
              lineWidth: 0.5,
              lineColor: ["black"]
          },
          /// I have to define cell of sequences here (renderCell ?)
          startY: 40,
          margin: { horizontal: 20 },
      });
       
        doc.save("invoiceList.pdf");  
      };


export const clientInvoicePdf = (invoice) => {
        var doc = new jsPDF({ format: "A4", orientation: "portrait" });
        var imgData = process.env.PUBLIC_URL + "/images/logo.png";

        // first table
        doc.line(12,135,197,135);
        doc.line(12,135,12,180);
        doc.line(12,180,197,180);
        doc.line(157,135,157,180);
        doc.line(197,135,197,180);

        //total table
        doc.line(12,185,197,185);
        doc.line(12,185,12,200);
        doc.line(12,200,197,200);
        doc.line(157,185,157,200);
        doc.line(197,185,197,200);


        doc.setFont("times","bold");
        doc.setFontSize(14);
        doc.addImage(imgData, 'png', 15, 15, 60, 14);
        doc.setFontSize(12);
        doc.setTextColor("#3E78D1");
        doc.text("support@mindzcube.com", 140, 14);
        doc.text("+91 8270583983", 158, 28);
        doc.setDrawColor("#27528D");
        doc.setLineWidth(1); 
        doc.line(10,38,200,38);

        //footer
        doc.line(10,260,200,260);
        

        doc.setFontSize(12);
        doc.setTextColor("black");
        doc.setFont("times","bold");
        doc.setDrawColor("black");
        doc.text("INVOICE", 90,45);
        doc.line(89,46,109,46);
        doc.text("Invoice Date: "+invoice.createdDate, 135,55);
        doc.text("Invoice Number: "+invoice.invoiceNumber, 135,62);

        doc.text("From", 15,72);
        doc.text("Mindzcube Software Development & Consultancy,", 15,79);
        doc.text("52-JA, Ground Floor, Old Thanjavur Road,", 15,86);
        doc.text("Mannargudi, Tamil Nadu,India -  614001.", 15,93);

        doc.text("To,", 15,110);
        doc.text(invoice.client.clientName, 15,117);
        doc.text(invoice.client.address, 15,124);
        doc.text(invoice.client.contactNumber, 15,131);
        //table data
        let l = invoice.invoiceDetails.length;
        let i=0;
        let textX = 15;
        let amtX = 195;
        let y = 142;
        let total = 0;
        while(i<l)
        {

          doc.text(invoice.invoiceDetails[i].detail, textX,y);
          doc.text("INR "+invoice.invoiceDetails[i].amount+".00", amtX,y,'right');
        
          total=(Number(total)+Number(invoice.invoiceDetails[i].amount));
          i++;
          y = y+7
        }
       

        doc.text("TOTAL", 15,195);
        doc.text("INR "+total+".00", 195,195,'right');

        doc.text("Bank Account: ", 15,210);
        doc.setFontSize(10);
        doc.setFont("times","normal");
        doc.text("MINDZCUBE", 15,217);
        doc.text("806020110000199", 15,224);
        doc.text("IFSC: BKID0008060", 15,231);
        doc.text("SWIFT CODE: BKIDINBBMOS", 15,238);

        //footer
        doc.setTextColor("#27528D");
        doc.text("Mindzcube Software Development & Consultancy",100, 268,'center');
        doc.text("UAM No. TN25D0002885", 100, 275,"center");
        doc.text("52-JA, Ground Floor, Old Thanjavur Road, Mannargudi, Tamil Nadu, India - 614001", 100, 282,"center");
        doc.text("www.mindzcube.com", 100, 289,"center");
        
       
        doc.save("invoice.pdf");  
      };