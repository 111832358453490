export const getRequestConfig = () => {
  let user = JSON.parse(localStorage.getItem("loggedUser"))
    ? JSON.parse(localStorage.getItem("loggedUser"))
    : "";
 
  if (user) {
    let authToken = JSON.parse(user).authToken;
    return {
      headers: {
        Authorization: authToken,
      },
    };
  } else {
    return {};
  }
};
export default { getRequestConfig };
