import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Table,
  span,
  Nav,
  Button,
  Modal,
  Form,
  FormGroup,
  Alert
} from "react-bootstrap";
import api from "../../config/api";
import { getRequestConfig } from "../../utils/getRequestConfig";

const AddLocation = ({
  showAddLocation,
  closeAddLocation,
  addLocationHandler,
  message
}) => {
  const [locationName, setLocationName] = useState("");
  const onSubmitHandler = (event) => {
    event.preventDefault();
    addLocationHandler(locationName);
  };
  return (
    <Modal
      show={showAddLocation}
      onHide={closeAddLocation}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Add Location</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={onSubmitHandler}>
          <Form.Group>
            <Form.Label>Location Name</Form.Label>
            <span style={{ color: "red" }}>*</span>
            <Form.Control
              type="text"
              placeholder="location name"
              onChange={(event) => setLocationName(event.target.value)}
              required
            />
          </Form.Group>

          <hr />
          {
            message?message:""
          }
          <Button type="submit" className="box secondary">
            Add
          </Button>
          <Button
            style={{ marginLeft: "25px" }}
            className="box"
            onClick={closeAddLocation}
          >
            Close
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

//edit daily report
const EditLocation = ({
  showEditLocation,
  closeEditLocation,
  editLocationHandler,
  location,
  message
}) => {
  const [locationName, setLocationName] = useState(location.locationName);

  const locationId = location.id;

  const onSubmitHandler = (event) => {
    event.preventDefault();
    editLocationHandler(locationId, locationName);
  };
  return (
    <Modal
      show={showEditLocation}
      onHide={closeEditLocation}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Edit Location</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={onSubmitHandler}>
          <Form.Group>
            <Form.Label>Location Name</Form.Label>
            <span style={{ color: "red" }}>*</span>
            <Form.Control
              type="text"
              placeholder="Location"
              value={locationName}
              onChange={(event) => setLocationName(event.target.value)}
              required
            />
          </Form.Group>
          <hr />
          {
            message?message:""
          }
          <Button type="submit" className="box secondary">
            Update
          </Button>
          <Button
            style={{ marginLeft: "25px" }}
            className="box"
            onClick={closeEditLocation}
          >
            Close
          </Button>
        </Form>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
};

// shows all employee daily reports

const LocationItems = ({ location, fetchLocations,setMessage,message }) => {
  const [showEditLocation, setShowEditLocation] = useState(false);
  const closeEditLocation = () => {
    setShowEditLocation(false);
  };
  const openEditLocation = () => {
    setShowEditLocation(true);
  };

  const editLocationHandler = (locationId, locationName) => {
    let content = { locationName: locationName };
    api
      .put(`location/?id=${locationId}`, content, getRequestConfig())
      .then((response) => {
        setShowEditLocation(true);
        fetchLocations();
        setMessage(<Alert variant="success">Location Edited Successfully</Alert>)
    
      }).catch((ex)=>{
        setMessage(<Alert variant="danger">There is some error</Alert>)
      });
  };
  return (
    <>
      <tr>
        <td>{location.locationName} </td>
        <td>
          <Button style={{ marginRight: "10px" }} onClick={openEditLocation}>
            Edit Location
          </Button>
        </td>
      </tr>

      <EditLocation
        showEditLocation={showEditLocation}
        closeEditLocation={closeEditLocation}
        editLocationHandler={editLocationHandler}
        location={location}
        message={message}
      />
    </>
  );
};

const Location = () => {
  const [locations, setLocations] = useState([]);
  const [showAddLocation, setShowAddLocation] = useState(false);
  const [message, setMessage] = useState("");
  useEffect(() => {
    fetchLocations();
  }, []);

  //get task
  const fetchLocations = () => {
    api.get("location/", getRequestConfig()).then((response) => {
      setLocations(response.data);
    });
  };

  const closeAddLocation = () => {
    setShowAddLocation(false);
  };
  const openAddLocation = () => {
    setShowAddLocation(true);
  };

  const addLocationHandler = (locationName) => {
    let content = { locationName: locationName };
    api.post("location/", content, getRequestConfig()).then((response) => {
      setShowAddLocation(true);
      fetchLocations();
      setMessage(<Alert variant="success">Location Added Successfully</Alert>)
    
    }).catch((ex)=>{
      setMessage(<Alert variant="danger">There is some error</Alert>)
    });
  };
  setTimeout(()=>{
    setMessage("");
    
  },10000);
  return (
    <div>
      <Row>
        <Col sm={12} md={6} lg={6} style={{ padding: "5px" }}>
          {" "}
          <h3>Location</h3>
        </Col>
        <Col sm={12} md={6} lg={6} style={{ padding: "5px" }}>
          <Button onClick={openAddLocation} style={{ float: "right" }}>
            Add Location
          </Button>
        </Col>
      </Row>

      <br />
      <Table className="table table-bordered">
        {locations.length === 0 && <h3>Loading...</h3>}
        <thead>
          <tr>
            <th>Location</th>

            <th>Edit</th>
          </tr>
        </thead>
        <tbody>
          {locations.length !== 0 &&
            locations.map((location, index) => (
              <LocationItems
                key={index}
                location={location}
                fetchLocations={fetchLocations}
                setMessage = {setMessage}
                message ={message}
              />
            ))}
        </tbody>
      </Table>
      <AddLocation
        showAddLocation={showAddLocation}
        closeAddLocation={closeAddLocation}
        addLocationHandler={addLocationHandler}
        message={message}
      />
    </div>
  );
};
export default Location;
