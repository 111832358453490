import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Table,
  span,
  Nav,
  Button,
  Modal,
  Form,
} from "react-bootstrap";
import api from "../../config/api";
import { dailyReportPdf } from "../../utils/dailyReportPdf";
import { getRequestConfig } from "../../utils/getRequestConfig";
import { CSVLink} from "react-csv";

const DailyReportItems = ({
  dailyReport,
  projects,
  tasks,
  fetchDailyReports,
  filterEmployee, 
  filterProject, 
  fromDate,
  toDate
}) => {
console.log(dailyReport);
  const editReport = (dailyReportId) =>{
   
    api.put(`daily_report/?id=${dailyReportId}`,{},getRequestConfig()).then((response)=>{
      fetchDailyReports();
    })
  }
  return (
    <>
      <tr>
        <td>{dailyReport.date} </td>
        <td>{dailyReport.employee} </td>
        <td>{dailyReport.project} </td>
        <td>{dailyReport.task} </td>
        <td>{dailyReport.activity} </td>
        <td>{dailyReport.hoursSpent} </td>
        <td>{dailyReport.comment} </td>
        {dailyReport.status==="Approved"?
                <td>
         
                <Button variant="dark" style={{ marginRight: "10px" }}  disabled>
                  Approved
                </Button>
              </td>
        :
        <td>
         
        <Button variant="success" style={{ marginRight: "10px"}} onClick={()=>editReport(dailyReport.id)}>
          Approve
        </Button>
      </td>
        }
      </tr>
    </>
  );
};

const DailyReport = (userId) => {
  const [projects, setProjects] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [dailyReports, setDailyReports] = useState([]);

  const [filterEmployee, setFilterEmployee] = useState(localStorage.getItem(`employee2${userId}`)?localStorage.getItem(`employee2${userId}`):"");
  const [filterProject, setFilterProject] = useState(localStorage.getItem(`project2${userId}`)?localStorage.getItem(`project2${userId}`):"");
  const [filterStatus, setFilterStatus] = useState(localStorage.getItem(`status2${userId}`)?localStorage.getItem(`status2${userId}`):"");

  var today = new Date(),
  // get month in two digit
  month =
  (today.getMonth() + 1) < "10"
    ? "0" + (today.getMonth() + 1)
    : (today.getMonth() + 1),
  // get month in two digit
  previousday = (today.getDate() - 1) < "10" ? "0" + (today.getDate()-1) : (today.getDate()-1),
  currentday = today.getDate()  < "10" ? "0" + today.getDate() : today.getDate(),
  // get current date
  yesterday = today.getFullYear()+"-" + month + "-" +previousday;
  today = today.getFullYear()+"-" + month + "-" +currentday;

  const [fromDate, setFromDate] = useState(yesterday);
  const [toDate, setToDate] = useState(today);
  const [datas, setDatas] = useState([]);
   //excel headers
   const headers = [
    { label: "Date", key: "date" },
    { label: "Employee", key: "employee" },
    { label: "Project", key: "project" },
    { label: "Task", key: "task" },
    { label: "Activity", key: "activity" },
    { label: "Hours Spent", key: "hoursSpent" },
    { label: "Comment", key: "comment" },

];

  useEffect(() => {
    fetchProjects();
    fetchTasks();
    fetchEmployees();
  }, []);

  useEffect(() => {
    fetchDailyReports(filterEmployee, filterProject,filterStatus, fromDate,toDate);
   
  }, [filterEmployee, filterProject,filterStatus, fromDate , toDate]);
  //reset filter

  const resetFilter = (yesterday,today) => {
    fetchDailyReports([]);
    localStorage.setItem(`project2${userId}`,"");
    setFilterProject(localStorage.getItem(`project2${userId}`));
    localStorage.setItem(`status2${userId}`,"");
    setFilterStatus(localStorage.getItem(`status2${userId}`));
    localStorage.setItem(`employee2${userId}`,"");
    setFilterEmployee(localStorage.getItem(`employee2${userId}`));
    localStorage.setItem(`fromDate2${userId}`,yesterday);
    setFromDate(localStorage.getItem(`fromDate2${userId}`));
    localStorage.setItem(`toDate2${userId}`,today);
    setToDate(localStorage.getItem(`toDate2${userId}`));
  };

  const sessionHandler = (event)=>{
    let name = event.target.name;
    let value=event.target.value;
    if(name=="employee")
    {
        localStorage.setItem(`employee2${userId}`, value);
        setFilterEmployee(localStorage.getItem(`employee2${userId}`));
    }
    else if(name=="project")
    {
      localStorage.setItem(`project2${userId}`, value);
      setFilterProject(localStorage.getItem(`project2${userId}`));
    }
    else if(name=="status")
    {
      localStorage.setItem(`status2${userId}`, value);
      setFilterStatus(localStorage.getItem(`status2${userId}`));
    }
    else if(name=="fromDate")
    {
      localStorage.setItem(`fromDate2${userId}`, value);
      setFromDate(localStorage.getItem(`fromDate2${userId}`));
    }
    else if(name=="toDate")
    {
      localStorage.setItem(`toDate2${userId}`, value);
      setToDate(localStorage.getItem(`toDate2${userId}`));
    }

}
  //get Daily report
  const fetchDailyReports = (filterEmployee, filterProject,filterStatus, fromDate,toDate) => {
    
    api
      .get(
        `daily_report/?project_id=${filterProject ? filterProject : localStorage.getItem(`project2${userId}`)}&status=${
          filterStatus ? filterStatus : localStorage.getItem(`status2${userId}`)
        }&startDate=${fromDate?fromDate:localStorage.getItem(`fromDate2${userId}`)}&endDate=${toDate?toDate:localStorage.getItem(`toDate2${userId}`)}&employee_id=${filterEmployee ? filterEmployee : localStorage.getItem(`employee2${userId}`)}`,
        getRequestConfig()
      )
      .then((response) => {
        setDailyReports([]);
        setDailyReports(response.data);

          // excel datas generate
          let reports =[];
          let total = 0;
          let l = response.data.length;
          for(let i = 0; i<l; i++)
          {
          total = (Number(total)+Number(response.data[i].hoursSpent));
          reports.push(response.data[i]);
          }
          reports.push({activity:"Total Hours Spend",hoursSpent:total});
          setDatas(reports);
      });
  };

  //get employee

  //get project
  const fetchEmployees = () => {
    api.get("employee/", getRequestConfig()).then((response) => {
      setEmployees(response.data);
    });
  };
  //get project
  const fetchProjects = () => {
    api.get("project/", getRequestConfig()).then((response) => {
      setProjects(response.data);
    });
  };
  //get task
  const fetchTasks = () => {
    api.get("task/", getRequestConfig()).then((response) => {
      setTasks(response.data);
      console.log(response.data);
    });
  };
  const downloadPdf = (dailyReports) => {
    dailyReportPdf(dailyReports);
  };
 
  return (
    <div>
      <Row>
        <Col sm={12} md={6} lg={6} style={{ padding: "5px" }}>
          {" "}
          <h3>Daily Report</h3>
        </Col>
      </Row>

      <Row>
        <Col sm={12} md={2} lg={2} style={{ padding: "5px" }}>
          <Form.Group>
            <Form.Label>Select Employee</Form.Label>
            <Form.Control
              as="select"
              value={filterEmployee}
              name="employee"
              onChange={sessionHandler}
            >
              <option value="">All</option>
              {employees.filter((employee)=>employee.role!=="admin").map((employee, index) => {
                return (
                  <option key={index} value={employee.employeeId}>
                    {employee.name}
                  </option>
                );
              })}
            </Form.Control>
          </Form.Group>
        </Col>
        <Col sm={12} md={2} lg={2} style={{ padding: "5px" }}>
          <Form.Group>
            <Form.Label>Select Project</Form.Label>
            <Form.Control
              as="select"
              value={filterProject}
              name="project"
              onChange={sessionHandler}
            >
              <option value="">All</option>
              {projects.map((project, index) => {
                return (
                  <option key={index} value={project.id}>
                    {project.title}
                  </option>
                );
              })}
            </Form.Control>
          </Form.Group>
        </Col>
        <Col sm={12} md={2} lg={2} style={{ padding: "5px" }}>
          <Form.Group>
            <Form.Label>Status</Form.Label>
            <Form.Control
              as="select"
              value={filterStatus}
              name="status"
              onChange={sessionHandler}
            >
              <option value="">All</option>
              <option value="Added">New Added</option>
              <option>Approved</option>
            </Form.Control>
          </Form.Group>
        </Col>
        <Col sm={12} md={2} lg={2} style={{ padding: "5px" }}>
          <Form.Group>
            <Form.Label>From Date</Form.Label>
            <Form.Control
              type="date"
              value={fromDate}
              name="fromDate"
              onChange={sessionHandler}
            />
          </Form.Group>
          </Col>
          <Col sm={12} md={2} lg={2} style={{ padding: "5px" }}>
          <Form.Group>
            <Form.Label>To Date</Form.Label>
            <Form.Control
              type="date"
              value={toDate}
              name="toDate"
              onChange={sessionHandler}
            />
          </Form.Group>
        </Col>
        <Col style={{ padding: "12px" }}>
          <br />

          <Button
            style={{ marginLeft: "10px" }}
            variant="dark"
            onClick={()=>resetFilter(yesterday,today)}
          >
            Reset
          </Button>
        </Col>
      
      </Row>
        <Row>
        {
          dailyReports.length === 0 ?"":
          <>
          <Col style={{ padding: "6px" }}>
          <br />
          <Button
          style={{ marginLeft: "5px", float: "right" }}

          onClick={() => downloadPdf(dailyReports)}
          >
          Download Pdf
          </Button>
          <CSVLink  style={{ marginLeft: "5px", float: "right" }} className="btn btn-primary" data={datas} headers={headers} filename={"dailyReport.csv"}>Download Excel</CSVLink>

        </Col>
       

          </>
        }
        
        </Row>
      
        {dailyReports.length === 0 && <h3>No Record Found</h3>}
        
          {dailyReports.length !== 0 &&
          <Table className="table table-bordered">
          <thead>
          <tr>
            <th>Date</th>
            <th>Employee</th>
            <th>Project</th>
            <th>Task</th>
            <th>Activity</th>
            <th>Hours Spent</th>
            <th>Comment</th>
            <th>Approve</th>
          </tr>
        </thead>

       <tbody>
           {dailyReports.filter((dailyReport)=>dailyReport.role!=="admin").map((dailyReport, index) => (
              <DailyReportItems
                key={index}
                dailyReport={dailyReport}
                projects={projects}
                tasks={tasks}
                fetchDailyReports={fetchDailyReports}
                filterEmployee={filterEmployee}
                filterProject={filterProject}
                fromDate={fromDate}
                toDate={toDate}
              />
            ))
           }
        </tbody>
        </Table>
    }
    </div>
  );
  
  
};
export default DailyReport;
