import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Modal,
  Table,
  Form,
  Button,
  Tabs,
  Tab,
  Alert
} from "react-bootstrap";
import api from "../../config/api";
import { getRequestConfig } from "../../utils/getRequestConfig";

//add leave management
const AddLeaveRequest = ({
  showAddLeaveRequst,
  closeAddLeaveRequest,
  addLeaveRequestHandler,
  message
}) => {
  const [leaveType, setLeaveType] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [totalDays,setTotalDays]=useState(0);
  const [reason, setReason] = useState("");

  useEffect(()=>{
    //get total days
       
     var start = new Date(fromDate);
     var end = new Date(toDate);
 
     // initial total
     var totalDays = 0;
 
     // normalize both start and end to beginning of the day
     start.setHours(0,0,0,0);
     end.setHours(0,0,0,0);
 
     var current = new Date(start);
     current.setDate(current.getDate());
     var day;
     // loop through each day, checking
     while (current <= end) {
         day = current.getDay();
         if (day >= 1 && day <= 6) {
             ++totalDays;
         }
         current.setDate(current.getDate() + 1);
     }
     setTotalDays(totalDays);
   
    
  },[fromDate,toDate]);

  const onSubmitHandler = (event) => {
    event.preventDefault();
    addLeaveRequestHandler(
      leaveType,
      fromDate,
      toDate,
      totalDays,
      reason
    );
  };
  return (
    <Modal
      show={showAddLeaveRequst}
      onHide={closeAddLeaveRequest}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Add Leave Request</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={onSubmitHandler}>
          <Form.Group>
            <Form.Label>Leave Type</Form.Label>
            <span style={{ color: "red" }}>*</span>
            <Form.Control
              as="select"
              onChange={(event) => setLeaveType(event.target.value)}
              required
            >
              <option value="">Select</option>
              <option value="PL">Personal Leave</option>
              <option value="EL">Earned Leave</option>
            </Form.Control>
          </Form.Group>
          <Form.Group>
            <Form.Label>From Date</Form.Label>
            <span style={{ color: "red" }}>*</span>
            <Form.Control
              type="date"
              name="fromDate"
              onChange={(event) => setFromDate(event.target.value)}
              required
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>To Date</Form.Label>
            <span style={{ color: "red" }}>*</span>
            <Form.Control
              type="date"
              name="toDate"
              onChange={(event) => setToDate(event.target.value)}
              required
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Total Days</Form.Label>
            <span style={{ color: "red" }}>*</span>
            <Form.Control
              type="number"
              placeholder="total days"
              value={totalDays}
              required
              readOnly
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Reason</Form.Label>
            <span style={{ color: "red" }}>*</span>
            <Form.Control
              type="text"
              placeholder="reason"
              onChange={(event) => setReason(event.target.value)}
            />
          </Form.Group>
        
          <hr />
          {
            message?message:""
          }
          <Button type="submit" className="box secondary">
            Add
          </Button>
          <Button
            style={{ marginLeft: "25px" }}
            className="box"
            onClick={closeAddLeaveRequest}
          >
            Close
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

//Edit leave management
const EditLeaveRequest = ({
  showEditLeaveRequest,
  closeEditLeaveRequest,
  editLeaveRequestHandler,
  leaveRequest,
  message
}) => {
  const [leaveType, setLeaveType] = useState(leaveRequest.leaveType);
  const [fromDate, setFromDate] = useState(leaveRequest.fromDate);
  const [toDate, setToDate] = useState(leaveRequest.toDate);
  const [totalDays,setTotalDays]=useState(0);
    
  const [reason, setReason] = useState(leaveRequest.reason);
  const [status, setStatus] = useState(leaveRequest.status);
  const leaveManagementId = leaveRequest.id;

  useEffect(()=>{
    //get total days
       
     var start = new Date(fromDate);
     var end = new Date(toDate);
 
     // initial total
     var totalDays = 0;
 
     // normalize both start and end to beginning of the day
     start.setHours(0,0,0,0);
     end.setHours(0,0,0,0);
 
     var current = new Date(start);
     current.setDate(current.getDate());
     var day;
     // loop through each day, checking
     while (current <= end) {
         day = current.getDay();
         if (day >= 1 && day <= 6) {
             ++totalDays;
         }
         current.setDate(current.getDate() + 1);
     }
     setTotalDays(totalDays);
   
    
  },[fromDate,toDate]);

  const onSubmitHandler = (event) => {
    event.preventDefault();
    editLeaveRequestHandler(
      leaveManagementId,
      leaveType,
      fromDate,
      toDate,
      totalDays,
      reason,
      status
    );
  };
  return (
    <Modal
      show={showEditLeaveRequest}
      onHide={closeEditLeaveRequest}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Edit Leave Request</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={onSubmitHandler}>
          <Form.Group>
            <Form.Label>Leave Type</Form.Label>
            <span style={{ color: "red" }}>*</span>
            <Form.Control
              as="select"
              value={leaveType}
              onChange={(event) => setLeaveType(event.target.value)}
              required
            >
              <option value="">Select</option>
              <option value="PL">Personal Leave</option>
              <option value="EL">Earned Leave</option>
            </Form.Control>
          </Form.Group>
          <Form.Group>
            <Form.Label>From Date</Form.Label>
            <span style={{ color: "red" }}>*</span>
            <Form.Control
              type="date"
              value={fromDate}
              name="fromDate"
              onChange={(event) => setFromDate(event.target.value)}
              required
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>To Date</Form.Label>
            <span style={{ color: "red" }}>*</span>
            <Form.Control
              type="date"
              value={toDate}
              name="toDate"
              onChange={(event) => setToDate(event.target.value)}
              required
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Total Days</Form.Label>
            <span style={{ color: "red" }}>*</span>
            <Form.Control
              type="number"
              placeholder="total days"
              value={totalDays}
              required
              readOnly
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Reason</Form.Label>
            <Form.Control
              type="text"
              value={reason}
              placeholder="reason"
              onChange={(event) => setReason(event.target.value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Status</Form.Label>
            <span style={{ color: "red" }}>*</span>
            <Form.Control
              as="select"
              value={status}
              onChange={(event) => setStatus(event.target.value)}
              required
            >
              <option value="">Select</option>
              <option>Requested</option>
              <option>Canceled</option>
            
            </Form.Control>
          </Form.Group>

          <hr />
          {
            message?message:""
          }
          <Button type="submit" className="box secondary">
            Update
          </Button>
          <Button
            style={{ marginLeft: "25px" }}
            className="box"
            onClick={closeEditLeaveRequest}
          >
            Close
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

// leave transaction items
const LeaveTransactionItems = ({
  leaveTransaction,
  fetchLeaveTransactions,
}) => {
  return (
    <>
      <tr>
      <td>{leaveTransaction.date} </td>
        {
          leaveTransaction.type==="PL"?<td style={{ background: "yellow" }}>{leaveTransaction.type} </td>:<td style={{ background: "skyblue" }}>{leaveTransaction.type} </td>
        }
         <td>
          {leaveTransaction.fromDate?'From Date : '+leaveTransaction.fromDate:""}<br/>
          {leaveTransaction.toDate?' To Date : '+leaveTransaction.toDate:""}<br/>
          {leaveTransaction.totalDays?' Total Days : '+leaveTransaction.totalDays:""}<br/>
          {leaveTransaction.reason?' Reason : '+leaveTransaction.reason:""}<br/>
        </td>
        <td>{leaveTransaction.deposit} </td>
        <td>{leaveTransaction.withdraw} </td>
        <td>{leaveTransaction.balance} </td>
      </tr>
    </>
  );
};
//Leave management items
const LeaveRequestItems = ({ leaveRequest, fetchLeaveRequests,setMessage,message }) => {
  const [showEditLeaveRequest, setShowEditLeaveRequest] = useState(false);

  const closeEditLeaveRequest = () => {
    setShowEditLeaveRequest(false);
  };
  const openEditLeaveRequest = () => {
    setShowEditLeaveRequest(true);
  };
  const editLeaveRequestHandler = (
    leaveManagementId,
    leaveType,
    fromDate,
    toDate,
    totalDays,
    reason,
    status
  ) => {
    let content = {
      leaveType: leaveType,
      fromDate: fromDate,
      toDate: toDate,
      totalDays: totalDays,
      reason: reason,
      status: status
    };
    api
      .put(
        `leave_application/?id=${leaveManagementId}`,
        content,
        getRequestConfig()
      )
      .then((response) => {
        setShowEditLeaveRequest(true);
        fetchLeaveRequests();
        setMessage(<Alert variant="success">Leave Request Edited Successfully</Alert>)
    
    }).catch((ex)=>{
      setMessage(<Alert variant="danger">There is some error</Alert>)
    });
  };
  return (
    <>
      <tr>
        <td>{leaveRequest.leaveType} </td>
        <td>{leaveRequest.fromDate} </td>
        <td>{leaveRequest.toDate} </td>
        <td>{leaveRequest.totalDays} </td>
        <td>{leaveRequest.reason} </td>
        <td>{leaveRequest.status} </td>
        <td>{leaveRequest.comment} </td>
        {leaveRequest.status !== "Approved" && (
          <td>
            <Button
              style={{ marginRight: "10px" }}
              onClick={openEditLeaveRequest}
            >
              Edit
            </Button>
          </td>
        )}
        {leaveRequest.status === "Approved" && (
          <td>
            <Button variant="dark" style={{ marginRight: "10px" }} disabled>
              Edit
            </Button>
          </td>
        )}
      </tr>
      <EditLeaveRequest
        showEditLeaveRequest={showEditLeaveRequest}
        closeEditLeaveRequest={closeEditLeaveRequest}
        editLeaveRequestHandler={editLeaveRequestHandler}
        leaveRequest={leaveRequest}
        message ={message}
      />
    </>
  );
};
const LeaveManagement = () => {
  const [showAddLeaveRequst, setShowAddLeaveRequest] = useState(false);
  const [leaveRequests, setLeaveRequests] = useState([]);
  const [leaveTransactions, setLeaveTransactions] = useState([]);
  const [message, setMessage] = useState("");
  useEffect(() => {
    fetchLeaveRequests();
    fetchLeaveTransactions();
  }, []);

  //fetch LMS
  const fetchLeaveRequests = () => {
    api.get("leave_application/", getRequestConfig()).then((response) => {
      setLeaveRequests(response.data);
      console.log(response.data);
    });
  };
  //fetch LTS
  const fetchLeaveTransactions = () => {
    api.get("leave_transaction/", getRequestConfig()).then((response) => {
      setLeaveTransactions(response.data);
      console.log(response.data);
    });
  };

  const closeAddLeaveRequest = () => {
    setShowAddLeaveRequest(false);
  };
  const openAddLeaveRequest = () => {
    setShowAddLeaveRequest(true);
  };
  const addLeaveRequestHandler = (
    leaveType,
    fromDate,
    toDate,
    totalDays,
    reason
  ) => {
    let content = {
      leaveType: leaveType,
      fromDate: fromDate,
      toDate: toDate,
      totalDays: totalDays,
      reason: reason,
      status: "Requested"
    };
    api
      .post("leave_application/", content, getRequestConfig())
      .then((response) => {
        setShowAddLeaveRequest(true);
        fetchLeaveRequests();
        setMessage(<Alert variant="success">Leave Request Added Successfully</Alert>)
    
      }).catch((ex)=>{
        setMessage(<Alert variant="danger">There is some error</Alert>)
      });
  };
  const [key, setKey] = useState("Leave applications");
  setTimeout(()=>{
    setMessage("");
    
  },10000);
  return (
    <>
      <Row>
        <Col sm={12} md={6} lg={6} style={{ padding: "5px" }}>
          {" "}
          <h3>Leave Management System</h3>
        </Col>
        <Col sm={12} md={6} lg={6} style={{ padding: "5px" }}>
          {" "}
          <Button onClick={openAddLeaveRequest} style={{ float: "right" }}>
            Add Request
          </Button>
        </Col>
      </Row>

      <br />
      <br />
      <Tabs
        id="controlled-tab-example"
        activeKey={key}
        onSelect={(k) => setKey(k)}
      >
        <Tab eventKey="Leave applications" title="Leave applications">
          <Table className="table table-bordered">
            <thead>
              <tr>
                <th>Leave Type</th>
                <th>From Date</th>
                <th>To Date</th>
                <th>Total Days</th>
                <th>Reason</th>
                <th>Status</th>
                <th>comment</th>
                <th>Edit</th>
              </tr>
            </thead>
            <tbody>
              {leaveRequests.length === 0 && <h3>Loading...</h3>}
              {leaveRequests.length !== 0 &&
                leaveRequests.map((leaveRequest, index) => (
                  <LeaveRequestItems
                    key={index}
                    leaveRequest={leaveRequest}
                    fetchLeaveRequests={fetchLeaveRequests}
                    setMessage = {setMessage}
                    message ={message}
                  />
                ))}
            </tbody>
          </Table>
        </Tab>
        <Tab eventKey="Leave transactions" title="Leave transactions">
          <Table className="table table-bordered">
            <thead>
              <tr>
                <th>Date</th>
                <th>Type</th>
                <th>Details</th>
                <th>deposit</th>
                <th>withdraw</th>
                <th>Balance</th>
              </tr>
            </thead>
            <tbody>
              {leaveTransactions.length === 0 && <h3>Loading...</h3>}
              {leaveTransactions.length !== 0 &&
                leaveTransactions.map((leaveTransaction, index) => (
                  <LeaveTransactionItems
                    key={index}
                    leaveTransaction={leaveTransaction}
                    fetchLeaveTransactions={fetchLeaveTransactions}
                  
                  />
                ))}
            </tbody>
          </Table>
        </Tab>
      </Tabs>

      <AddLeaveRequest
        showAddLeaveRequst={showAddLeaveRequst}
        closeAddLeaveRequest={closeAddLeaveRequest}
        addLeaveRequestHandler={addLeaveRequestHandler}
        message ={message}
      />
    </>
  );
};
export default LeaveManagement;
