import React,{useState,useEffect} from "react";
import { Modal, Form, Button } from "react-bootstrap";
const ForgetPassword = ({showForgetPassword, closeForgetPassword,forgetPassword}) => {
  const [userId, setUserId] = useState("")
  const [alert, setAlert] = useState("");

  const onSubmitHandler = (event) =>{
    event.preventDefault();
    forgetPassword(userId,setAlert );
  }
  setTimeout(()=>{
    setAlert("");

  },10000);

  return (
    <Modal
      show={showForgetPassword}
      onHide={closeForgetPassword}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Forget Password</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={onSubmitHandler} encType="multipart/form-data">
          <Form.Group>
          <Form.Label>User Id</Form.Label>
            <Form.Control type="text" placeholder="user id" onChange={(event)=>setUserId(event.target.value)}/>
          </Form.Group>
         {
           alert?alert:""
         }
        <br/><br/>
            <Button
              type="submit"
              className="box secondary"
            >
              Submit
            </Button>
            
            <Button
              style={{ marginLeft: "25px" }}
              className="box"
              onClick={closeForgetPassword}
            >
              Close
            </Button>
        </Form>
      </Modal.Body>
   
    </Modal>
  );
};
export default ForgetPassword;
