import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Table,
  span,
  Nav,
  Button,
  Modal,
  Form,
  Alert
} from "react-bootstrap";
import api from "../../config/api";
import { getRequestConfig } from "../../utils/getRequestConfig";
// add daily report
const AddClient = ({ showAddClient, closeAddClient, addClientHandler,message }) => {
  const [clientName, setClientName] = useState("");
  const [contactPerson, setContactPerson] = useState("");
  const [address, setAddress] = useState("");
  const [email, setEmail] = useState("");
  const [contactNumber, setContactNumber] = useState("");
 

  const onSubmitHandler = (event) => {
    event.preventDefault();
    addClientHandler(clientName, contactPerson, address, email, contactNumber);
  };
  return (
    <Modal
      show={showAddClient}
      onHide={closeAddClient}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Add Client</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={onSubmitHandler}>
          <Form.Group>
            <Form.Label>Client Name</Form.Label>
            <span style={{ color: "red" }}>*</span>
            <Form.Control
              type="text"
              placeholder="client name"
              onChange={(event) => setClientName(event.target.value)}
              required
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Contact Person</Form.Label>
            <span style={{ color: "red" }}>*</span>
            <Form.Control
              type="text"
              placeholder="contact person"
              onChange={(event) => setContactPerson(event.target.value)}
              required
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Address</Form.Label>
            <span style={{ color: "red" }}>*</span>
            <Form.Control
              type="text"
              placeholder="address"
              onChange={(event) => setAddress(event.target.value)}
              required
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Email</Form.Label>
            <span style={{ color: "red" }}>*</span>
            <Form.Control
              type="email"
              placeholder="email"
              onChange={(event) => setEmail(event.target.value)}
              required
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Contact Number</Form.Label>
            <span style={{ color: "red" }}>*</span>
            <Form.Control
              type="text"
              placeholder="contact number"
              onChange={(event) => setContactNumber(event.target.value)}
              required
            />
          </Form.Group>
          <hr />
         {
           message?message:""
         }
          <Button type="submit" className="box secondary">
            Add
          </Button>
          <Button
            style={{ marginLeft: "25px" }}
            className="box"
            onClick={closeAddClient}
          >
            Close
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

//edit daily report
const EditClient = ({
  showEditClient,
  closeEditClient,
  editClientHandler,
  client,
  message
}) => {
  const [clientName, setClientName] = useState(client.clientName);
  const [contactPerson, setContactPerson] = useState(client.contactPerson);
  const [address, setAddress] = useState(client.address);
  const [email, setEmail] = useState(client.email);
  const [contactNumber, setContactNumber] = useState(client.contactNumber);
  const clientId = client.id;

  const onSubmitHandler = (event) => {
    event.preventDefault();
    editClientHandler(
      clientId,
      clientName,
      contactPerson,
      address,
      email,
      contactNumber
    );
  };
  return (
    <Modal
      show={showEditClient}
      onHide={closeEditClient}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Edit Client</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={onSubmitHandler}>
          <Form.Group>
            <Form.Label>Client Name</Form.Label>
            <span style={{ color: "red" }}>*</span>
            <Form.Control
              type="text"
              placeholder="client name"
              value={clientName}
              onChange={(event) => setClientName(event.target.value)}
              required
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Contact Person</Form.Label>
            <span style={{ color: "red" }}>*</span>
            <Form.Control
              type="text"
              placeholder="contact person"
              value={contactPerson}
              onChange={(event) => setContactPerson(event.target.value)}
              required
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Address</Form.Label>
            <span style={{ color: "red" }}>*</span>
            <Form.Control
              type="text"
              placeholder="address"
              value={address}
              onChange={(event) => setAddress(event.target.value)}
              required
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Email</Form.Label>
            <span style={{ color: "red" }}>*</span>
            <Form.Control
              type="email"
              placeholder="email"
              value={email}
              onChange={(event) => setEmail(event.target.value)}
              required
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Contact Number</Form.Label>
            <span style={{ color: "red" }}>*</span>
            <Form.Control
              type="text"
              placeholder="contact number"
              value={contactNumber}
              onChange={(event) => setContactNumber(event.target.value)}
              required
            />
          </Form.Group>
          <hr />
          {
            message?message:""
          }
          <Button type="submit" className="box secondary">
            Update
          </Button>
          <Button
            style={{ marginLeft: "25px" }}
            className="box"
            onClick={closeEditClient}
          >
            Close
          </Button>
        </Form>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
};

// shows all employee daily reports

const ClientItems = ({ client, fetchClients,setMessage,message }) => {
  const [showEditClient, setShowEditClient] = useState(false);

  const closeEditClient = () => {
    setShowEditClient(false);
  };
  const openEditClient = () => {
    setShowEditClient(true);
  };

  const editClientHandler = (
    clientId,
    clientName,
    contactPerson,
    address,
    email,
    contactNumber,
    
  ) => {
    let content = {
      clientName: clientName,
      contactPerson: contactPerson,
      address: address,
      email: email,
      contactNumber: contactNumber,
    };
    api
      .put(`client/?id=${clientId}`, content, getRequestConfig())
      .then((response) => {
        setShowEditClient(true);
        fetchClients();
        setMessage(<Alert variant="success">Client Edited Successfully</Alert>)
        
      }).catch((ex)=>{
        setMessage(<Alert variant="danger">There is some error</Alert>)
      });
  };
  return (
    <>
      <tr>
        <td>{client.clientName} </td>
        <td>{client.contactPerson} </td>
        <td>{client.address} </td>
        <td>{client.email} </td>
        <td>{client.contactNumber} </td>
        <td>
          <Button style={{ marginRight: "10px" }} onClick={openEditClient}>
            Edit Client
          </Button>
        </td>
      </tr>

      <EditClient
        showEditClient={showEditClient}
        closeEditClient={closeEditClient}
        editClientHandler={editClientHandler}
        client={client}
        message={message}
      />
    </>
  );
};

const Client = () => {
  const [clients, setClients] = useState([]);
  const [showAddClient, setShowAddClient] = useState(false);
  const [message,setMessage] = useState("");
  useEffect(() => {
    fetchClients();
  }, []);

  //get task
  const fetchClients = () => {
    api.get("client/", getRequestConfig()).then((response) => {
      console.log(response);
      setClients(response.data);
    });
  };

  const closeAddClient = () => {
    setShowAddClient(false);
  };
  const openAddClient = () => {
    setShowAddClient(true);
  };

  const addClientHandler = (
    clientName,
    contactPerson,
    address,
    email,
    contactNumber
  ) => {
    let content = {
      clientName: clientName,
      contactPerson: contactPerson,
      address: address,
      email: email,
      contactNumber: contactNumber
    };
    api.post("client/", content, getRequestConfig()).then((response) => {
      setShowAddClient(true);
      fetchClients();
      setMessage(<Alert variant="success">Client Added Successfully</Alert>)
    
    }).catch((ex)=>{
      setMessage(<Alert variant="danger">There is some error</Alert>)
    });
  };

  setTimeout(()=>{
    setMessage("");
    
  },10000);
  return (
    <div>
      <Row>
        <Col sm={12} md={6} lg={6} style={{ padding: "5px" }}>
          {" "}
          <h3>Client</h3>
        </Col>
        <Col sm={12} md={6} lg={6} style={{ padding: "5px" }}>
          <Button onClick={openAddClient} style={{ float: "right" }}>
            Add Client
          </Button>
        </Col>
      </Row>

      <br />
      <Table className="table table-bordered">
        {clients.length === 0 && <h3>Loading...</h3>}
        <thead>
          <tr>
            <th>client Name</th>
            <th>Contact Person</th>
            <th>Address</th>
            <th>Email</th>
            <th>Contact Number</th>
            <th>Edit</th>
          </tr>
        </thead>
        <tbody>
          {clients.length !== 0 &&
            clients.map((client, index) => (
              <ClientItems
                key={index}
                client={client}
                fetchClients={fetchClients}
                setMessage = {setMessage}
                message={message}
              />
            ))}
        </tbody>
      </Table>
      <AddClient
        showAddClient={showAddClient}
        closeAddClient={closeAddClient}
        addClientHandler={addClientHandler}
        message={message}
      />
    </div>
  );
};
export default Client;
