import React from "react";
import jsPDF from "jspdf";
import 'jspdf-autotable';
      // get weekly pdf

      
 export const generatePdf = (paySlip) => {
        var doc = new jsPDF({ format: "A4", orientation: "landscape" });
        var imgData = process.env.PUBLIC_URL + "/images/logo.png";
        
        doc.setFont("times","bold");
    
        doc.setFontSize(14);
        console.log(paySlip);
        doc.addImage(imgData, 'png', 25, 30, 50, 12);
        doc.text("Mindzcube Software Development & Consultancy", 100, 30);
        doc.setFontSize(10);
        doc.text("52-JA, Ground Floor, Old Thanjavur Road, Mannargudi", 100, 38);
        doc.text("Tamil Nadu - 614001", 100, 46);

        doc.autoTable({
          head:[[{ content: "Payslip - "+paySlip.monthYear, colSpan: 4,styles: {halign:'center',fillColor: 'Lavender',textColor: 'black'}}]],
         
          body: [
            ['Employee ID', ': '+paySlip.employee.employeeCode, 'Employee Name',': '+paySlip.employee.name],
            ['Date of Joining', ': '+paySlip.employee.dateOfJoining, 'Designation',': '+paySlip.employee.designation],
            ['Bank Name', ': '+paySlip.employee.bankName, 'Bank A/C No',': '+paySlip.employee.bankAccountNo],
            ['PF NO', ': '+paySlip.employee.pfNumber, 'PAN No',': '+paySlip.employee.panNumber],
            
            // ...
          ],
          startY: 60,
          margin: { horizontal: 20 },
         
        })
      
        doc.text("Total Days : "+paySlip.totalDays, 22, 105);

        doc.text("Lop Days : "+paySlip.lopDays, 250, 105);

        
        doc.autoTable({
       
          head:  [
            [
                {content: 'Earnings', colSpan: 2, styles: {halign:'center',fillColor: 'Lavender',textColor: 'black'}}, 
                {content: 'Deductions', colSpan: 2, styles: {halign:'center',fillColor: 'Lavender',textColor: 'black'}}
            ],
            [{content:'Description',styles: {halign:'center',fillColor: 'Lavender',textColor: 'black'}}, 
            {content:'Amount',styles: {halign:'center',fillColor: 'Lavender',textColor: 'black'}}, 
            {content:'Description',styles: {halign:'center',fillColor: 'Lavender',textColor: 'black'}}, 
            {content:'Amount',styles: {halign:'center',fillColor: 'Lavender',textColor: 'black'}}],
        ],
        
          body: [
            ['Basic Pay', {content:paySlip.basicPay+".00",styles:{halign:'right'}}, 'Professional Tax',{content:paySlip.professionTax+".00",styles:{halign:'right'}}], 
            ['H.R.A', {content:paySlip.hra+".00",styles:{halign:'right'}},  'Income Tax',{content:paySlip.incomeTax+".00",styles:{halign:'right'}}],
            ['L.T.A', {content:paySlip.lta+".00",styles:{halign:'right'}},'Provident Fund (PF)',{content:paySlip.pf+".00",styles:{halign:'right'}}],
            ['Special Allowance', {content:paySlip.sa+".00",styles:{halign:'right'}}, 'L.O.P',{content:paySlip.lop+".00",styles:{halign:'right'}}],
            ['Variable Pay', {content:paySlip.variablePay+".00",styles:{halign:'right'}}],
            ['Medical', {content:paySlip.medical+".00",styles:{halign:'right'}}],
            [{content:'Gross Earnings', styles :{fontStyle:'bold'}}, {content:paySlip.grossEarnings+".00",styles:{halign:'right', fontStyle:'bold'}},{content:'Gross Deductions',styles:{fontStyle:'bold'}},{content:paySlip.grossDeductions+".00",styles:{halign:'right',fontStyle:'bold'}}],
            [{content: 'Net Pay',styles: {fontStyle:'bold'}},{content:paySlip.netpay+".00",styles :{halign:'right',fontStyle:'bold'}}],
          ],         
          startY: 110,
          margin: { horizontal: 20 }, 
          bodyStyles: {
            lineWidth: 0.5,
            lineColor: ["black"]
          },
          
          headerStyles: {
            lineWidth: 0.5,
            lineColor: ["black"]
         },

        });
        doc.setFont("times","normal");
        doc.text("Remarks: This is computer generated page and does not require authentication.", 20, 195);
        doc.save("paySlip.pdf");  
      };

