import React, { useState, useEffect } from "react";
import { Modal, Form, Button } from "react-bootstrap";

const ChangePassword = ({
    showChangePassword,
    closeChangePassword,
    changePasswordHandler
}) => {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState("");
  const handleChange = (event) =>{
    event.preventDefault();
    const { name, value } = event.target;

    const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
    const mediumRegex = new RegExp("^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})");
    let errors = [];
    switch (name){
      case 'newPassword':
        errors.newPassword = 
        strongRegex.test(value)? 
          <small style={{color:"green"}}>{"Strong password"}</small>
         :mediumRegex.test(value)? 
         <small style={{color:"Blue"}}>{"medium secure atleast (0-9) and (!@#$%^&) and 8 character above "}</small>
    : (value)?<small style={{color:"red"}}>{"Low secure atleast (a-z) and (A-Z) and 6 character above"}</small>:"";
       setNewPassword(value);
        break;
       default:
        break;
    }
    setErrors(errors);
  }
  const resetFields = () =>{
    setNewPassword("");
     setConfirmPassword("");
    }
    const onSubmitHandler= (event)=>{
      event.preventDefault();
      changePasswordHandler(newPassword,confirmPassword,setErrors, setMessage,resetFields);
    }
    setTimeout(()=>{
      setMessage("");
  
    },10000);
  return (
    <Modal
      show={showChangePassword}
      onHide={closeChangePassword}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Change Password</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <Form onSubmit={onSubmitHandler}>
        
          <Form.Group>
          <Form.Label>New Password</Form.Label>
            <Form.Control type="password" name="newPassword" placeholder="new password" onChange={handleChange} required/>
            {errors.length >= 0 && errors.newPassword}
              
          </Form.Group>
        <Form.Group>
        <Form.Label>Confirm Password</Form.Label>
        <Form.Control type="password" name="confirmPassword" placeholder="confirm password" onChange={(event)=>setConfirmPassword(event.target.value)} required/>
 
        </Form.Group>
        <hr/>
        {
          message?message:""
        }
            <Button
              type="submit"
              className="box secondary"
            >
            Submit
            </Button>
            
            <Button
              style={{ marginLeft: "25px" }}
              className="box"
              onClick={closeChangePassword}
            >
              Close
            </Button>
          </Form>
         
      </Modal.Body>
    </Modal>
  );
};

export default ChangePassword;
