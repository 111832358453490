import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Table,
  span,
  Nav,
  Button,
  Modal,
  Form,
  Alert
} from "react-bootstrap";
import api from "../../config/api";
import {baseURL} from "../../config/api";
import { getRequestConfig } from "../../utils/getRequestConfig";
// add daily report
const AddEmployee = ({
  showAddEmployee,
  closeAddEmployee,
  addEmployeeHandler,
  departments,
  locations,
  message
}) => {
  const [employeeId, setEmployeeId] = useState("");
  const [employeeCode, setEmployeeCode] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [qualification, setQualification] = useState("");
  const [dateOfJoining, setDateOfJoining] = useState("");
  const [designation, setDesignation] = useState("");
  const [totalExperience, setTotalExperience] = useState("");
  const [pan, setPan] = useState("");
  const [pfAccount, setPfAccount] = useState("");
  const [emailId, setEmailId] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [departmentId, setDepartmentId] = useState("");
  const [locationId, setLocationId] = useState("");
  const [bankName, setBankName] = useState("");
  const [bankAccount, setBankAccount] = useState("");
  const [basicPay, setBasicPay] = useState("");
  const [hra, setHra] = useState("");
  const [lta, setLta] = useState("");
  const [sa, setSa] = useState("");
  const [medical, setMedical] = useState("");
  const [variablePay, setVariablePay] = useState("");
  const [professionTax, setProfessionTax] = useState("");
  const [incomeTax, setIncomeTax] = useState("");
  const [pf, setPf] = useState("");
  const [plBalance, setPlBalance] = useState("");
  const [elBalance, setElBalance] = useState("");
  const [photoUrl, setPhotoUrl] = useState({});

  var today = new Date(),
  // get month in two digit
  currentMonth =
    today.getMonth() + 1 < "10"
      ? "0" + (today.getMonth() + 1)
      : today.getMonth() + 1,
  // get current date
  currentYear = today.getFullYear();

  const [month, setMonth] = useState(currentMonth);
  const [year, setYear] = useState(currentYear);

  //get years
  let rangeYears = [];
  for (let i = 12; i > 0; i--) {
    rangeYears.push(new Date().getFullYear() - i);
  }
  for (let i = 0; i < 12; i++) {
    rangeYears.push(new Date().getFullYear() + i);
  }
  const onSubmitHandler = (event) => {
    event.preventDefault();
    addEmployeeHandler(
      employeeId,
      employeeCode,
      password,
      name,
      dateOfBirth,
      qualification,
      dateOfJoining,
      designation,
      totalExperience,
      pan,
      pfAccount,
      emailId,
      contactNumber,
      photoUrl,
      departmentId,
      locationId,
      bankName,
      bankAccount,
      basicPay,
      hra,
      lta,
      sa,
      medical,
      variablePay,
      professionTax,
      incomeTax,
      pf,
      plBalance,
      elBalance,
      month,
      year
    );
  };
  return (
    <Modal
      show={showAddEmployee}
      onHide={closeAddEmployee}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Add Employee</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={onSubmitHandler} encType="multipart/form-data">
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Employee Id</Form.Label>
                <span style={{ color: "red" }}>*</span>
                <Form.Control
                  type="text"
                  placeholder="employee id"
                  onChange={(event) => setEmployeeId(event.target.value)}
                  required
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Employee Code</Form.Label>
                <span style={{ color: "red" }}>*</span>
                <Form.Control
                  type="text"
                  placeholder="employee code"
                  onChange={(event) => setEmployeeCode(event.target.value)}
                  required
                />
              </Form.Group>
            </Col>
            </Row>
            <Row>
            <Col>
              <Form.Group>
                <Form.Label>Password</Form.Label>
                <span style={{ color: "red" }}>*</span>
                <Form.Control
                  type="password"
                  placeholder="password"
                  onChange={(event) => setPassword(event.target.value)}
                  required
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Name</Form.Label>
                <span style={{ color: "red" }}>*</span>
                <Form.Control
                  type="text"
                  placeholder="name"
                  onChange={(event) => setName(event.target.value)}
                  required
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Date Of Birth</Form.Label>
                <span style={{ color: "red" }}>*</span>
                <Form.Control
                  type="date"
                  onChange={(event) => setDateOfBirth(event.target.value)}
                  required
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Qualification</Form.Label>
                <span style={{ color: "red" }}>*</span>
                <Form.Control
                  type="text"
                  placeholder="qualification"
                  onChange={(event) => setQualification(event.target.value)}
                  required
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Date Of Joining</Form.Label>
                <span style={{ color: "red" }}>*</span>
                <Form.Control
                  type="date"
                  onChange={(event) => setDateOfJoining(event.target.value)}
                  required
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Designation</Form.Label>
                <span style={{ color: "red" }}>*</span>
                <Form.Control
                  type="text"
                  placeholder="designation"
                  onChange={(event) => setDesignation(event.target.value)}
                  required
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Total Exp</Form.Label>
                <span style={{ color: "red" }}>*</span>
                <Form.Control
                  type="text"
                  placeholder="total experience"
                  onChange={(event) => setTotalExperience(event.target.value)}
                  required
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Pan</Form.Label>
                <span style={{ color: "red" }}>*</span>
                <Form.Control
                  type="text"
                  placeholder="pan"
                  onChange={(event) => setPan(event.target.value)}
                  required
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Pf Account</Form.Label>
                <span style={{ color: "red" }}>*</span>
                <Form.Control
                  type="text"
                  placeholder="pf account"
                  onChange={(event) => setPfAccount(event.target.value)}
                  required
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Email Id</Form.Label>
                <span style={{ color: "red" }}>*</span>
                <Form.Control
                  type="email"
                  placeholder="email id"
                  onChange={(event) => setEmailId(event.target.value)}
                  required
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Contact Number</Form.Label>
                <span style={{ color: "red" }}>*</span>
                <Form.Control
                  type="text"
                  placeholder="contact number"
                  onChange={(event) => setContactNumber(event.target.value)}
                  required
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>

            <Col>
              <Form.Group>
                <Form.Label>Department</Form.Label>
                <span style={{ color: "red" }}>*</span>
                <Form.Control
                  as="select"
                  placeholder="contact number"
                  onChange={(event) => setDepartmentId(event.target.value)}
                  required
                >
                  <option value="">select</option>
                  {departments.map((department, index) => {
                    return (
                      <option key={index} value={department.id}>
                        {department.departmentName}
                      </option>
                    );
                  })}
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Location</Form.Label>
                <span style={{ color: "red" }}>*</span>
                <Form.Control
                  as="select"
                  placeholder="location"
                  onChange={(event) => setLocationId(event.target.value)}
                  required
                >
                  <option value="">select</option>
                  {locations.map((location, index) => {
                    return (
                      <option key={index} value={location.id}>
                        {location.locationName}
                      </option>
                    );
                  })}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Bank Name</Form.Label>
                <span style={{ color: "red" }}>*</span>
                <Form.Control
                  type="text"
                  placeholder="bank name"
                  onChange={(event) => setBankName(event.target.value)}
                  required
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Bank Account</Form.Label>
                <span style={{ color: "red" }}>*</span>
                <Form.Control
                  type="text"
                  placeholder="bank account"
                  onChange={(event) => setBankAccount(event.target.value)}
                  required
                />
              </Form.Group>
            </Col>
          </Row>
          <h5>Salary Details</h5>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Basic Pay</Form.Label>
                <span style={{ color: "red" }}>*</span>
                <Form.Control
                  type="text"
                  placeholder="basic pay"
                  onChange={(event) => setBasicPay(event.target.value)}
                  required
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>H.R.A</Form.Label>
                <span style={{ color: "red" }}>*</span>
                <Form.Control
                  type="text"
                  placeholder="Hra"
                  onChange={(event) => setHra(event.target.value)}
                  required
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>L.T.A</Form.Label>
                <span style={{ color: "red" }}>*</span>
                <Form.Control
                  type="text"
                  placeholder="Lta"
                  onChange={(event) => setLta(event.target.value)}
                  required
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>S.A</Form.Label>
                <span style={{ color: "red" }}>*</span>
                <Form.Control
                  type="text"
                  placeholder="sepcial allowance"
                  onChange={(event) => setSa(event.target.value)}
                  required
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Medical</Form.Label>
                <span style={{ color: "red" }}>*</span>
                <Form.Control
                  type="text"
                  placeholder="medical"
                  onChange={(event) => setMedical(event.target.value)}
                  required
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Variable Pay</Form.Label>
                <span style={{ color: "red" }}>*</span>
                <Form.Control
                  type="text"
                  placeholder="variable pay"
                  onChange={(event) => setVariablePay(event.target.value)}
                  required
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Profession Tax</Form.Label>
                <span style={{ color: "red" }}>*</span>
                <Form.Control
                  type="text"
                  placeholder="profession tax"
                  onChange={(event) => setProfessionTax(event.target.value)}
                  required
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Income Tax</Form.Label>
                <span style={{ color: "red" }}>*</span>
                <Form.Control
                  type="text"
                  placeholder="income tax"
                  onChange={(event) => setIncomeTax(event.target.value)}
                  required
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Provident Fund</Form.Label>
                <span style={{ color: "red" }}>*</span>
                <Form.Control
                  type="text"
                  placeholder="provident fund"
                  onChange={(event) => setPf(event.target.value)}
                  required
                />
              </Form.Group>
            </Col>
          </Row>
          <h5>Leave Details</h5>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Personal Leave</Form.Label>
                <span style={{ color: "red" }}>*</span>
                <Form.Control
                  type="text"
                  placeholder="personal leave"
                  onChange={(event) => setPlBalance(event.target.value)}
                  required
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Earned Leave</Form.Label>
                <span style={{ color: "red" }}>*</span>
                <Form.Control
                  type="text"
                  placeholder="earned leave"
                  onChange={(event) => setElBalance(event.target.value)}
                  required
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
            <Form.Group>
            <Form.Label>Month</Form.Label>
            <span style={{ color: "red" }}>*</span>
            <Form.Control
              as="select"
              value={month}
              onChange={(event) => setMonth(event.target.value)}
              required
            >
              <option value="">Select</option>
              <option>01</option>
              <option>02</option>
              <option>03</option>
              <option>04</option>
              <option>05</option>
              <option>06</option>
              <option>07</option>
              <option>08</option>
              <option>09</option>
              <option>10</option>
              <option>11</option>
              <option>12</option>
            </Form.Control>
          </Form.Group>
            </Col>
            <Col>
            <Form.Group>
            <Form.Label>Year</Form.Label>
            <span style={{ color: "red" }}>*</span>
            <Form.Control
              as="select"
              value={year}
              onChange={(event) => setYear(event.target.value)}
              required
            >
              <option value="">Select</option>
              {rangeYears.map((rangeYear, index) => {
                return <option key={index}>{rangeYear}</option>;
              })}
            </Form.Control>
          </Form.Group>
            </Col>
          </Row>
          <Form.Group>
            <Form.Label>Photo</Form.Label>
            <span style={{ color: "red" }}>*</span>
            <Form.Control
              type="file"
              placeholder="photo"
              onChange={(event) => setPhotoUrl(event.target.files[0])}
             
            ></Form.Control>
          
          </Form.Group>
          <hr />
          {
            message?message:""
          }
          <Button type="submit" className="box secondary">
            Add
          </Button>
          <Button
            style={{ marginLeft: "25px" }}
            className="box"
            onClick={closeAddEmployee}
          >
            Close
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

//edit daily report
const EditEmployee = ({
  showEditEmployee,
  closeEditEmployee,
  editEmployeeHandler,
  employee,
  departments,
  locations,
  message
}) => {
  const [employeeId, setEmployeeId] = useState(employee.employeeId);
  const [employeeCode, setEmployeeCode] = useState(employee.employeeCode);
  const [password, setPassword] = useState(employee.password);
  const [name, setName] = useState(employee.name);
  const [dateOfBirth, setDateOfBirth] = useState(employee.dateOfBirth);
  const [qualification, setQualification] = useState(employee.qualification);
  const [dateOfJoining, setDateOfJoining] = useState(employee.dateOfJoining);
  const [designation, setDesignation] = useState(employee.designation);
  const [totalExperience, setTotalExperience] = useState(
    employee.totalExperience
  );
  const [pan, setPan] = useState(employee.pan);
  const [pfAccount, setPfAccount] = useState(employee.pfAccount);
  const [emailId, setEmailId] = useState(employee.emailId);
  const [contactNumber, setContactNumber] = useState(employee.contactNumber);
  const role = employee.role?employee.role:"";
  const [accountStatus, setAccountStatus] = useState(employee.accountStatus);
  const [departmentId, setDepartmentId] = useState(employee.departmentId);
  const [locationId, setLocationId] = useState(employee.locationsId);
  const [bankName, setBankName] = useState(employee.bankName);
  const [bankAccount, setBankAccount] = useState(employee.bankAccount);
  const [basicPay, setBasicPay] = useState(employee.salaryMaster.basicPay);
  const [hra, setHra] = useState(employee.salaryMaster.hra);
  const [lta, setLta] = useState(employee.salaryMaster.lta);
  const [sa, setSa] = useState(employee.salaryMaster.sa);
  const [medical, setMedical] = useState(employee.salaryMaster.medical);
  const [variablePay, setVariablePay] = useState(
    employee.salaryMaster.variablePay
  );
  const [professionTax, setProfessionTax] = useState(
    employee.salaryMaster.professionTax
  );
  const [incomeTax, setIncomeTax] = useState(employee.salaryMaster.incomeTax);
  const [pf, setPf] = useState(employee.salaryMaster.pf);

  const [photoUrl, setPhotoUrl] = useState({});

  const onSubmitHandler = (event) => {
    event.preventDefault();
    editEmployeeHandler(
      employeeId,
      employeeCode,
      password,
      name,
      dateOfBirth,
      qualification,
      dateOfJoining,
      designation,
      totalExperience,
      pan,
      pfAccount,
      emailId,
      contactNumber,
      photoUrl,
      role,
      accountStatus,
      departmentId,
      locationId,
      bankName,
      bankAccount,
      basicPay,
      hra,
      lta,
      sa,
      medical,
      variablePay,
      professionTax,
      incomeTax,
      pf
    );
  };
  return (
    <Modal
      show={showEditEmployee}
      onHide={closeEditEmployee}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Edit Employee</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={onSubmitHandler} encType="multipart/form-data">
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Employee Id</Form.Label>
                <span style={{ color: "red" }}>*</span>
                <Form.Control
                  type="text"
                  placeholder="employee id"
                  value={employeeId}
                  onChange={(event) => setEmployeeId(event.target.value)}
                  required readOnly
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Employee Code</Form.Label>
                <span style={{ color: "red" }}>*</span>
                <Form.Control
                  type="text"
                  placeholder="employee code"
                  value={employeeCode}
                  onChange={(event) => setEmployeeCode(event.target.value)}
                  required
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Name</Form.Label>
                <span style={{ color: "red" }}>*</span>
                <Form.Control
                  type="text"
                  placeholder="name"
                  value={name}
                  onChange={(event) => setName(event.target.value)}
                  required
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Date Of Birth</Form.Label>
                <span style={{ color: "red" }}>*</span>
                <Form.Control
                  type="date"
                  value={dateOfBirth}
                  onChange={(event) => setDateOfBirth(event.target.value)}
                  required
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Qualification</Form.Label>
                <span style={{ color: "red" }}>*</span>
                <Form.Control
                  type="text"
                  placeholder="qualification"
                  value={qualification}
                  onChange={(event) => setQualification(event.target.value)}
                  required
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Date Of Joining</Form.Label>
                <span style={{ color: "red" }}>*</span>
                <Form.Control
                  type="date"
                  value={dateOfJoining}
                  onChange={(event) => setDateOfJoining(event.target.value)}
                  required
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Designation</Form.Label>
                <span style={{ color: "red" }}>*</span>
                <Form.Control
                  type="text"
                  placeholder="designation"
                  value={designation}
                  onChange={(event) => setDesignation(event.target.value)}
                  required
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Total Exp</Form.Label>
                <span style={{ color: "red" }}>*</span>
                <Form.Control
                  type="text"
                  placeholder="total experience"
                  value={totalExperience}
                  onChange={(event) => setTotalExperience(event.target.value)}
                  required
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Pan</Form.Label>
                <span style={{ color: "red" }}>*</span>
                <Form.Control
                  type="text"
                  placeholder="pan"
                  value={pan}
                  onChange={(event) => setPan(event.target.value)}
                  required
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Pf Account</Form.Label>
                <span style={{ color: "red" }}>*</span>
                <Form.Control
                  type="text"
                  placeholder="pf account"
                  value={pfAccount}
                  onChange={(event) => setPfAccount(event.target.value)}
                  required
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Email Id</Form.Label>
                <span style={{ color: "red" }}>*</span>
                <Form.Control
                  type="email"
                  placeholder="email id"
                  value={emailId}
                  onChange={(event) => setEmailId(event.target.value)}
                  required
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Contact Number</Form.Label>
                <span style={{ color: "red" }}>*</span>
                <Form.Control
                  type="text"
                  placeholder="contact number"
                  value={contactNumber}
                  onChange={(event) => setContactNumber(event.target.value)}
                  required
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
           
            <Col>
              <Form.Group>
                <Form.Label>Account Status</Form.Label>
                <span style={{ color: "red" }}>*</span>
                <Form.Control
                  as="select"
                  placeholder="account status"
                  value={accountStatus}
                  onChange={(event) => setAccountStatus(event.target.value)}
                  required
                >
                  <option value="">select</option>
                  <option value="active">Active</option>
                  <option value="inactive">Inactive</option>
                </Form.Control>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Department</Form.Label>
                <span style={{ color: "red" }}>*</span>
                <Form.Control
                  as="select"
                  placeholder="contact number"
                  value={departmentId}
                  onChange={(event) => setDepartmentId(event.target.value)}
                  required
                >
                  <option value="">select</option>
                  {departments.map((department, index) => {
                    return (
                      <option key={index} value={department.id}>
                        {department.departmentName}
                      </option>
                    );
                  })}
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Location</Form.Label>
                <span style={{ color: "red" }}>*</span>
                <Form.Control
                  as="select"
                  placeholder="location"
                  value={locationId}
                  onChange={(event) => setLocationId(event.target.value)}
                  required
                >
                  <option value="">select</option>
                  {locations.map((location, index) => {
                    return (
                      <option key={index} value={location.id}>
                        {location.locationName}
                      </option>
                    );
                  })}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Bank Name</Form.Label>
                <span style={{ color: "red" }}>*</span>
                <Form.Control
                  type="text"
                  placeholder="bank name"
                  value={bankName}
                  onChange={(event) => setBankName(event.target.value)}
                  required
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Bank Account</Form.Label>
                <span style={{ color: "red" }}>*</span>
                <Form.Control
                  type="text"
                  placeholder="bank account"
                  value={bankAccount}
                  onChange={(event) => setBankAccount(event.target.value)}
                  required
                />
              </Form.Group>
            </Col>
          </Row>
          <h5>Salary Details</h5>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Basic Pay</Form.Label>
                <span style={{ color: "red" }}>*</span>
                <Form.Control
                  type="text"
                  placeholder="basic pay"
                  value={basicPay}
                  onChange={(event) => setBasicPay(event.target.value)}
                  required
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>H.R.A</Form.Label>
                <span style={{ color: "red" }}>*</span>
                <Form.Control
                  type="text"
                  placeholder="Hra"
                  value={hra}
                  onChange={(event) => setHra(event.target.value)}
                  required
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>L.T.A</Form.Label>
                <span style={{ color: "red" }}>*</span>
                <Form.Control
                  type="text"
                  placeholder="Lta"
                  value={lta}
                  onChange={(event) => setLta(event.target.value)}
                  required
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>S.A</Form.Label>
                <span style={{ color: "red" }}>*</span>
                <Form.Control
                  type="text"
                  placeholder="sepcial allowance"
                  value={sa}
                  onChange={(event) => setSa(event.target.value)}
                  required
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Medical</Form.Label>
                <span style={{ color: "red" }}>*</span>
                <Form.Control
                  type="text"
                  placeholder="medical"
                  value={medical}
                  onChange={(event) => setMedical(event.target.value)}
                  required
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Variable Pay</Form.Label>
                <span style={{ color: "red" }}>*</span>
                <Form.Control
                  type="text"
                  placeholder="variable pay"
                  value={variablePay}
                  onChange={(event) => setVariablePay(event.target.value)}
                  required
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Profession Tax</Form.Label>
                <span style={{ color: "red" }}>*</span>
                <Form.Control
                  type="text"
                  placeholder="profession tax"
                  value={professionTax}
                  onChange={(event) => setProfessionTax(event.target.value)}
                  required
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Income Tax</Form.Label>
                <span style={{ color: "red" }}>*</span>
                <Form.Control
                  type="text"
                  placeholder="income tax"
                  value={incomeTax}
                  onChange={(event) => setIncomeTax(event.target.value)}
                  required
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Provident Fund</Form.Label>
                <span style={{ color: "red" }}>*</span>
                <Form.Control
                  type="text"
                  placeholder="provident fund"
                  value={pf}
                  onChange={(event) => setPf(event.target.value)}
                  required
                />
              </Form.Group>
            </Col>
            </Row>
            

          <Form.Group>
            <Form.Label>Photo</Form.Label>
            <Form.Control
              type="file"
              placeholder="photo"
              onChange={(event) => setPhotoUrl(event.target.files[0])}
            ></Form.Control>
            <img
              src={baseURL +"/"+ employee.photoUrl}
              style={{ width: "100px", height: "120px" }}
            />
          </Form.Group>
          <hr />
          {
            message?message:""
          }
          <Button type="submit" className="box secondary">
            Update
          </Button>
          <Button
            style={{ marginLeft: "25px" }}
            className="box"
            onClick={closeEditEmployee}
          >
            Close
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

// shows all employee daily reports

const EmployeeItems = ({
  employee,
  departments,
  locations,
  fetchEmployees,
  setMessage,
  message
}) => {
  const [showEditEmployee, setShowEditEmployee] = useState(false);
  const closeEditEmployee = () => {
    setShowEditEmployee(false);
  };
  const openEditEmployee = () => {
    setShowEditEmployee(true);
  };

  const editEmployeeHandler = (
    employeeId,
    employeeCode,
    password,
    name,
    dateOfBirth,
    qualification,
    dateOfJoining,
    designation,
    totalExperience,
    pan,
    pfAccount,
    emailId,
    contactNumber,
    photoUrl,
    role,
    accountStatus,
    departmentId,
    locationId,
    bankName,
    bankAccount,
    basicPay,
    hra,
    lta,
    sa,
    medical,
    variablePay,
    professionTax,
    incomeTax,
    pf

  ) => {
    let formData = new FormData();
    formData.append("employeeCode", employeeCode);
    formData.append("name", name);
    formData.append("dateOfBirth", dateOfBirth);
    formData.append("qualification", qualification);
    formData.append("dateOfJoining", dateOfJoining);
    formData.append("designation", designation);
    formData.append("totalExperience", totalExperience);
    formData.append("pan", pan);
    formData.append("pfAccount", pfAccount);
    formData.append("emailId", emailId);
    formData.append("contactNumber", contactNumber);
    formData.append("photoUrl", photoUrl);
    formData.append("role", role);
    formData.append("accountStatus", accountStatus);
    formData.append("departmentId", departmentId);
    formData.append("locationId", locationId);
    formData.append("bankName", bankName);
    formData.append("bankAccount", bankAccount);
    formData.append("basicPay", basicPay);
    formData.append("hra", hra);
    formData.append("lta", lta);
    formData.append("sa", sa);
    formData.append("medical", medical);
    formData.append("variablePay", variablePay);
    formData.append("professionTax", professionTax);
    formData.append("incomeTax", incomeTax);
    formData.append("pf", pf);

    api
      .post(`employee/?employeeId=${employeeId}`, formData, getRequestConfig())
      .then((response) => {
        console.log(response.data);
        setShowEditEmployee(true);
        fetchEmployees();
        setMessage(<Alert variant="success">Employee Edited Successfully</Alert>)
        
      }).catch((ex)=>{
        setMessage(<Alert variant="danger">There is some error</Alert>)
      });
  };
  return (
    <>
      <tr>
        <td style={{textAlign:"center"}}>
          <img
            src={baseURL +"/"+employee.photoUrl}
            style={{ width: "120px", height: "120px" }}
          />
          <br />
          Employee Id : {employee.employeeId}
          <br />
          Employee Code : {employee.employeeCode}
          <br />
          Name : {employee.name}
          <br />
          Phone Number : {employee.contactNumber} <br />
          Email : {employee.emailId}{" "}
        </td>
        <td> {employee.designation} </td>
        <td>{employee.role} </td>
        <td>{employee.department} </td>
        <td>{employee.location} </td>
        <td>{employee.accountStatus} </td>
        <td>
          <Button style={{ marginRight: "10px" }} onClick={openEditEmployee}>
            Edit Employee
          </Button>
        </td>
      </tr>

      <EditEmployee
        showEditEmployee={showEditEmployee}
        closeEditEmployee={closeEditEmployee}
        editEmployeeHandler={editEmployeeHandler}
        employee={employee}
        departments={departments}
        locations={locations}
        message = {message}
      />
    </>
  );
};

const Employee = () => {
  const [employees, setEmployees] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [locations, setLocations] = useState([]);
  const [showAddEmployee, setShowAddEmployee] = useState(false);
  const [message, setMessage] = useState("");
  useEffect(() => {
    fetchEmployees();
    fetchDepartments();
    fetchLocations();
  }, []);

  //get task
  const fetchEmployees = () => {
    api.get("employee/", getRequestConfig()).then((response) => {
      setEmployees(response.data);
      console.log(response);
    });
  };
  //get departments
  const fetchDepartments = () => {
    api.get("department/", getRequestConfig()).then((response) => {
      setDepartments(response.data);
    });
  };

  //get task
  const fetchLocations = () => {
    api.get("location/", getRequestConfig()).then((response) => {
      setLocations(response.data);
    });
  };

  const closeAddEmployee = () => {
    setShowAddEmployee(false);
  };
  const openAddEmployee = () => {
    setShowAddEmployee(true);
  };

  const addEmployeeHandler = (
    employeeId,
    employeeCode,
    password,
    name,
    dateOfBirth,
    qualification,
    dateOfJoining,
    designation,
    totalExperience,
    pan,
    pfAccount,
    emailId,
    contactNumber,
    photoUrl,
    departmentId,
    locationId,
    bankName,
    bankAccount,
    basicPay,
    hra,
    lta,
    sa,
    medical,
    variablePay,
    professionTax,
    incomeTax,
    pf,
    plBalance,
    elBalance,
    month,
    year
  ) => {
    let formData = new FormData();
    formData.append("employeeId", employeeId);
    formData.append("employeeCode", employeeCode);
    formData.append("password", password);
    formData.append("name", name);
    formData.append("dateOfBirth", dateOfBirth);
    formData.append("qualification", qualification);
    formData.append("dateOfJoining", dateOfJoining);
    formData.append("designation", designation);
    formData.append("totalExperience", totalExperience);
    formData.append("pan", pan);
    formData.append("pfAccount", pfAccount);
    formData.append("emailId", emailId);
    formData.append("contactNumber", contactNumber);
    formData.append("photoUrl", photoUrl);
    formData.append("role", "employee");
    formData.append("accountStatus", "active");
    formData.append("departmentId", departmentId);
    formData.append("locationId", locationId);
    formData.append("bankName", bankName);
    formData.append("bankAccount", bankAccount);
    formData.append("basicPay", basicPay);
    formData.append("hra", hra);
    formData.append("lta", lta);
    formData.append("sa", sa);
    formData.append("medical", medical);
    formData.append("variablePay", variablePay);
    formData.append("professionTax", professionTax);
    formData.append("incomeTax", incomeTax);
    formData.append("pf", pf);
    formData.append("plBalance", plBalance);
    formData.append("elBalance", elBalance);
    formData.append("monthYear", month+"/"+year);
    let leaveTransaction = [
      { type: "PL", deposit: plBalance, balance: plBalance },
      { type: "EL", deposit: elBalance, balance: elBalance },
    ];
    let length = leaveTransaction.length;
    for (let i = 0; i < length; i++) {
      formData.append("types[]", leaveTransaction[i].type);
      formData.append("deposit[]", leaveTransaction[i].deposit);
      formData.append("balance[]", leaveTransaction[i].balance);
    }

    api.post("employee/", formData, getRequestConfig()).then((response) => {
      setShowAddEmployee(true);
      
      fetchEmployees();
      setMessage(<Alert variant="success">Employee Added Successfully</Alert>)
        
      }).catch((ex)=>{
        setMessage(<Alert variant="danger">There is some error</Alert>)
      });
  };
  setTimeout(()=>{
    setMessage("");
    
  },10000);
  return (
    <div>
      <Row>
        <Col sm={12} md={6} lg={6} style={{ padding: "5px" }}>
          {" "}
          <h3>Employees</h3>
        </Col>
        <Col sm={12} md={6} lg={6} style={{ padding: "5px" }}>
          <Button onClick={openAddEmployee} style={{ float: "right" }}>
            Add Employee
          </Button>
        </Col>
      </Row>

      <br />
     
      <Table className="table table-bordered">
        {employees.length === 0 && <h3>Loading...</h3>}
        <thead>
          <tr>
            <th style={{textAlign:"center"}}>Employee</th>
            <th>Designation</th>
            <th>Role</th>
            <th>Department</th>
            <th>Location</th>
            <th>Status</th>
            <th>Edit</th>
          </tr>
        </thead>
        <tbody>
          {employees.length !== 0 &&
            employees.filter((employee)=>employee.role!=="admin").map((employee, index) => (
              <EmployeeItems
                key={index}
                employee={employee}
                departments={departments}
                locations={locations}
                fetchEmployees={fetchEmployees}
                setMessage = {setMessage}
                message = {message}
              />
            ))}
        </tbody>
      </Table>
      <AddEmployee
        showAddEmployee={showAddEmployee}
        closeAddEmployee={closeAddEmployee}
        addEmployeeHandler={addEmployeeHandler}
        departments={departments}
        locations={locations}
        message = {message}
      />
    </div>
  );
};
export default Employee;
