import React, { useState, useEffect } from "react";
import api from "../../config/api";
import { getRequestConfig } from "../../utils/getRequestConfig";
import { Card, Button, Form, Row, Col,Modal,Alert } from "react-bootstrap";
import { findByLabelText } from "@testing-library/react";
const msToTime = (duration) => {
  var minutes = Math.floor((duration / (1000 * 60)) % 60),
    hours = Math.floor((duration / (1000 * 60 * 60)));

  hours = hours < 10 ? "0" + hours : hours;
  minutes = minutes < 10 ? "0" + minutes : minutes;
  return hours + ":" + minutes;
};

const Attendance = (userId) => {
  const [employees, setEmployees] = useState([]);
  const [myAttendance, setMyAttendance] = useState([]);

  var today = new Date(),
  // get month in two digit
  month =
  (today.getMonth() + 1) < "10"
    ? "0" + (today.getMonth() + 1)
    : (today.getMonth() + 1),
  // get month in two digit
  previousday = (today.getDate() - 1) < "10" ? "0" + (today.getDate()-1) : (today.getDate()-1),
  currentday = today.getDate()  < "10" ? "0" + today.getDate() : today.getDate(),
  // get current date
  yesterday = today.getFullYear()+"-" + month + "-" +previousday;
  today = today.getFullYear()+"-" + month + "-" +currentday;

  const [fromDate, setFromDate] = useState(localStorage.getItem(`fromDate1${userId}`)?localStorage.getItem(`fromDate1${userId}`):yesterday);
  const [toDate, setToDate] = useState(localStorage.getItem(`toDate1${userId}`)?localStorage.getItem(`toDate1${userId}`):today);
  const [employee, setEmployee] = useState(localStorage.getItem(`employee1${userId}`)?localStorage.getItem(`employee1${userId}`):"");

  let sumHours = 0;
  let sumMilliseconds = 0;
  useEffect(() => {
    fetchEmployees();
  }, []);

  useEffect(() => {
    fetchAttendance(employee,fromDate,toDate);
  },[employee,fromDate,toDate]);

  const fetchEmployees = () => {
    api.get("employee/", getRequestConfig()).then((response) => {
      setEmployees(response.data);
    });
  };
  const resetFilter = (yesterday,today) => {
    
    localStorage.setItem(`employee1${userId}`,"");
    setEmployee(localStorage.getItem(`employee1${userId}`));
    localStorage.setItem(`fromDate1${userId}`,yesterday);
    setFromDate(localStorage.getItem(`fromDate1${userId}`));
    localStorage.setItem(`toDate1${userId}`,today);
    setToDate(localStorage.getItem(`toDate1${userId}`));
    setMyAttendance([]);
  };

  const sessionHandler = (event)=>{
    let name = event.target.name;
    let value=event.target.value;
    if(name=="employee")
    {
        localStorage.setItem(`employee1${userId}`, value);
        setEmployee(localStorage.getItem(`employee1${userId}`));
    }
    else if(name=="fromDate")
    {
      localStorage.setItem(`fromDate1${userId}`, value);
      setFromDate(localStorage.getItem(`fromDate1${userId}`));
    }
    else if(name=="toDate")
    {
      localStorage.setItem(`toDate1${userId}`, value);
      setToDate(localStorage.getItem(`toDate1${userId}`));
    }

}
  const fetchAttendance = (employee,fromDate,toDate) => {

    if(employee!==""&&fromDate!==""&&toDate!=="")
    {
      api
      .get(
        `attendance/?startDate=${fromDate?fromDate:localStorage.getItem(`fromDate1${userId}`)}&endDate=${toDate?toDate:localStorage.getItem(`toDate1${userId}`)}&employeeId=${employee?employee:localStorage.getItem(`employee1${userId}`)}`,
        getRequestConfig()
      )
      .then((response) => {
        setMyAttendance(response.data);
        console.log(response.data);
      });
    }
    
  };

 
  return (
    <> 
        <Row>
            <Col sm={12} md={6} lg={6} style={{ padding: "5px" }}>
            {" "}
            <h3>Attendance</h3>
            </Col>
            
        </Row>
        <br/>
        <br/>
    
        <Row style={{ fontWeight: "bold" }}>
          <Col>Employee</Col>
          <Col>From Date</Col>
          <Col>To Date</Col>
          <Col></Col>
        </Row>
          <Row>
            <Col>
            
              <select
                className="form-control"
                required
                name="employee"
                onChange={sessionHandler}
                value={employee}
              >
                <option value="">Select Employee</option>
                {employees
                  .filter((item) => {
                    if (item.role != "admin") return item;
                  })
                  .map((item, index) => {
                      return (
                      <option key={index} value={item.employeeId}>
                        {item.name}
                      </option>
                    );
                  })}
              </select>
            </Col>
            <Col>
              <input
                type="date"
                className="form-control"
                value={fromDate}
                name="fromDate"
                onChange={sessionHandler}
                required
              />
            </Col>
            <Col>
              <input
                type="date"
                className="form-control"
                value={toDate}
                name="toDate"
                onChange={sessionHandler}
                required
              />
            </Col>
            <Col>
            <Button
                style={{ marginLeft: "10px" }}
                variant="dark"
                onClick={()=>resetFilter(yesterday,today)}
              >
            Reset
          </Button>
            </Col>
          </Row>
    
      <br />
      {
        myAttendance.length ===0 && 
        <Card style={{ fontWeight: "650" }}>
          <Row>
            <Col>No record found</Col>
            
          </Row>
        </Card>
      }
      {myAttendance.length !== 0 && (
        <Card style={{ fontWeight: "650" }}>
          <Row>
            <Col>Date</Col>
            <Col>Check-in</Col>
            <Col>Check-out</Col>
            <Col>Hours</Col>
            <Col>Edit</Col>
          </Row>
        </Card>
      )}
      {myAttendance.length !== 0 && myAttendance.map((item, index) => {
        
        let checkinDate = item.checkin!=null ? new Date(item.checkin): "";
        let checkoutDate = item.checkout!=null ? new Date(item.checkout) : "";
        let timeDifference = (item.checkin!=null && item.checkout!=null) ? Math.abs(
          checkoutDate.getTime() - checkinDate.getTime()
        ):0;
        let hours = (timeDifference / (1000 * 3600)).toFixed(2);

        sumHours = sumHours + Number(hours);
        sumMilliseconds = sumMilliseconds + Number(timeDifference);
        return <AttendanceItem attendance={item} key={index} fetchAttendance={fetchAttendance} employee={employee} fromDate={fromDate} toDate={toDate} />;
      
      
     })}
      {myAttendance.length !== 0 && (
        <Card style={{ fontWeight: "650" }}>
          <Row>
            <Col></Col>
            <Col></Col>
            <Col style={{textAlign: "left" }}>Total Hours : {" "}{msToTime(sumMilliseconds)}</Col>
            <Col></Col>
          </Row>
        </Card>
      )}
    </>
  );
};

//edit Attendance model
const EditAttendance = ({
    showEditAttendance,
    closeEditAttendance,
    editAttendanceHandler,
    attendance,
    message
    }) =>{
      const [checkin,setCheckin] = useState(attendance.checkin);
      const [checkout,setCheckout] = useState(attendance.checkout);
      const [status,setStatus] = useState(attendance.status);
      const [comment,setComment] = useState(attendance.comment);
      const attendanceId = attendance.id;
     
      
  const onSubmitHandler = (event) => {
    event.preventDefault();
    editAttendanceHandler(
      checkin,
      checkout,
      status,
      comment,
      attendanceId
    );
  };
  return(
    <Modal
    show={showEditAttendance}
    onHide={closeEditAttendance}
    aria-labelledby="contained-modal-title-vcenter"
    centered
  >
    <Modal.Header closeButton>
      <Modal.Title>Edit Attendance</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <Form onSubmit={onSubmitHandler}>
      
        <Form.Group>
          <Form.Label>Checkin</Form.Label>
          <span style={{ color: "red" }}>*</span>
          <Form.Control
            type="text"
            placeholder="checkin"
            value={checkin}
            onChange={(event) => setCheckin(event.target.value)}
            required
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Checkout</Form.Label>
          <span style={{ color: "red" }}>*</span>
          <Form.Control
            type="text"
            placeholder="checkout"
            value={checkout}
            onChange={(event) => setCheckout(event.target.value)}
            required
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>status</Form.Label>
          <span style={{ color: "red" }}>*</span>
          <Form.Control
              as="select"
              value={status}
              onChange={(event) => setStatus(event.target.value)}
              >
              <option value="">select</option>
              <option>checkedin</option>
              <option>checkedout</option>
            </Form.Control>
        </Form.Group> 
        <Form.Group>
          <Form.Label>Comment</Form.Label>
          <textarea
              className="form-control"
              placeholder="comment"
              value={comment}
              onChange={(event) => setComment(event.target.value)}
            />
        </Form.Group>
        <hr />
        {
          message?message:""
        }
        <Button type="submit" className="box secondary">
          Update
        </Button>
        <Button
          style={{ marginLeft: "25px" }}
          className="box"
          onClick={closeEditAttendance}
        >
          Close
        </Button>
      </Form>
    </Modal.Body>
    <Modal.Footer></Modal.Footer>
  </Modal>
  )
}
const AttendanceItem = ({ 
  attendance,
  fetchAttendance,
  employee,
  fromDate,
  toDate
 }) => {

  const [showEditAttendance, setShowEditAttendance] = useState(false);
  const [message, setMessage] = useState("");
  const closeEditAttendance = () => {
    setShowEditAttendance(false);
  };
  const openEditAttendance = () => {
    setShowEditAttendance(true);
  };

  //edit attendance
  const editAttendanceHandler = (
    checkin,
    checkout,
    status,
    comment,
    attendanceId
  ) =>{

    let content = {
      checkin:checkin,
      checkout:checkout,
      status:status,
      comment:comment
    }

    api
    .put(`attendance/?id=${attendanceId}`, content, getRequestConfig()).then((response) => {
      setShowEditAttendance(true);
      fetchAttendance(employee,fromDate,toDate);
      setMessage(<Alert variant="success">Attendance Edited Successfully</Alert>)
      
    }).catch((ex)=>{
      setMessage(<Alert variant="danger">There is some error</Alert>)
    });
  }
  setTimeout(()=>{
    setMessage("");
    
  },10000);

  const cardStyle =
    attendance.status === "checkedin" ? { backgroundColor: "#FFDE80" } : {};
  const checkinDate = attendance.checkin !=null ? new Date(attendance.checkin):"";
  const dateString = checkinDate !="" ?
    checkinDate.getDate() +
    "-" +
    (checkinDate.getMonth() + 1) +
    "-" +
    checkinDate.getFullYear():"";
  const checkoutDate = attendance.checkout!=null? new Date(attendance.checkout) : "";
  let timeDifference = checkoutDate !="" && checkinDate!="" ? Math.abs(checkoutDate.getTime() - checkinDate.getTime()):"";
  let hours = timeDifference !="" ? msToTime(timeDifference):"";

  return (
    <>
    <Card style={cardStyle}>
      <Row>
        <Col>{dateString}</Col>
        <Col>{checkinDate.getHours() + ":" + checkinDate.getMinutes()}</Col>
        <Col>
          {attendance.checkout
            ? checkoutDate.getHours() + ":" + checkoutDate.getMinutes()
            : ""}
        </Col>
        <Col>{hours} </Col>
        <Col>
            <Button type="submit" className="box secondary" onClick={openEditAttendance}>
            Edit 
          </Button>
        </Col>
      </Row>
    </Card>
    <EditAttendance
    
    showEditAttendance = {showEditAttendance}
    closeEditAttendance = {closeEditAttendance}
    editAttendanceHandler = {editAttendanceHandler}
    attendance = {attendance}
    message = {message}
    />
    </>
  );
};

export default Attendance;
