import React from "react";
import jsPDF from "jspdf";
import 'jspdf-autotable';
      // get weekly pdf

      
 export const dailyReportPdf = (dailyReports) => {
        var doc = new jsPDF({ format: "A4", orientation: "portrait" });
        doc.setFont("times","bold");
    
        doc.setFontSize(14);
        doc.text("DAILY REPORT", 90, 30);
        doc.setFontSize(10);
       
        //generate table data
        var head = new Array("Date","Employee","Project","Task","Activity","Horus Spent","Comment");
        var body =new Array();
        let l = dailyReports.length;
        var totalHoursSpend = 0;
        for(let i = 0; i<l; i++)
        {
          body.push(
            [
                dailyReports[i].date,
                dailyReports[i].employee,
                dailyReports[i].project,
                dailyReports[i].task,
                dailyReports[i].activity,
                dailyReports[i].hoursSpent,
                dailyReports[i].comment
            ])
            totalHoursSpend = (Number(totalHoursSpend)+Number(dailyReports[i].hoursSpent));
        }

      
    
        //create table
        doc.autoTable(head, body, {
          headerStyles:{
            halign:'center',
            fillColor: 'Lavender',
            textColor: 'black',
            lineWidth: 0.5,
            lineColor: ["black"]
          },
          bodyStyles:{
              fontSize: 10,
              columnWidth: 'auto',
              valign: 'middle',
              halign: 'center',
              overflow: 'linebreak',
              textColor: 'black',
              lineWidth: 0.5,
              lineColor: ["black"]
          },
        
          /// I have to define cell of sequences here (renderCell ?)
          startY: 40,
          margin: { horizontal: 20 },
      });
      doc.autoTable({
        head:[
          [
            {content: 'Total Hours Spend ',styles: {halign:'right', fillColor: 'Lavender',textColor: 'black'}}, 
            {content: totalHoursSpend, styles: {halign:'left',fillColor: 'Lavender',textColor: 'black'}},
            {content: '',styles: {halign:'right', fillColor: 'Lavender',textColor: 'black'}}, 
            
          ]
        ],
        headerStyles:{
          lineWidth: 0.5,
          lineColor: ["black"]
        },
        margin: { horizontal: 20 },
      })
       
        doc.save("dailyReport.pdf");  
      };

