import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Table,
  span,
  Nav,
  Button,
  Modal,
  Form,
  Alert
} from "react-bootstrap";
import api from "../../config/api";
import { getRequestConfig } from "../../utils/getRequestConfig";
const AddPaySlip = ({
  showAddPaySlip,
  closeAddPaySlip,
  addPaySlipHandler,
  employees,
  leaveBalances,
  message
}) => {
  const [employeeId, setEmployeeId] = useState("");
  
  var today = new Date(),
  // get month in two digit
  currentMonth =
    today.getMonth() + 1 < "10"
      ? "0" + (today.getMonth() + 1)
      : today.getMonth() + 1,
  // get current date
  currentYear = today.getFullYear();

  const [month, setMonth] = useState(currentMonth);
  const [year, setYear] = useState(currentYear);
  const [totalDays, setTotalDays] = useState("0");
  const [lopDays, setLopDays] = useState("0");
  const [basicPay, setBasicPay] = useState("0");
  const [hra, setHra] = useState("0");
  const [lta, setLta] = useState("0");
  const [sa, setSa] = useState("0");
  const [medical, setMedical] = useState("0");
  const [variablePay, setVariablePay] = useState("0");
  const [grossEarnings, setGrossEarnings] = useState("0");
  const [professionTax, setProfessionTax] = useState("0");
  const [incomeTax, setIncomeTax] = useState("0");
  const [pf, setPf] = useState("0");
  const [grossDeductions, setGrossDeductions] = useState("0");
  const [netpay, setNetPay] = useState("0");
  const [lop, setLop] = useState("0");

  const [plDays, setPlDays] = useState("0");
  const [elDays, setElDays] = useState("0");
  
  useEffect(() => {
    salaryHandler(employeeId, month, year);
  }, [employeeId, month, year]);

  const salaryHandler = (employeeId, month, year) => {
    let getDays ="0";
    getDays = new Date(year, month, 0).getDate();
    let basicPay = "0";
    let hra = "0";
    let lta = "0";
    let sa = "0";
    let medical = "0";
    let variablePay = "0";
    let professionTax = "0";
    let incomeTax = "0";
    let pf = "0";
    let lop = "0";
    let lopDays = "0";
    let plDays= "0";
    let elDays="0";
    let totalGrossDeductions ="0";
    let totalGrossEarnings ='0';
    //get salary from employee
    employees
      .filter((filters) => filters.employeeId === employeeId)
      .map((employee, index) => {
        basicPay = employee.salaryMaster.basicPay;
        hra = employee.salaryMaster.hra;
        lta = employee.salaryMaster.lta;
        sa = employee.salaryMaster.sa;
        medical = employee.salaryMaster.medical;
        variablePay = employee.salaryMaster.variablePay;
        professionTax = employee.salaryMaster.professionTax;
        incomeTax = employee.salaryMaster.incomeTax;
        pf = employee.salaryMaster.pf;
      });
    //get leave balance
    leaveBalances
      .filter((filterLeave) => filterLeave.employee === employeeId)
      .map((leaveBalance, index) => {
        let plDays =
          leaveBalance.plBalance < 0 ? Math.abs(leaveBalance.plBalance) : 0;
          
        let elDays =
          leaveBalance.elBalance < 0 ? Math.abs(leaveBalance.elBalance) : 0;
          
        lopDays = plDays + elDays;
      });
      setPlDays(plDays.toString());
      setElDays(elDays.toString());
    setLopDays(lopDays.toString());
    setBasicPay(basicPay.toString());
    setHra(hra);
    setLta(lta);
    setSa(sa);
    setMedical(medical);
    setVariablePay(variablePay);
    setProfessionTax(professionTax);
    setIncomeTax(incomeTax);
    setPf(pf);
    totalGrossEarnings =
      Number(basicPay) +
      Number(hra) +
      Number(lta) +
      Number(sa) +
      Number(medical) +
      Number(variablePay);
    setGrossEarnings(totalGrossEarnings.toString());
    let getLopDays = Math.round((totalGrossEarnings / getDays) * lopDays);
    totalGrossDeductions =
      Number(professionTax) +
      Number(incomeTax) +
      Number(pf) +
      Number(getLopDays);
    setGrossDeductions(totalGrossDeductions.toString());
    var netPay = totalGrossEarnings - totalGrossDeductions;
    setNetPay(netPay.toString());
    setLop(getLopDays.toString());
    setTotalDays(getDays.toString());
    setEmployeeId(employeeId);
  };
  //get years
  let rangeYears = [];
  for (let i = 12; i > 0; i--) {
    rangeYears.push(new Date().getFullYear() - i);
  }
  for (let i = 0; i < 12; i++) {
    rangeYears.push(new Date().getFullYear() + i);
  }

  const onSubmitHandler = (event) => {
    event.preventDefault();
    addPaySlipHandler(
      employeeId,
      month,
      year,
      totalDays,
      lopDays,
      basicPay,
      hra,
      lta,
      sa,
      medical,
      variablePay,
      pf,
      grossEarnings,
      professionTax,
      incomeTax,
      grossDeductions,
      netpay,
      lop,
      plDays,
      elDays
    );
  };
  return (
    <Modal
      show={showAddPaySlip}
      onHide={closeAddPaySlip}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Add PaySlip</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={onSubmitHandler} encType="multipart/form-data">
          <Form.Group>
            <Form.Label>Employee</Form.Label>
            <span style={{ color: "red" }}>*</span>
            <Form.Control
              as="select"
              placeholder=""
              value={employeeId}
              onChange={(event) => setEmployeeId(event.target.value)}
              required
            >
              <option value="">select</option>
              {employees.filter((employee)=>{if(employee.role!=="admin")return employee}).map((employee, index) => {
                return (
                  <option key={index} value={employee.employeeId}>
                    {employee.name}
                  </option>
                );
              })}
            </Form.Control>
          </Form.Group>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Month</Form.Label>
                <span style={{ color: "red" }}>*</span>
                <Form.Control
                  as="select"
                  value={month}
                  onChange={(event) => setMonth(event.target.value)}
                  required
                >
                  <option value="">Select</option>
                  <option>01</option>
                  <option>02</option>
                  <option>03</option>
                  <option>04</option>
                  <option>05</option>
                  <option>06</option>
                  <option>07</option>
                  <option>08</option>
                  <option>09</option>
                  <option>10</option>
                  <option>11</option>
                  <option>12</option>
                </Form.Control>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Year</Form.Label>
                <span style={{ color: "red" }}>*</span>
                <Form.Control
                  as="select"
                  value={year}
                  onChange={(event) => setYear(event.target.value)}
                  required
                >
                  <option value="">Select</option>
                  {rangeYears.map((rangeYear, index) => {
                    return <option key={index}>{rangeYear}</option>;
                  })}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Total Days</Form.Label>
                <span style={{ color: "red" }}>*</span>
                <Form.Control
                  type="text"
                  placeholder="total days"
                  value={totalDays}
                  required
                  readOnly
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Lop Days</Form.Label>
                <span style={{ color: "red" }}>*</span>
                <Form.Control
                  type="text"
                  placeholder="lopdays"
                  value={lopDays}
                  onChange={(event) => setLopDays(event.target.value)}
                  required
                  readOnly
                />
              </Form.Group>
            </Col>
            </Row>
            <Row>
              <Col className="col-6">
              <Form.Label><h5>Earnings</h5></Form.Label>
             
              <Form.Group>
                <Form.Label>Basic Pay</Form.Label>
                <span style={{ color: "red" }}>*</span>
                <Form.Control
                  type="text"
                  placeholder="basic pay"
                  value={basicPay}
                  onChange={(event) => setBasicPay(event.target.value)}
                  required
                  readOnly
                />
              </Form.Group>
           
              <Form.Group>
                <Form.Label>H.R.A</Form.Label>
                <span style={{ color: "red" }}>*</span>
                <Form.Control
                  type="text"
                  placeholder="hrs"
                  value={hra}
                  onChange={(event) => setHra(event.target.value)}
                  required
                  readOnly
                />
              </Form.Group>
            
              <Form.Group>
                <Form.Label>L.T.A</Form.Label>
                <span style={{ color: "red" }}>*</span>
                <Form.Control
                  type="text"
                  placeholder="lta"
                  value={lta}
                  onChange={(event) => setLta(event.target.value)}
                  required
                  readOnly
                />
              </Form.Group>
           
              <Form.Group>
                <Form.Label>S.A</Form.Label>
                <span style={{ color: "red" }}>*</span>
                <Form.Control
                  type="text"
                  placeholder="sepecial allowance"
                  value={sa}
                  onChange={(event) => setSa(event.target.value)}
                  required
                  readOnly
                />
              </Form.Group>

              <Form.Group>
                <Form.Label>Medical</Form.Label>
                <span style={{ color: "red" }}>*</span>
                <Form.Control
                  type="text"
                  placeholder="medical"
                  value={medical}
                  onChange={(event) => setMedical(event.target.value)}
                  required
                  readOnly
                />
              </Form.Group>

              <Form.Group>
                <Form.Label>Variable Pay</Form.Label>
                <span style={{ color: "red" }}>*</span>
                <Form.Control
                  type="text"
                  placeholder="variable pay"
                  value={variablePay}
                  onChange={(event) => setVariablePay(event.target.value)}
                  required
                  readOnly
                />
              </Form.Group>
          
              </Col>
              <Col className="col-6">
              <Form.Label><h5>Deductions</h5></Form.Label>
              <Form.Group>
                <Form.Label>Profession Tax</Form.Label>
                <span style={{ color: "red" }}>*</span>
                <Form.Control
                  type="text"
                  placeholder="profession tax"
                  value={professionTax}
                  onChange={(event) => setProfessionTax(event.target.value)}
                  required
                  readOnly
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Income Tax</Form.Label>
                <span style={{ color: "red" }}>*</span>
                <Form.Control
                  type="text"
                  placeholder="income Tax"
                  value={incomeTax}
                  onChange={(event) => setIncomeTax(event.target.value)}
                  required
                  readOnly
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>P.F</Form.Label>
                <span style={{ color: "red" }}>*</span>
                <Form.Control
                  type="text"
                  placeholder="pf"
                  value={pf}
                  onChange={(event) => setPf(event.target.value)}
                  required
                  readOnly
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Lop</Form.Label>
                <span style={{ color: "red" }}>*</span>
                <Form.Control
                  type="text"
                  placeholder="lop"
                  value={lop}
                  onChange={(event) => setLop(event.target.value)}
                  required
                  readOnly
                />
              </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
              <Form.Group>
                <Form.Label><b>Gross earnings</b></Form.Label>
                <span style={{ color: "red" }}>*</span>
                <Form.Control
                  type="text"
                  placeholder="gross earnings"
                  value={grossEarnings}
                  onChange={(event) => setGrossEarnings(event.target.value)}
                  required
                  readOnly
                />
              </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label><b>Gross Deductions</b></Form.Label>
                  <span style={{ color: "red" }}>*</span>
                  <Form.Control
                    type="text"
                    placeholder="gross deductions"
                    value={grossDeductions}
                    onChange={(event) => setGrossDeductions(event.target.value)}
                    required
                    readOnly
                  />
              </Form.Group>
              </Col>
            </Row>
          <Row>
                
            <Col>
              <Form.Group>
                <Form.Label><b>Net Pay</b></Form.Label>
                <span style={{ color: "red" }}>*</span>
                <Form.Control
                  type="text"
                  placeholder="net pay"
                  value={netpay}
                  onChange={(event) => setNetPay(event.target.value)}
                  required
                  readOnly
                />
              </Form.Group>
            </Col>
          </Row>

          <hr />
          {
            message?message:""
          }
          <Button type="submit" className="box secondary">
            Add
          </Button>
          <Button
            style={{ marginLeft: "25px" }}
            className="box"
            onClick={closeAddPaySlip}
          >
            Close
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

//edit daily report
const EditPaySlip = ({
  showEditPaySlip,
  closeEditPaySlip,
  editPaySlipHandler,
  paySlip,
  employees,
  leaveBalances,
  message
}) => {
  const [employeeId, setEmployeeId] = useState(
    paySlip.employee.employeeId?paySlip.employee.employeeId:""
  );
  let monthYears = paySlip.monthYear.split("/");
  let getMonth1 = monthYears[0];
  let getYear1 = monthYears[1];
  const [month, setMonth] = useState(getMonth1);
  const [year, setYear] = useState(getYear1);
  const [totalDays, setTotalDays] = useState("");
  const [lopDays, setLopDays] = useState(paySlip.lopDays);
  const [basicPay, setBasicPay] = useState(paySlip.basicPay);
  const [hra, setHra] = useState(paySlip.hra);
  const [lta, setLta] = useState(paySlip.lta);
  const [sa, setSa] = useState(paySlip.sa);
  const [medical, setMedical] = useState(paySlip.medical);
  const [variablePay, setVariablePay] = useState(paySlip.variablePay);
  const [grossEarnings, setGrossEarnings] = useState(paySlip.grossEarnings);
  const [professionTax, setProfessionTax] = useState(paySlip.professionTax);
  const [incomeTax, setIncomeTax] = useState(paySlip.incomeTax);
  const [pf, setPf] = useState(paySlip.pf);
  const [grossDeductions, setGrossDeductions] = useState(
    paySlip.grossDeductions
  );
  const [netpay, setNetPay] = useState(paySlip.netpay);
  const [lop, setLop] = useState(paySlip.lop);
  
  const paySlipId = paySlip.id;
  //get years
  let rangeYears = [];
  for (let i = 12; i > 0; i--) {
    rangeYears.push(new Date().getFullYear() - i);
  }
  for (let i = 0; i < 12; i++) {
    rangeYears.push(new Date().getFullYear() + i);
  }

  useEffect(() => {
    salaryHandler(employeeId, month, year);
  }, [employeeId, month, year]);

  const salaryHandler = (employeeId, month, year) => {
    let getDays ="0";
    getDays = new Date(year,month, 0).getDate();
    let basicPay = "0";
    let hra = "0";
    let lta = "0";
    let sa = "0";
    let medical = "0";
    let variablePay = "0";
    let professionTax = "0";
    let incomeTax = "0";
    let pf = "0";
    let lop = "0";
    let lopDays = "0";
    let plDays= "0";
    let elDays="0";
    let totalGrossDeductions ="0";
    let totalGrossEarnings ='0';
    //get salary from employee
    employees
      .filter((filters) => filters.employeeId === employeeId)
      .map((employee, index) => {
        basicPay = employee.salaryMaster.basicPay;
        hra = employee.salaryMaster.hra;
        lta = employee.salaryMaster.lta;
        sa = employee.salaryMaster.sa;
        medical = employee.salaryMaster.medical;
        variablePay = employee.salaryMaster.variablePay;
        professionTax = employee.salaryMaster.professionTax;
        incomeTax = employee.salaryMaster.incomeTax;
        pf = employee.salaryMaster.pf;
      });
    //get leave balance
    leaveBalances
      .filter((filterLeave) => filterLeave.employee === employeeId)
      .map((leaveBalance, index) => {
        let plDays =
          leaveBalance.plBalance < 0 ? Math.abs(leaveBalance.plBalance) : 0;
          
        let elDays =
          leaveBalance.elBalance < 0 ? Math.abs(leaveBalance.elBalance) : 0;
          
        lopDays = plDays + elDays;
      });
    setLopDays(lopDays.toString());
    setBasicPay(basicPay.toString());
    setHra(hra);
    setLta(lta);
    setSa(sa);
    setMedical(medical);
    setVariablePay(variablePay);
    setProfessionTax(professionTax);
    setIncomeTax(incomeTax);
    setPf(pf);
    totalGrossEarnings =
      Number(basicPay) +
      Number(hra) +
      Number(lta) +
      Number(sa) +
      Number(medical) +
      Number(variablePay);
    setGrossEarnings(totalGrossEarnings.toString());
    let getLopDays = Math.round((totalGrossEarnings / getDays) * lopDays);
    totalGrossDeductions =
      Number(professionTax) +
      Number(incomeTax) +
      Number(pf) +
      Number(getLopDays);
    setGrossDeductions(totalGrossDeductions.toString());
    var netPay = totalGrossEarnings - totalGrossDeductions;
    setNetPay(netPay.toString());
    setLop(getLopDays.toString());
    setTotalDays(getDays.toString());
    setEmployeeId(employeeId);
  };

  const onSubmitHandler = (event) => {
    event.preventDefault();
    editPaySlipHandler(
      paySlipId,
      employeeId,
      month,
      year,
      totalDays,
      lopDays,
      basicPay,
      hra,
      lta,
      sa,
      medical,
      variablePay,
      pf,
      grossEarnings,
      professionTax,
      incomeTax,
      grossDeductions,
      netpay,
      lop
    );
  };
  return (
    <Modal
      show={showEditPaySlip}
      onHide={closeEditPaySlip}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Edit PaySlip</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={onSubmitHandler} encType="multipart/form-data">
          <Form.Group>
            <Form.Label>Employee</Form.Label>
            <span style={{ color: "red" }}>*</span>
            <Form.Control
              as="select"
              placeholder=""
              value={employeeId}
              onChange={(event) => setEmployeeId(event.target.value)}
              required 
            >
              <option value="">select</option>
              {employees.map((employee, index) => {
                return (
                  <option key={index} value={employee.employeeId}>
                    {employee.name}
                  </option>
                );
              })}
            </Form.Control>
          </Form.Group>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Month</Form.Label>
                <span style={{ color: "red" }}>*</span>
                <Form.Control
                  as="select"
                  value={month}
                  onChange={(event) => setMonth(event.target.value)}
                  required
                >
                  <option value="">Select</option>
                  <option>01</option>
                  <option>02</option>
                  <option>03</option>
                  <option>04</option>
                  <option>05</option>
                  <option>06</option>
                  <option>07</option>
                  <option>08</option>
                  <option>09</option>
                  <option>10</option>
                  <option>11</option>
                  <option>12</option>
                </Form.Control>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Year</Form.Label>
                <span style={{ color: "red" }}>*</span>
                <Form.Control
                  as="select"
                  value={year}
                  onChange={(event) => setYear(event.target.value)}
                  required
                >
                  <option value="">Select</option>
                  {rangeYears.map((rangeYear, index) => {
                    return <option key={index}>{rangeYear}</option>;
                  })}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Total Days</Form.Label>
                <span style={{ color: "red" }}>*</span>
                <Form.Control
                  type="text"
                  placeholder="total days"
                  value={totalDays}
                  required
                  readOnly
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Lop Days</Form.Label>
                <span style={{ color: "red" }}>*</span>
                <Form.Control
                  type="text"
                  placeholder="lopdays"
                  value={lopDays}
                  onChange={(event) => setLopDays(event.target.value)}
                  required
                  readOnly
                />
              </Form.Group>
            </Col>
            </Row>
            <Row>
              <Col className="col-6">
              <Form.Label><h5>Earnings</h5></Form.Label>
             
              <Form.Group>
                <Form.Label>Basic Pay</Form.Label>
                <span style={{ color: "red" }}>*</span>
                <Form.Control
                  type="text"
                  placeholder="basic pay"
                  value={basicPay}
                  onChange={(event) => setBasicPay(event.target.value)}
                  required
                  readOnly
                />
              </Form.Group>
           
              <Form.Group>
                <Form.Label>H.R.A</Form.Label>
                <span style={{ color: "red" }}>*</span>
                <Form.Control
                  type="text"
                  placeholder="hrs"
                  value={hra}
                  onChange={(event) => setHra(event.target.value)}
                  required
                  readOnly
                />
              </Form.Group>
            
              <Form.Group>
                <Form.Label>L.T.A</Form.Label>
                <span style={{ color: "red" }}>*</span>
                <Form.Control
                  type="text"
                  placeholder="lta"
                  value={lta}
                  onChange={(event) => setLta(event.target.value)}
                  required
                  readOnly
                />
              </Form.Group>
           
              <Form.Group>
                <Form.Label>S.A</Form.Label>
                <span style={{ color: "red" }}>*</span>
                <Form.Control
                  type="text"
                  placeholder="sepecial allowance"
                  value={sa}
                  onChange={(event) => setSa(event.target.value)}
                  required
                  readOnly
                />
              </Form.Group>

              <Form.Group>
                <Form.Label>Medical</Form.Label>
                <span style={{ color: "red" }}>*</span>
                <Form.Control
                  type="text"
                  placeholder="medical"
                  value={medical}
                  onChange={(event) => setMedical(event.target.value)}
                  required
                  readOnly
                />
              </Form.Group>

              <Form.Group>
                <Form.Label>Variable Pay</Form.Label>
                <span style={{ color: "red" }}>*</span>
                <Form.Control
                  type="text"
                  placeholder="variable pay"
                  value={variablePay}
                  onChange={(event) => setVariablePay(event.target.value)}
                  required
                  readOnly
                />
              </Form.Group>
          
              </Col>
              <Col className="col-6">
              <Form.Label><h5>Deductions</h5></Form.Label>
              <Form.Group>
                <Form.Label>Profession Tax</Form.Label>
                <span style={{ color: "red" }}>*</span>
                <Form.Control
                  type="text"
                  placeholder="profession tax"
                  value={professionTax}
                  onChange={(event) => setProfessionTax(event.target.value)}
                  required
                  readOnly
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Income Tax</Form.Label>
                <span style={{ color: "red" }}>*</span>
                <Form.Control
                  type="text"
                  placeholder="income Tax"
                  value={incomeTax}
                  onChange={(event) => setIncomeTax(event.target.value)}
                  required
                  readOnly
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>P.F</Form.Label>
                <span style={{ color: "red" }}>*</span>
                <Form.Control
                  type="text"
                  placeholder="pf"
                  value={pf}
                  onChange={(event) => setPf(event.target.value)}
                  required
                  readOnly
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Lop</Form.Label>
                <span style={{ color: "red" }}>*</span>
                <Form.Control
                  type="text"
                  placeholder="lop"
                  value={lop}
                  onChange={(event) => setLop(event.target.value)}
                  required
                  readOnly
                />
              </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
              <Form.Group>
                <Form.Label><b>Gross earnings</b></Form.Label>
                <span style={{ color: "red" }}>*</span>
                <Form.Control
                  type="text"
                  placeholder="gross earnings"
                  value={grossEarnings}
                  onChange={(event) => setGrossEarnings(event.target.value)}
                  required
                  readOnly
                />
              </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label><b>Gross Deductions</b></Form.Label>
                  <span style={{ color: "red" }}>*</span>
                  <Form.Control
                    type="text"
                    placeholder="gross deductions"
                    value={grossDeductions}
                    onChange={(event) => setGrossDeductions(event.target.value)}
                    required
                    readOnly
                  />
              </Form.Group>
              </Col>
            </Row>
          
         
          <Row>

            <Col>
              <Form.Group>
                <Form.Label><b>Net Pay</b></Form.Label>
                <span style={{ color: "red" }}>*</span>
                <Form.Control
                  type="text"
                  placeholder="net pay"
                  value={netpay}
                  onChange={(event) => setNetPay(event.target.value)}
                  required
                  readOnly
                />
              </Form.Group>
            </Col>
          </Row>

          <hr />
          {
            message?message:""
          }
          <Button type="submit" className="box secondary">
            Edit
          </Button>
          <Button
            style={{ marginLeft: "25px" }}
            className="box"
            onClick={closeEditPaySlip}
          >
            Close
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

// shows all PaySlip daily reports

const PaySlipItems = ({ paySlip, employees, leaveBalances, fetchPaySlips, setMessage,message }) => {
  const [showEditPaySlip, setShowEditPaySlip] = useState(false);
  const closeEditPaySlip = () => {
    setShowEditPaySlip(false);
  };
  const openEditPaySlip = () => {
    setShowEditPaySlip(true);
  };
  console.log(paySlip);
  const editPaySlipHandler = (
    paySlipId,
    employeeId,
    month,
    year,
    totalDays,
    lopDays,
    basicPay,
    hra,
    lta,
    sa,
    medical,
    variablePay,
    pf,
    grossEarnings,
    professionTax,
    incomeTax,
    grossDeductions,
    netpay,
    lop
  ) => {
    let content = {
      "employeeId": employeeId,
      "monthYear": month + "/" + year,
      "totalDays": totalDays,
      "lopDays": lopDays,
      "basicPay": basicPay,
      "hra": hra,
      "lta": lta,
      "sa": sa,
      "medical": medical,
      "variablePay": variablePay,
      "pf": pf,
      "grossEarnings": grossEarnings,
      "professionTax": professionTax,
      "incomeTax": incomeTax,
      "grossDeductions": grossDeductions,
      "netpay": netpay,
      "lop": lop
    };
    
    api
      .put(`salary/?id=${paySlipId}`, content, getRequestConfig())
      .then((response) => {
        console.log(response.data);
        setShowEditPaySlip(true);
        fetchPaySlips();
        setMessage(<Alert variant="success">Salary Edited Successfully</Alert>)
    
    }).catch((ex)=>{
      setMessage(<Alert variant="danger">There is some error</Alert>)
    });
  };
  return (
    <>
      <tr>
        <td>{paySlip.employee.name} </td>
        <td>{paySlip.monthYear} </td>
        <td>{paySlip.totalDays} </td>
        <td>{paySlip.grossEarnings} </td>
        <td>{paySlip.grossDeductions} </td>
        <td>{paySlip.netpay} </td>
        <td>
          <Button style={{ marginRight: "10px" }} onClick={openEditPaySlip}>
            Edit PaySlip
          </Button>
        </td>
      </tr>

      <EditPaySlip
        showEditPaySlip={showEditPaySlip}
        closeEditPaySlip={closeEditPaySlip}
        editPaySlipHandler={editPaySlipHandler}
        paySlip={paySlip}
        employees={employees}
        leaveBalances={leaveBalances}
        message = {message}
      />
    </>
  );
};

const PaySlip = (userId) => {
  const [paySlips, setPaySlips] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [leaveBalances, setLeaveBalances] = useState([]);
  const [filterEmployee, setFilterEmployee] = useState(localStorage.getItem(`employee4${userId}`)?localStorage.getItem(`employee4${userId}`):[]);
  const [filterMonth, setFilterMonth] = useState(localStorage.getItem(`month4${userId}`)?localStorage.getItem(`month4${userId}`):[]);
  const [filterYear, setFilterYear] = useState(localStorage.getItem(`year4${userId}`)?localStorage.getItem(`year4${userId}`):[]);
  const [showAddPaySlip, setShowAddPaySlip] = useState(false);
  const [message, setMessage] = useState("");
  useEffect(() => {
    fetchLeaveBalances();
    fetchEmployees();
  }, []);

  useEffect(() => {
    fetchPaySlips(filterEmployee, filterMonth, filterYear);
  }, [filterEmployee, filterMonth, filterYear]);

  const resetFilter = () => {
    fetchPaySlips([]);
    localStorage.setItem(`employee4${userId}`,"");
    setFilterEmployee(localStorage.getItem(`employee4${userId}`));
    localStorage.setItem(`month4${userId}`,"");
    setFilterMonth(localStorage.getItem(`month4${userId}`));
    localStorage.setItem(`year4${userId}`,"");
    setFilterYear(localStorage.getItem(`year4${userId}`));
  };

  const sessionHandler = (event)=>{
    let name = event.target.name;
    let value=event.target.value;
    if(name=="employee")
    {
        localStorage.setItem(`employee4${userId}`, value);
        setFilterEmployee(localStorage.getItem(`employee4${userId}`));
    }
    else if(name=="month")
    {
      localStorage.setItem(`month4${userId}`, value);
      setFilterMonth(localStorage.getItem(`month4${userId}`));
    }
    else if(name=="year")
    {
      localStorage.setItem(`year4${userId}`, value);
      setFilterYear(localStorage.getItem(`year4${userId}`));
    }

}
  //get task
  const fetchPaySlips = (filterEmployee, filterMonth, filterYear) => {
    api
      .get(
        `salary/?employeeId=${filterEmployee ? filterEmployee : localStorage.getItem(`employee4${userId}`)}&month=${
          filterMonth ? filterMonth : localStorage.getItem(`month4${userId}`)
        }&year=${filterYear ? filterYear : localStorage.getItem(`year4${userId}`)}`,
        getRequestConfig()
      )
      .then((response) => {
        setPaySlips([]);
        setPaySlips(response.data);
      });
  };

  //fetch leave Balance

  const fetchLeaveBalances = () => {
    api.get(`leave_balance/`, getRequestConfig()).then((response) => {
      setLeaveBalances(response.data);
      console.log(response.data);
    });
  };
  //get years
  let rangeYears = [];
  for (let i = 12; i > 0; i--) {
    rangeYears.push(new Date().getFullYear() - i);
  }
  for (let i = 0; i < 12; i++) {
    rangeYears.push(new Date().getFullYear() + i);
  }
  //get employee
  const fetchEmployees = () => {
    api.get("employee/", getRequestConfig()).then((response) => {
      setEmployees(response.data);
     
    });
  };

  const closeAddPaySlip = () => {
    setShowAddPaySlip(false);
  };
  const openAddPaySlip = () => {
    setShowAddPaySlip(true);
  };

  const addPaySlipHandler = (
    employeeId,
    month,
    year,
    totalDays,
    lopDays,
    basicPay,
    hra,
    lta,
    sa,
    medical,
    variablePay,
    pf,
    grossEarnings,
    professionTax,
    incomeTax,
    grossDeductions,
    netpay,
    lop,
    plDays,
    elDays
  ) => {
    
    let content = {
      "employeeId": employeeId,
      "monthYear": month + "/" + year,
      "totalDays": totalDays,
      "lopDays": lopDays,
      "basicPay": basicPay,
      "hra": hra,
      "lta": lta,
      "sa": sa,
      "medical": medical,
      "variablePay": variablePay,
      "grossEarnings": grossEarnings,
      "professionTax": professionTax,
      "incomeTax": incomeTax,
      "pf": pf,
      "grossDeductions": grossDeductions,
      "netpay": netpay,
      "lop": lop,
      "plDays": plDays,
      "elDays": elDays
    };
  
    api.post("salary/",content,getRequestConfig()).then((response) => {
      setShowAddPaySlip(true);
      fetchPaySlips();
      setMessage(<Alert variant="success">Salary Added Successfully</Alert>)
    
    }).catch((ex)=>{
      setMessage(<Alert variant="danger">There is some error</Alert>)
    });
  };

  setTimeout(()=>{
    setMessage("");
    
  },10000);
  return (
    <div>
      <Row>
        <Col sm={12} md={6} lg={6} style={{ padding: "5px" }}>
          {" "}
          <h3>PaySlips</h3>
        </Col>
        <Col sm={12} md={6} lg={6} style={{ padding: "5px" }}>
          <Button onClick={openAddPaySlip} style={{ float: "right" }}>
            Add PaySlip
          </Button>
        </Col>
      </Row>
      <Row>
        <Col sm={12} md={3} lg={3} style={{ padding: "5px" }}>
          <Form.Group>
            <Form.Label>Select Employee</Form.Label>
            <Form.Control
              as="select"
              value={filterEmployee}
              name="employee"
              onChange={sessionHandler}
            >
              <option value="">Select</option>
              {employees.filter((employee)=>{if(employee.role!=="admin")return employee}).map((employee, index) => {
                return (
                  <option key={index} value={employee.employeeId}>
                    {employee.name}
                  </option>
                );
              })}
            </Form.Control>
          </Form.Group>
        </Col>
        <Col sm={12} md={3} lg={3} style={{ padding: "5px" }}>
          <Form.Group>
            <Form.Label>Month</Form.Label>
            <Form.Control
              as="select"
              value={filterMonth}
              name="month"
              onChange={sessionHandler}
              required
            >
              <option value="">Select</option>
              <option>01</option>
              <option>02</option>
              <option>03</option>
              <option>04</option>
              <option>05</option>
              <option>06</option>
              <option>07</option>
              <option>08</option>
              <option>09</option>
              <option>10</option>
              <option>11</option>
              <option>12</option>
            </Form.Control>
          </Form.Group>
        </Col>
        <Col sm={12} md={3} lg={3} style={{ padding: "5px" }}>
          <Form.Group>
            <Form.Label>Year</Form.Label>
            <Form.Control
              as="select"
              value={filterYear}
              name="year"
              onChange={sessionHandler}
              required
            >
              <option value="">Select</option>
              {rangeYears.map((rangeYear, index) => {
                return <option key={index}>{rangeYear}</option>;
              })}
            </Form.Control>
          </Form.Group>
        </Col>

        <Col style={{ padding: "12px" }}>
          <br />

          <Button
            style={{ marginLeft: "10px" }}
            variant="dark"
            onClick={resetFilter}
          >
            Reset
          </Button>
        </Col>
      </Row>

      <br />
      <Table className="table table-bordered">
        {paySlips.length === 0 && <h3>No Record Found</h3>}
        <thead>
          <tr>
            <th>Employee</th>
            <th>Month Year</th>
            <th>Total Days</th>
            <th>Gross Earnings</th>
            <th>Gross Deductions</th>
            <th>Net Pay</th>
            <th>Edit</th>
          </tr>
        </thead>
        <tbody>
          {paySlips.length !== 0 &&
            paySlips.filter((paySlip)=>paySlip.employee.role!=="admin").map((paySlip, index) => (
              <PaySlipItems
                key={index}
                paySlip={paySlip}
                employees={employees}
                leaveBalances={leaveBalances}
                fetchPaySlips={fetchPaySlips}
                setMessage ={setMessage}
                message={message}
              />
            ))}
        </tbody>
      </Table>
      <AddPaySlip
        showAddPaySlip={showAddPaySlip}
        closeAddPaySlip={closeAddPaySlip}
        addPaySlipHandler={addPaySlipHandler}
        employees={employees}
        leaveBalances={leaveBalances}
        message = {message}
      />
    </div>
  );
};
export default PaySlip;
