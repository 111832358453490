import React, { useState } from "react";
import { Row, Col, Nav } from "react-bootstrap";
import { Link, Route, Switch } from "react-router-dom";
import Home from "./Home";
import Attendance from "./Attendance";
import DailyReport from "./DailyReport";
import QualityControl from "../QualityControl";
import LeaveManagement from "./LeaveManagement";
import PaySlip from "./PaySlip";
import Header from "../Header";
const Dashboard = ({ user, setUser }) => {
  const [selection, setSelection] = useState("Dashboard");
  const userId = JSON.parse(user).employee.employee_id;
  return (
    <>
      <Header user={user} setUser={setUser} />
      
      <Row>
        <Col lg={1} className="side-nav" style={{textAlign:"center"}}>
          <Nav defaultActiveKey="/home" className="flex-column">
            <Link
              to="/home"
              className={selection == "Dashboard" ? "text current" : "text"}
              onClick={() => setSelection("Dashboard")}
            >
              <i className="fa fa-tachometer fa-3x"></i><br/>
              <span>Dashboard</span>
            </Link>
            <Link
              to="/attendance"
              className={selection == "Attendance" ? "text current" : "text"}
              onClick={() => setSelection("Attendance")}
            >
              <i class="fa fa-check-square-o fa-3x"></i><br/>
              <span>Attendance</span>
            </Link>
            <Link
              to="/dailyReport"
              className={selection == "Daily Report" ? "text current" : "text"}
              onClick={() => setSelection("Daily Report")}
            >
              <i class="fa fa-bar-chart fa-3x"></i><br/>
              <span>Daily Report</span>
            </Link>
            <Link
              to="/qualityControl"
              className={selection == "QC" ? "text current" : "text"}
              onClick={() => setSelection("QC")}
            >
              <i class="fa fa-tasks fa-3x"></i><br/>
              <span>Task</span>
            </Link>
            <Link
              to="/leaveRequest"
              className={selection == "LMS" ? "text current" : "text"}
              onClick={() => setSelection("LMS")}
            >
               <i class="fa fa-calendar-times-o fa-3x"></i><br/>
              <span>LMS</span>
            </Link>
            <Link
              to="/paySlip"
              className={selection == "Payslip" ? "text current" : "text"}
              onClick={() => setSelection("Payslip")}
            >
               <i class="fa fa-money fa-3x"></i><br/>
              <span>Payslip</span>
            </Link>
          </Nav>
        </Col>
        <Col lg={11} className="main">
          <Switch>
            <Route exact path="/home" component={Home} />
            <Route exact path="/attendance"  component={Attendance} />
            <Route exact path="/dailyReport" component={()=>DailyReport(userId)} />
            <Route exact path="/qualityControl" component={()=>QualityControl(userId)} />
            <Route exact path="/leaveRequest" component={LeaveManagement} />
            <Route exact path="/paySlip" component={()=>PaySlip(userId)} />

          </Switch>
        </Col>
      </Row>
    </>
  );
};
export default Dashboard;
