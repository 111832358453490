import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Modal,
  Table,
  Form,
  Button,
  Tabs,
  Tab,
  Alert
} from "react-bootstrap";
import api from "../../config/api";
import { getRequestConfig } from "../../utils/getRequestConfig";
//Edit leave management
const EditLeaveRequest = ({
  showEditLeaveRequest,
  closeEditLeaveRequest,
  editLeaveRequestHandler,
  leaveRequest,
  leaveTransactions,
  leaveBalance,
  message
}) => {
  const type = leaveRequest.leaveType;
  var today = new Date(),
  // get month in two digit
  currentMonth =
    today.getMonth() + 1 < "10"
      ? "0" + (today.getMonth() + 1)
      : today.getMonth() + 1,
  // get current date
  currentYear = today.getFullYear();

  const [month, setMonth] = useState(currentMonth);
  const [year, setYear] = useState(currentYear);

  const withDraw = leaveRequest.totalDays;
  const [status, setStatus] = useState(leaveRequest.status);
  const [comment, setComment] = useState("");
  const leaveRequestId = leaveRequest.id;
  const employeeId = leaveRequest.employeeId;

 
  const changeStatus = (e) => {
    let getStatus = e.target.value;
    setStatus(getStatus);
  };

  const onSubmitHandler = (event) => {
    event.preventDefault();
    editLeaveRequestHandler(
      leaveRequestId,
      employeeId,
      month,
      year,
      status,
      type,
      withDraw,
      comment
    );
  };
  return (
    <Modal
      show={showEditLeaveRequest}
      onHide={closeEditLeaveRequest}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Edit Leave Request</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={onSubmitHandler}>
          <Form.Group>
            <Form.Label>Status</Form.Label>
            <span style={{ color: "red" }}>*</span>
            <Form.Control
              as="select"
              value={status}
              onChange={changeStatus}
              required
            >
              <option value="">Select</option>
              <option>Rejected</option>
              <option>Approved</option>
            </Form.Control>
          </Form.Group>
          <Form.Group>
            <Form.Label>Comment</Form.Label>
            <textarea
              className="form-control"
              placeholder="comment"
              onChange={(event) => setComment(event.target.value)}
            />
          </Form.Group>
          <hr />
          {
            message?message:""
          }
          <Button type="submit" className="box secondary">
            Update
          </Button>
          <Button
            style={{ marginLeft: "25px" }}
            className="box"
            onClick={closeEditLeaveRequest}
          >
            Close
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

const AddLeaveBalance = ({
  showAddLeaveBalance,
  closeAddLeaveBalance,
  addLeaveBalanceHandler,
  employees,
  message
}) => {
  const [plBalance, setPlBalance] = useState("");
  const [elBalance, setElBalance] = useState("");
  const [employeeId, setEmployeeId] = useState("");

  var today = new Date(),
  // get month in two digit
  currentMonth =
    today.getMonth() + 1 < "10"
      ? "0" + (today.getMonth() + 1)
      : today.getMonth() + 1,
  // get current date
  currentYear = today.getFullYear();

  const [month, setMonth] = useState(currentMonth);
  const [year, setYear] = useState(currentYear);
 
  const onSubmitHandler = (e) => {
    e.preventDefault();
    addLeaveBalanceHandler(employeeId, plBalance, elBalance,month,year);
  };
  return (
    <Modal
      show={showAddLeaveBalance}
      onHide={closeAddLeaveBalance}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Add Leave Balance</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={onSubmitHandler}>
        <Form.Group>
            <Form.Label>Select Employee</Form.Label>
            <Form.Control
              as="select"
              value={employeeId}
              onChange={(event) => setEmployeeId(event.target.value)}
            >
              <option value="">Select</option>
              {employees.filter((employee)=>{if(employee.role!=="admin")return employee}).map((employee, index) => {
                return (
                  <option key={index} value={employee.employeeId}>
                    {employee.name}
                  </option>
                );
              })}
            </Form.Control>
          </Form.Group>
          <Form.Group>
            <Form.Label>Personal Leave</Form.Label>
            <span style={{ color: "red" }}>*</span>
            <Form.Control
              type="text"
              placeholder="personal leave"
              value={plBalance}
              onChange={(event) => setPlBalance(event.target.value)}
              required
            />
          </Form.Group>

          <Form.Group>
            <Form.Label>Earned Leave</Form.Label>
            <span style={{ color: "red" }}>*</span>
            <Form.Control
              type="text"
              placeholder="earned leave"
              value={elBalance}
              onChange={(event) => setElBalance(event.target.value)}
              required
            />
          </Form.Group>
          
          <hr />
          {
            message?message:""
          }
          <Button type="submit" className="box secondary">
            Add
          </Button>
          <Button
            style={{ marginLeft: "25px" }}
            className="box"
            onClick={closeAddLeaveBalance}
          >
            Close
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};
// leave transaction items
const LeaveTransactionItems = ({
  leaveTransaction,
  fetchLeaveTransactions,
}) => {
  return (
    <>
      <tr>
        <td>{leaveTransaction.employee} </td>
        <td>{leaveTransaction.date}</td>
        {
          leaveTransaction.type==="PL"?<td style={{ background: "yellow" }}>{leaveTransaction.type} </td>:<td style={{ background: "skyblue" }}>{leaveTransaction.type} </td>
        }
        <td>
          {leaveTransaction.fromDate?'From '+leaveTransaction.fromDate:""}
          {leaveTransaction.toDate?' To '+leaveTransaction.toDate:""}<br/>
          {leaveTransaction.reason?' Reason : '+leaveTransaction.reason:""}
        </td>
        <td>{leaveTransaction.deposit} </td>
        <td>{leaveTransaction.withdraw} </td>
        <td>{leaveTransaction.balance} </td>
      </tr>
    </>
  );
};
//Leave management items
const LeaveRequestItems = ({
  leaveRequest,
  leaveTransactions,
  leaveBalance,
  fetchLeaveRequests,
  fetchLeaveTransactions,
}) => {
  const [showEditLeaveRequest, setShowEditLeaveRequest] = useState(false);
  
  const [message, setMessage] = useState("");
  const closeEditLeaveRequest = () => {
    setShowEditLeaveRequest(false);
  };
  const openEditLeaveRequest = () => {
    setShowEditLeaveRequest(true);
  };
  const editLeaveRequestHandler = (
    leaveManagementId,
    employeeId,
    month,
    year,
    status,
    type,
    withDraw,
    comment
  ) => {
    var tempDate = new Date();
    var date = tempDate.getFullYear() + '-' + (tempDate.getMonth()+1) + '-' + tempDate.getDate() +' '+ tempDate.getHours()+':'+ tempDate.getMinutes()+':'+ tempDate.getSeconds();
   
    let content = {

      employeeId: employeeId,
      monthYear: month + "/" + year,
      status: status,
      type: type,
      withDraw: withDraw,
      modifiedAt:date,
      comment:comment
    };
   
    api
      .put(
        `leave_application/?id=${leaveManagementId}`,
        content,
        getRequestConfig()
      )
      .then((response) => {
        setShowEditLeaveRequest(true);
      
        fetchLeaveRequests();
        fetchLeaveTransactions();
        setMessage(<Alert variant="success">Leave Request {status} Successfully</Alert>)
    
      }).catch((ex)=>{
        setMessage(<Alert variant="danger">There is some error</Alert>)
      });
  };
 
  setTimeout(()=>{
    setMessage("");
    
  },10000);
  return (
    <>
      <tr>
        <td>{leaveRequest.employee}</td>
        <td>{leaveRequest.leaveType} </td>
        <td>{leaveRequest.fromDate} </td>
        <td>{leaveRequest.toDate} </td>
        <td>{leaveRequest.totalDays} </td>
        <td>{leaveRequest.reason} </td>
        <td>{leaveRequest.status} </td>
        <td>{leaveRequest.comment} </td>
       
          <td>
            <Button
              style={{ marginRight: "10px" }}
              onClick={openEditLeaveRequest}
            >
              Edit Status
            </Button>
           
          </td>
       
      </tr>
      <EditLeaveRequest
        showEditLeaveRequest={showEditLeaveRequest}
        closeEditLeaveRequest={closeEditLeaveRequest}
        editLeaveRequestHandler={editLeaveRequestHandler}
        leaveRequest={leaveRequest}
        leaveTransactions={leaveTransactions}
        leaveBalance={leaveBalance}
        message={message}
      />

    
    </>
  );
};
const LeaveManagement = (userId) => {
  let user = JSON.parse(localStorage.getItem("loggedUser"))
    ? JSON.parse(localStorage.getItem("loggedUser"))
    : "";
  let authToken = JSON.parse(user).authToken;
  const [leaveRequests, setLeaveRequests] = useState([]);
  const [leaveTransactions, setLeaveTransactions] = useState([]);
  const [leaveBalance, setLeaveBalance] = useState([]);
  const [showAddLeaveBalance, setshowAddLeaveBalance] = useState(false);
  const [employees, setEmployees] = useState([]);
  const [message, setMessage] = useState("");
  const [filterEmployee, setFilterEmployee] = useState(localStorage.getItem(`employee5${userId}`)?localStorage.getItem(`employee5${userId}`):[]);
  const [filterMonth, setFilterMonth] = useState(localStorage.getItem(`month5${userId}`)?localStorage.getItem(`month5${userId}`):[]);
  const [filterYear, setFilterYear] = useState(localStorage.getItem(`year5${userId}`)?localStorage.getItem(`year5${userId}`):[]);
  useEffect(() => {
    fetchLeaveRequests();
    fetchLeaveBalance();
    fetchEmployees();
  }, []);

  useEffect(() => {
    fetchLeaveTransactions(filterEmployee, filterMonth, filterYear);
  }, [filterEmployee, filterMonth, filterYear]);
  const resetFilter = () => {
    fetchLeaveTransactions([]);
    localStorage.setItem(`employee5${userId}`,"");
    setFilterEmployee(localStorage.getItem(`employee5${userId}`));
    localStorage.setItem(`month5${userId}`,"");
    setFilterMonth(localStorage.getItem(`month5${userId}`));
    localStorage.setItem(`year5${userId}`,"");
    setFilterYear(localStorage.getItem(`year5${userId}`));
  };

  const sessionHandler = (event)=>{
    let name = event.target.name;
    let value=event.target.value;
    if(name=="employee")
    {
        localStorage.setItem(`employee5${userId}`, value);
        setFilterEmployee(localStorage.getItem(`employee5${userId}`));
    }
    else if(name=="month")
    {
      localStorage.setItem(`month5${userId}`, value);
      setFilterMonth(localStorage.getItem(`month5${userId}`));
    }
    else if(name=="year")
    {
      localStorage.setItem(`year5${userId}`, value);
      setFilterYear(localStorage.getItem(`year5${userId}`));
    }
    

}
//get employee
const fetchEmployees = () => {
  api.get("employee/", getRequestConfig()).then((response) => {
    setEmployees(response.data);
   
  });
};
  //fetch LMS
  const fetchLeaveRequests = () => {
    api.get("leave_application/", getRequestConfig()).then((response) => {
      setLeaveRequests(response.data);
      console.log(response.data);
    });
  };
  //fetch LTS
  const fetchLeaveTransactions = (filterEmployee, filterMonth, filterYear) => {

    api.get(`leave_transaction/?employeeId=${filterEmployee ? filterEmployee : localStorage.getItem(`employee5${userId}`)}&month=${filterMonth ? filterMonth : localStorage.getItem(`month5${userId}`)}&year=${filterYear ? filterYear : localStorage.getItem(`year5${userId}`)}`, getRequestConfig()).then((response) => {
      
      setLeaveTransactions([]);
      setLeaveTransactions(response.data);
 
    });
  };

   //get years
   let rangeYears = [];
   for (let i = 12; i > 0; i--) {
     rangeYears.push(new Date().getFullYear() - i);
   }
   for (let i = 0; i < 12; i++) {
     rangeYears.push(new Date().getFullYear() + i);
   }
  //fetch leave balance

  const fetchLeaveBalance = () => {
    api.get("leave_balance/", getRequestConfig()).then((response) => {
      setLeaveBalance(response.data);
      console.log(response.data);
    });
  };
  const [key, setKey] = useState("Leave applications");
 
  //add leave balace
  const closeAddLeaveBalance = () => {
    setshowAddLeaveBalance(false);
  };
  const openAddLeaveBalance = () => {
    setshowAddLeaveBalance(true);
  };
  const addLeaveBalanceHandler = (employeeId, plBalance, elBalance, month,year) => {
    let content = {
      plBalance: plBalance,
      elBalance: elBalance,
      monthYear: month+"/"+year,
      leaveTransactions: [
        { type: "PL", deposit: plBalance},
        { type: "EL", deposit: elBalance },
      ],
     
    };
    api
      .put(
        `leave_balance/?employeeId=${employeeId}`,
        content,
        getRequestConfig()
      )
      .then((response) => {
        setshowAddLeaveBalance(true);
       
        setMessage(<Alert variant="success">Leave balance Added Successfully</Alert>)
    
      }).catch((ex)=>{
        setMessage(<Alert variant="danger">There is some error</Alert>)
      });
  };
  setTimeout(()=>{
    setMessage("");
    
  },10000);
  return (
    <>
      <Row>
        <Col sm={12} md={6} lg={6} style={{ padding: "5px" }}>
          {" "}
          <h4>Leave Management System</h4>
        </Col>
        <Col sm={12} md={6} lg={6} style={{ padding: "5px" }}>
          {" "}
          <Button
              style={{ marginRight: "10px" }}
              onClick={openAddLeaveBalance}
              style={{ float: "right" }}
            >
              Add Leave Balance
            </Button>
        </Col>

      </Row>

      <br />
      
      <Tabs
        id="controlled-tab-example"
        activeKey={key}
        onSelect={(k) => setKey(k)}
      >
        <Tab eventKey="Leave applications" title="Leave applications">
          <Table className="table table-bordered">
            <thead>
              <tr>
                <th>Employee</th>
                <th>Leave Type</th>
                <th>From Date</th>
                <th>To Date</th>
                <th>Total Days</th>
                <th>Reason</th>
                <th>Status</th>
                <th>comment</th>
                <th>Edit</th>
              </tr>
            </thead>
            <tbody>
              {leaveRequests.length === 0 && <h4>No Record Found</h4>}
              {leaveRequests.length !== 0 &&
                leaveRequests.filter((leaveRequest)=>leaveRequest.role!=="admin").map((leaveRequest, index) => (
                  <LeaveRequestItems
                    key={index}
                    leaveRequest={leaveRequest}
                    leaveTransactions={leaveTransactions}
                    leaveBalance={leaveBalance}
                    fetchLeaveRequests={fetchLeaveRequests}
                    fetchLeaveTransactions={fetchLeaveTransactions}
                  />
                ))}
            </tbody>
          </Table>
        </Tab>
        <Tab eventKey="Leave transactions" title="Leave transactions">
        <Row>
        <Col sm={12} md={3} lg={3} style={{ padding: "5px" }}>
          <Form.Group>
            <Form.Label>Select Employee</Form.Label>
            <Form.Control
              as="select"
              value={filterEmployee}
              name="employee"
              onChange={sessionHandler}
            >
              <option value="">Select</option>
              {employees.filter((employee)=>{if(employee.role!=="admin")return employee}).map((employee, index) => {
                return (
                  <option key={index} value={employee.employeeId}>
                    {employee.name}
                  </option>
                );
              })}
            </Form.Control>
          </Form.Group>
        </Col>
        <Col sm={12} md={3} lg={3} style={{ padding: "5px" }}>
          <Form.Group>
            <Form.Label>Month</Form.Label>
            <Form.Control
              as="select"
              value={filterMonth}
              name="month"
              onChange={sessionHandler}
              required
            >
              <option value="">Select</option>
              <option>01</option>
              <option>02</option>
              <option>03</option>
              <option>04</option>
              <option>05</option>
              <option>06</option>
              <option>07</option>
              <option>08</option>
              <option>09</option>
              <option>10</option>
              <option>11</option>
              <option>12</option>
            </Form.Control>
          </Form.Group>
        </Col>
        <Col sm={12} md={3} lg={3} style={{ padding: "5px" }}>
          <Form.Group>
            <Form.Label>Year</Form.Label>
            <Form.Control
              as="select"
              value={filterYear}
              name="year"
              onChange={sessionHandler}
              required
            >
              <option value="">Select</option>
              {rangeYears.map((rangeYear, index) => {
                return <option key={index}>{rangeYear}</option>;
              })}
            </Form.Control>
          </Form.Group>
        </Col>

        <Col style={{ padding: "12px" }}>
          <br />

          <Button
            style={{ marginLeft: "10px" }}
            variant="dark"
            onClick={resetFilter}
          >
            Reset
          </Button>
        </Col>
      </Row>
      <br/>
          <Table className="table table-bordered">
            <thead>
              <tr>
                <th>Employee</th>
                <th>Date</th>
                <th>Type</th>
                <th>Details</th>
                <th>deposit</th>
                <th>withdraw</th>
                <th>Balance</th>
              </tr>
            </thead>
            <tbody>
              {leaveTransactions.length === 0 && <h4>No Record Found</h4>}
              {leaveTransactions.length !== 0 &&
                leaveTransactions.filter((employee)=>employee.role!=="admin").map((leaveTransaction, index) => (
                  <LeaveTransactionItems
                    key={index}
                    leaveTransaction={leaveTransaction}
                    fetchLeaveTransactions={fetchLeaveTransactions}
                  />
                ))}
            </tbody>
          </Table>
        </Tab>
      </Tabs>
      <AddLeaveBalance
        showAddLeaveBalance={showAddLeaveBalance}
        closeAddLeaveBalance={closeAddLeaveBalance}
        addLeaveBalanceHandler={addLeaveBalanceHandler}
        employees={employees}
        message={message}
      />
    </>
  );
};
export default LeaveManagement;
