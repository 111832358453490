import React, { useState, useEffect } from "react";
import api from "../../config/api";
import { Card, Row, Col, Button,Alert } from "react-bootstrap";
import { getRequestConfig } from "../../utils/getRequestConfig";

const Attendance = () => {
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem("loggedUser"))
  );

  const [myAttendance, setMyAttendance] = useState([]);
  const [timer, setTimer] = useState(new Date());
  const [checkinFlag, setCheckinFlag] = useState(true);
  const [message, setMessage] = useState("")
  useEffect(() => {
    fetchAttendances();
    runTimer();
  }, []);

  const fetchAttendances = () => {
    api.get("attendance/", getRequestConfig()).then((response) => {
      console.log(response.data);
      const data = response.data;
      setMyAttendance(response.data);
      setCheckinFlag(true);
      data.map((item, index) => {
        if (item.status === "checkedin") {
          setCheckinFlag(false);
        }
      });
    });
  };

  const checkoutHandler = (attendanceId) => {
    var tempDate = new Date();
    var date = tempDate.getFullYear() + '-' + (tempDate.getMonth()+1) + '-' + tempDate.getDate() +' '+ tempDate.getHours()+':'+ tempDate.getMinutes()+':'+ tempDate.getSeconds();
   
    let content = {checkout:date,status:"checkedout"};
    api
      .put(`attendance/?id=${attendanceId}`, content, getRequestConfig())
      .then((response) => {
        fetchAttendances();
        setMessage(<Alert variant="success">Checkout Successfully</Alert>)
    
      }).catch((ex)=>{
        setMessage(<Alert variant="danger">There is some error</Alert>)
      });
  };

  const checkinHandler = () => {
    var tempDate = new Date();
    var date = tempDate.getFullYear() + '-' + (tempDate.getMonth()+1) + '-' + tempDate.getDate() +' '+ tempDate.getHours()+':'+ tempDate.getMinutes()+':'+ tempDate.getSeconds();
   
    let content = {checkin:date,status:"checkedin"};
    api
      .post(`attendance/`,content, getRequestConfig())
      .then((response) => {
        fetchAttendances();
        setMessage(<Alert variant="success">Checkin Successfully</Alert>)
    
      }).catch((ex)=>{
        setMessage(<Alert variant="danger">There is some error</Alert>)
      });
  };

  const runTimer = () => {
    setInterval(() => setTimer(new Date()), 1000);
  };

  setTimeout(()=>{
    setMessage("");
    
  },10000);
  return (
    <>
      <h3>
        {timer.toLocaleDateString()} {timer.toLocaleTimeString()}
      </h3>

      <Row>
        <Col>
          {checkinFlag ? (
            <Button onClick={checkinHandler}>Check-in</Button>
          ) : (
            <Button className="btn-primary-disabled">Check-in</Button>
          )}
        </Col>
      </Row>

      <br />
      
        {
            message?message:""
        }
      <Card style={{ fontWeight: "650" }}>
        <Row>
          <Col>Date</Col>
          <Col>Start</Col>
          <Col>End</Col>
        </Row>
      </Card>
      {
        myAttendance.length ===0 && <h4>Loading...</h4>
      }
      {myAttendance.length !==0 && myAttendance.map((item, index) => {
        return (
          <AttendanceItem
            attendance={item}
            key={index}
            checkoutHandler={checkoutHandler}
          />
        );
      })}
    </>
  );
};

const AttendanceItem = ({ attendance, checkoutHandler }) => {
  const cardStyle =
    attendance.status === "checkedin" ? { backgroundColor: "#FFDE80" } : {};
  const checkinDate = new Date(attendance.checkin);
  const dateString =
    checkinDate.getDate() +
    "-" +
    (checkinDate.getMonth() + 1) +
    "-" +
    checkinDate.getFullYear();
  const checkoutDate = attendance.checkout ? new Date(attendance.checkout) : "";
  return (
    <Card style={cardStyle}>
      <Row>
        <Col>{dateString}</Col>
        <Col>{checkinDate.getHours() + ":" + checkinDate.getMinutes()}</Col>
        <Col>
          {attendance.checkout ? (
            checkoutDate.getHours() + ":" + checkoutDate.getMinutes()
          ) : (
            <Button onClick={() => checkoutHandler(attendance.id)}>
              Checkout
            </Button>
          )}
        </Col>
      </Row>
    </Card>
  );
};

export default Attendance;
