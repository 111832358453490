import React, { useState } from "react";
import { Row, Col, Card, Form, Button, Alert } from "react-bootstrap";
import api from "../config/api";
import { Link, BrowserRouter as Router, Route, Switch } from "react-router-dom";
import ForgetPassword from "./ForgetPassword";
const Login = ({ setUser }) => {
  const [employeeId, setEmployeeId] = useState("");
  const [password, setPassword] = useState("");
  const [showForgetPassword, setShowForgetPassword] = useState(false);
  const [message, setMessage] = useState("");
  //login submit
  const loginSubmit = (employeeId, password) => {
    let content = { employeeId: employeeId, password: password };
    api
      .post("login/", content)
      .then((res) => {
        if (res.status === 200) {
          let loggedUser = JSON.stringify(res.data);
          localStorage.setItem("loggedUser", JSON.stringify(loggedUser));
          setUser(loggedUser);
          console.log(res.data);
        } else {
          setMessage(<Alert variant="danger">{"user id/password is incorrect or user is inactive"}</Alert>);
          console.log(res.data);
        }
      })
      .catch((err) => {
        setMessage(<Alert variant="danger">{"user id/password is incorrect or user is inactive"}</Alert>);

        console.log(err);
      });
  };

  const closeForgetPassword = () => {
    setShowForgetPassword(false);
  };
  const openForgetPassword = () => {
    setShowForgetPassword(true);
  };

  const forgetPassword = (userId, setAlert) => {
    api.put(`change_password/?userId=${userId}`).then((response) => {
      setShowForgetPassword(true);
      let content = {
        message: "Generated new Password",
        email: "Kindly Open your gmail account ",
      };
      if(response.status===204){
        setAlert(<small style={{color:"red"}}>{"please give your correct user Id"}</small>);
        
      }else
      {
        setAlert(<small style={{color:"green"}}>{content.message}<br/>{content.email}</small>);
      }
     
    }).catch((err) => {
      
      setAlert(<small style={{color:"red"}}>{"please give your correct user Id"}</small>);
    })
  };
  setTimeout(()=>{
    setMessage("");

  },10000);
  return (
    <>
         
      <Row>
      
        <Col lg={8} className="left-section">
          <img
            src={process.env.PUBLIC_URL + "/images/logo.png"}
            className="emblem"
          />
        </Col>
        <Col lg={4} className="right-section">
          {
              message?message:""
          }
          <Card>
            <Card.Header>
              <h4 style={{ color: "#3E78C5" }}>
                <i className="fa fa-key"></i> Employee Login
              </h4>
            </Card.Header>
            <Card.Body>
              <Form.Label style={{ color: "#3E78C5" }}>
                <b>User Id</b>
              </Form.Label>
              <Form.Control
                type="text"
                value={employeeId}
                onChange={(event) => setEmployeeId(event.target.value)}
              />
              <br />
              <Form.Label style={{ color: "#3E78C5" }}>
                <b>Password</b>
              </Form.Label>
              <Form.Control
                type="password"
                value={password}
                onChange={(event) => setPassword(event.target.value)}
              />
            </Card.Body>
            <Card.Footer>
              <Row>
                <Col style={{ color: "#3E78C5" }}>
                  <a onClick={openForgetPassword} style={{ cursor: "pointer" }}>
                    forget Password ?
                  </a>
                </Col>
                <Col style={{ textAlign: "right" }}>
                  <Button onClick={() => loginSubmit(employeeId, password)}>
                    Login
                  </Button>
                </Col>
              </Row>
            </Card.Footer>
          </Card>
        </Col>
      </Row>
      <ForgetPassword
        showForgetPassword={showForgetPassword}
        closeForgetPassword={closeForgetPassword}
        forgetPassword={forgetPassword}
      />
    </>
  );
};
export default Login;
