import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Table,
  span,
  Nav,
  Button,
  Modal,
  Form,
  Alert
} from "react-bootstrap";
import api from "../../config/api";
import { getRequestConfig } from "../../utils/getRequestConfig";

const AddProject = ({ showAddProject, closeAddProject, addProjectHandler,message }) => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [productOwner, setProductOwner] = useState("");
  const [startedDate, setStartedDate] = useState("");
  const [expectedCompletionDate, setExpectedCompletionDate] = useState("");
  const [estimatedHours, setEstimatedHours] = useState("");

  const onSubmitHandler = (event) => {
    event.preventDefault();
    addProjectHandler(
      title,
      description,
      productOwner,
      startedDate,
      expectedCompletionDate,
      estimatedHours
    );
  };
  return (
    <Modal
      show={showAddProject}
      onHide={closeAddProject}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Add Project</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={onSubmitHandler}>
          <Form.Group>
            <Form.Label>Title</Form.Label>
            <span style={{ color: "red" }}>*</span>
            <Form.Control
              type="text"
              placeholder="title"
              onChange={(event) => setTitle(event.target.value)}
              required
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Description</Form.Label>
            <Form.Control
              type="text"
              placeholder="description"
              onChange={(event) => setDescription(event.target.value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Product Owner</Form.Label>
            <span style={{ color: "red" }}>*</span>
            <Form.Control
              type="text"
              placeholder="product owner"
              onChange={(event) => setProductOwner(event.target.value)}
              required
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Started Date</Form.Label>
            <span style={{ color: "red" }}>*</span>
            <Form.Control
              type="date"
              onChange={(event) => setStartedDate(event.target.value)}
              required
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Expected Completion Date</Form.Label>
            <span style={{ color: "red" }}>*</span>
            <Form.Control
              type="date"
              placeholder="expected completion date"
              onChange={(event) =>
                setExpectedCompletionDate(event.target.value)
              }
              required
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Estimated Hours</Form.Label>
            <span style={{ color: "red" }}>*</span>
            <Form.Control
              type="text"
              placeholder="estimate hours"
              onChange={(event) => setEstimatedHours(event.target.value)}
              required
            />
          </Form.Group>
          <hr />
          {
            message?message:""
          }
          <Button type="submit" className="box secondary">
            Add
          </Button>
          <Button
            style={{ marginLeft: "25px" }}
            className="box"
            onClick={closeAddProject}
          >
            Close
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

//edit daily report
const EditProject = ({
  showEditProject,
  closeEditProject,
  editProjectHandler,
  project,
  message
}) => {
  const [title, setTitle] = useState(project.title);
  const [description, setDescription] = useState(project.description);
  const [productOwner, setProductOwner] = useState(project.productOwner);
  const [startedDate, setStartedDate] = useState(project.startedDate);
  const [expectedCompletionDate, setExpectedCompletionDate] = useState(
    project.expectedCompletionDate
  );
  const [estimatedHours, setEstimatedHours] = useState(project.estimatedHours);
  const projectId = project.id;

  const onSubmitHandler = (event) => {
    event.preventDefault();
    editProjectHandler(
      projectId,
      title,
      description,
      productOwner,
      startedDate,
      expectedCompletionDate,
      estimatedHours
    );
  };
  return (
    <Modal
      show={showEditProject}
      onHide={closeEditProject}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Edit Project</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={onSubmitHandler}>
          <Form.Group>
            <Form.Label>Title</Form.Label>
            <span style={{ color: "red" }}>*</span>
            <Form.Control
              type="text"
              placeholder="title"
              value={title}
              onChange={(event) => setTitle(event.target.value)}
              required
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Description</Form.Label>
            <Form.Control
              type="text"
              placeholder="description"
              value={description}
              onChange={(event) => setDescription(event.target.value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Product Owner</Form.Label>
            <span style={{ color: "red" }}>*</span>
            <Form.Control
              type="text"
              placeholder="product owner"
              value={productOwner}
              onChange={(event) => setProductOwner(event.target.value)}
              required
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Started Date</Form.Label>
            <span style={{ color: "red" }}>*</span>
            <Form.Control
              type="date"
              value={startedDate}
              onChange={(event) => setStartedDate(event.target.value)}
              required
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Expected Completion Date</Form.Label>
            <span style={{ color: "red" }}>*</span>
            <Form.Control
              type="date"
              placeholder="expected completion date"
              value={expectedCompletionDate}
              onChange={(event) =>
                setExpectedCompletionDate(event.target.value)
              }
              required
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Estimated Hours</Form.Label>
            <span style={{ color: "red" }}>*</span>
            <Form.Control
              type="text"
              placeholder="estimate hours"
              value={estimatedHours}
              onChange={(event) => setEstimatedHours(event.target.value)}
              required
            />
          </Form.Group>
          <hr />
          {
            message?message:""
          }
          <Button type="submit" className="box secondary">
            Update
          </Button>
          <Button
            style={{ marginLeft: "25px" }}
            className="box"
            onClick={closeEditProject}
          >
            Close
          </Button>
        </Form>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
};

// shows all employee daily reports

const ProjectItems = ({ project, fetchProjects,setMessage,message }) => {
  const [showEditProject, setShowEditProject] = useState(false);
  const closeEditProject = () => {
    setShowEditProject(false);
  };
  const openEditProject = () => {
    setShowEditProject(true);
  };

  const editProjectHandler = (
    projectId,
    title,
    description,
    productOwner,
    startedDate,
    expectedCompletionDate,
    estimatedHours
  ) => {
    let content = {
      title: title,
      description: description,
      productOwner: productOwner,
      startedDate: startedDate,
      expectedCompletionDate: expectedCompletionDate,
      estimatedHours: estimatedHours,
    };
    api
      .put(`project/?id=${projectId}`, content, getRequestConfig())
      .then((response) => {
        setShowEditProject(true);
        fetchProjects();
        setMessage(<Alert variant="success">Project Edited Successfully</Alert>)
    
      }).catch((ex)=>{
        setMessage(<Alert variant="danger">There is some error</Alert>)
      });
  };
  return (
    <>
      <tr>
        <td>{project.title} </td>
        <td>{project.description} </td>
        <td>{project.productOwner} </td>
        <td>{project.startedDate} </td>
        <td>{project.expectedCompletionDate}</td>
        <td>{project.estimatedHours}</td>
        <td>
          <Button style={{ marginRight: "10px" }} onClick={openEditProject}>
            Edit Project
          </Button>
        </td>
      </tr>

      <EditProject
        showEditProject={showEditProject}
        closeEditProject={closeEditProject}
        editProjectHandler={editProjectHandler}
        project={project}
        message = {message}
      />
    </>
  );
};

const Project = () => {
  const [projects, setProjects] = useState([]);
  const [showAddProject, setShowAddProject] = useState(false);
  const [message, setMessage] = useState("");
  useEffect(() => {
    fetchProjects();
  }, []);

  //get task
  const fetchProjects = () => {
    api.get("project/", getRequestConfig()).then((response) => {
      setProjects(response.data);
      console.log(response.data);
    });
  };

  const closeAddProject = () => {
    setShowAddProject(false);
  };
  const openAddProject = () => {
    setShowAddProject(true);
  };

  const addProjectHandler = (
    title,
    description,
    productOwner,
    startedDate,
    expectedCompletionDate,
    estimatedHours
  ) => {
    let content = {
      title: title,
      description: description,
      productOwner: productOwner,
      startedDate: startedDate,
      expectedCompletionDate: expectedCompletionDate,
      estimatedHours: estimatedHours,
    };

    api.post("project/", content, getRequestConfig()).then((response) => {
      setShowAddProject(true);
      fetchProjects();
      setMessage(<Alert variant="success">Project Added Successfully</Alert>)
    
    }).catch((ex)=>{
      setMessage(<Alert variant="danger">There is some error</Alert>)
    });
  };
  setTimeout(()=>{
    setMessage("");
    
  },10000);
  return (
    <div>
      <Row>
        <Col sm={12} md={6} lg={6} style={{ padding: "5px" }}>
          {" "}
          <h3>Project</h3>
        </Col>
        <Col sm={12} md={6} lg={6} style={{ padding: "5px" }}>
          <Button onClick={openAddProject} style={{ float: "right" }}>
            Add Project
          </Button>
        </Col>
      </Row>

      <br />
      <Table className="table table-bordered">
        {projects.length === 0 && <h3>Loading...</h3>}
        <thead>
          <tr>
            <th>Title</th>
            <th>Description</th>
            <th>Product Owner</th>
            <th>Started Date</th>
            <th>Expected Completion Date</th>
            <th>Estimated Hours</th>
            <th>Edit</th>
          </tr>
        </thead>
        <tbody>
          {projects.length !== 0 &&
            projects.map((project, index) => (
              <ProjectItems
                key={index}
                project={project}
                fetchProjects={fetchProjects}
                setMessage = {setMessage}
                message ={message}
              />
            ))}
        </tbody>
      </Table>
      <AddProject
        showAddProject={showAddProject}
        closeAddProject={closeAddProject}
        addProjectHandler={addProjectHandler}
        message ={message}
      />
    </div>
  );
};
export default Project;
