import React, { useState, useEffect } from "react";
import { Modal, Form, Button,Alert } from "react-bootstrap";
import api from "../config/api";
import {baseURL} from "../config/api";
import { getRequestConfig } from "../utils/getRequestConfig";

const EditProfile = ({
    showEditProfile,
    closeEditProfile,
    editProfileHandler
}) => {

  const [name, setName] = useState("");
  const [emailId, setEmailId] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [photoUrl, setPhotoUrl] = useState({});
  const [message, setMessage] = useState("");

  let user = JSON.parse(localStorage.getItem("loggedUser"))
  ? JSON.parse(localStorage.getItem("loggedUser"))
  : "";

  let employeeId = JSON.parse(user).employee.employee_id;
  useEffect(()=>{
    fetchEmployee();
  },[]);
  //Edit profile
  const fetchEmployee = () =>{
    api.get(`employee/?employeeId=${employeeId}`, getRequestConfig()).then((res)=>{
          setName(res.data.name);
          setEmailId(res.data.emailId);
          setContactNumber(res.data.contactNumber);
          setPhotoUrl(res.data.photoUrl);
    })
  }

  const onSubmitHandler= (event)=>{
    event.preventDefault();
    var formData = new FormData();
    formData.append("name", name);
    formData.append("emailId", emailId);
    formData.append("contactNumber", contactNumber);
    formData.append("photoUrl", photoUrl);
  
    editProfileHandler(formData,setMessage);
  }

  setTimeout(()=>{
    setMessage("");

  },10000);

  return (
    <Modal
      show={showEditProfile}
      onHide={closeEditProfile}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Edit Profile</Modal.Title>
      </Modal.Header>
      <Modal.Body>
       
        <Form onSubmit={onSubmitHandler} encType="multipart/form-data">
          <Form.Group>
          <Form.Label>Name</Form.Label>
            <Form.Control type="text" value={name} onChange={(event)=>setName(event.target.value)}/>
          </Form.Group>
          <Form.Group>
          <Form.Label>Email Id</Form.Label>
            <Form.Control type="email" value={emailId} onChange={(event)=>setEmailId(event.target.value)}/>
          </Form.Group>
          <Form.Group>
          <Form.Label>Contact Number</Form.Label>
            <Form.Control type="text" value={contactNumber} onChange={(event)=>setContactNumber(event.target.value)}/>
          </Form.Group>
          <Form.Group>
          <Form.Label>Profile Image</Form.Label>
            <Form.Control type="file" placeholder="photo" onChange={(event) =>setPhotoUrl(event.target.files[0])}>
          </Form.Control>
          <img 
            src={baseURL+"/"+ photoUrl}
            className="img img-thumbnail"
            
            alt="avatar" />
        </Form.Group>
        <hr/>
        {
          message?message:""
        }
            <Button
              type="submit"
              className="box secondary"
            >
              Update
            </Button>
            
            <Button
              style={{ marginLeft: "25px" }}
              className="box"
              onClick={closeEditProfile}
            >
              Close
            </Button>
        </Form>
      </Modal.Body>
   
    </Modal>
  );
};

export default EditProfile;
