import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Table,
  span,
  Nav,
  Button,
  ButtonGroup,
  Modal,
  Form,
  Alert
} from "react-bootstrap";
import api from "../../config/api";
import { invoicePdf, clientInvoicePdf } from "../../utils/invoicePdf";
import { getRequestConfig } from "../../utils/getRequestConfig";

const AddInvoice = ({
  showAddInvoice,
  closeAddInvoice,
  addInvoiceHandler,
  clients,
  message
}) => {
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [clientId, setClientId] = useState("");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");
  const [invoiceAmount, setInvoiceAmount] = useState("");
  const [paymentStatus, setPaymentStatus] = useState("");
  const [paymentMode, setPaymentMode] = useState("");
  const [paymentDate, setPaymentDate] = useState("");

  const [details, setDetails] = useState([]);
  const [amount, setAmount] = useState([]);
  const [invoiceDetails, setInvoiceDetails] = useState([]);

  // detail handler
  const detailHandler = (event) => {
    let detail = details;
    detail[event.target.name] = event.target.value;
    setDetails(detail);
  };

  //amount handler
  const amountHandler = (event) => {
    let amt = amount;
    amt[event.target.name] = event.target.value;
    setAmount(amt);
  };

  //get years
  let rangeYears = [];
  for (let i = 12; i > 0; i--) {
    rangeYears.push(new Date().getFullYear() - i);
  }
  for (let i = 0; i < 12; i++) {
    rangeYears.push(new Date().getFullYear() + i);
  }

  const onSubmitHandler = (event) => {
    event.preventDefault();
    let invDtl = invoiceDetails;
    details.map((item, index) => {
      let amt = amount[index];
      invDtl[index] = { detail: item, amount: amt };
    });
    setInvoiceDetails(invDtl);
    addInvoiceHandler(
      invoiceNumber,
      clientId,
      month,
      year,
      invoiceAmount,
      paymentStatus,
      paymentMode,
      paymentDate,
      invoiceDetails
    );
    setDetails("");
    setAmount("");
    setMonth("");
    setYear("");
  };

  return (
    <Modal
      show={showAddInvoice}
      onHide={closeAddInvoice}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Add Invoice</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={onSubmitHandler}>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Invoice Number</Form.Label>
                <span style={{ color: "red" }}>*</span>
                <Form.Control
                  type="text"
                  placeholder="Invoice number"
                  onChange={(event) => setInvoiceNumber(event.target.value)}
                  required
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Client</Form.Label>
                <span style={{ color: "red" }}>*</span>
                <Form.Control
                  as="select"
                  placeholder="contact person"
                  onChange={(event) => setClientId(event.target.value)}
                  required
                >
                  <option value="">Select</option>
                  {clients.map((client, index) => {
                    return (
                      <option key={index} value={client.id}>
                        {client.clientName}
                      </option>
                    );
                  })}
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Month</Form.Label>
                <span style={{ color: "red" }}>*</span>
                <Form.Control
                  as="select"
                  value={month}
                  onChange={(event) => setMonth(event.target.value)}
                  required
                >
                  <option value="">Select</option>
                  <option>01</option>
                  <option>02</option>
                  <option>03</option>
                  <option>04</option>
                  <option>05</option>
                  <option>06</option>
                  <option>07</option>
                  <option>08</option>
                  <option>09</option>
                  <option>10</option>
                  <option>11</option>
                  <option>12</option>
                </Form.Control>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Year</Form.Label>
                <span style={{ color: "red" }}>*</span>
                <Form.Control
                  as="select"
                  value={year}
                  onChange={(event) => setYear(event.target.value)}
                  required
                >
                  <option value="">Select</option>
                  {rangeYears.map((rangeYear, index) => {
                    return <option key={index}>{rangeYear}</option>;
                  })}
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Invoice Amount</Form.Label>
                <span style={{ color: "red" }}>*</span>
                <Form.Control
                  type="text"
                  placeholder="invoice amount"
                  onChange={(event) => setInvoiceAmount(event.target.value)}
                  required
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Payment Status</Form.Label>
                <span style={{ color: "red" }}>*</span>

                <Form.Control
                  as="select"
                  onChange={(event) => setPaymentStatus(event.target.value)}
                  required
                >
                  <option value="">Select</option>
                  <option>Paid</option>
                  <option>Pending</option>
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Payment Mode</Form.Label>
                <span style={{ color: "red" }}>*</span>
                <Form.Control
                  type="text"
                  placeholder="payment mode"
                  onChange={(event) => setPaymentMode(event.target.value)}
                  required
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Payment Date</Form.Label>
                <span style={{ color: "red" }}>*</span>
                <Form.Control
                  type="date"
                  placeholder="payment date"
                  onChange={(event) => setPaymentDate(event.target.value)}
                  required
                />
              </Form.Group>
            </Col>
          </Row>
          <Form.Group>
            <Form.Label>Invoice Details</Form.Label>
            <span style={{ color: "red" }}>*</span>
            <Table className="table table-bordered">
              <thead>
                <tr>
                  <th>Details</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <Form.Control
                      type="text"
                      placeholder="detail"
                      value={details[0]}
                      name={0}
                      onChange={detailHandler}
                      required
                    />
                  </td>
                  <td>
                    <Form.Control
                      type="text"
                      placeholder="amount"
                      value={amount[0]}
                      name={0}
                      onChange={amountHandler}
                      required
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <Form.Control
                      type="text"
                      placeholder="detail"
                      value={details[1]}
                      name={1}
                      onChange={detailHandler}
                    />
                  </td>
                  <td>
                    <Form.Control
                      type="text"
                      placeholder="amount"
                      value={amount[1]}
                      name={1}
                      onChange={amountHandler}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <Form.Control
                      type="text"
                      placeholder="detail"
                      value={details[2]}
                      name={2}
                      onChange={detailHandler}
                      
                    />
                  </td>
                  <td>
                    <Form.Control
                      type="text"
                      placeholder="amount"
                      value={amount[2]}
                      name={2}
                      onChange={amountHandler}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <Form.Control
                      type="text"
                      placeholder="detail"
                      value={details[3]}
                      name={3}
                      onChange={detailHandler}
                    />
                  </td>
                  <td>
                    <Form.Control
                      type="text"
                      placeholder="amount"
                      value={amount[3]}
                      name={3}
                      onChange={amountHandler}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <Form.Control
                      type="text"
                      placeholder="detail"
                      value={details[4]}
                      name={4}
                      onChange={detailHandler}
                    />
                  </td>
                  <td>
                    <Form.Control
                      type="text"
                      placeholder="amount"
                      value={amount[4]}
                      name={4}
                      onChange={amountHandler}
                    />
                  </td>
                </tr>
              </tbody>
            </Table>
          </Form.Group>
          <hr />
          {
            message?message:""
          }
          <Button type="submit" className="box secondary">
            Add
          </Button>
          <Button
            style={{ marginLeft: "25px" }}
            className="box"
            onClick={closeAddInvoice}
          >
            Close
          </Button>
        </Form>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
};

//edit invoice
const EditInvoice = ({
  showEditInvoice,
  closeEditInvoice,
  editInvoiceHandler,
  invoice,
  clients,
  message
}) => {
  const [invoiceNumber, setInvoiceNumber] = useState(invoice.invoiceNumber);
  const [clientId, setClientId] = useState(invoice.clientId);
  let monthYears = invoice.monthYear.split("/");
  let getMonth = monthYears[0];
  let getYear = monthYears[1];
  const [month, setMonth] = useState(getMonth);
  const [year, setYear] = useState(getYear);
  const [invoiceAmount, setInvoiceAmount] = useState(invoice.invoiceAmount);
  const [paymentStatus, setPaymentStatus] = useState(invoice.paymentStatus);
  const [paymentMode, setPaymentMode] = useState(invoice.paymentMode);
  const [paymentDate, setPaymentDate] = useState(invoice.paymentDate);
  const [details, setDetails] = useState([]);
  const [amount, setAmount] = useState([]);
  const [invoiceDetails, setInvoiceDetails] = useState([]);
  const [detailId, setDetailId] = useState([]);

  const invoiceId = invoice.id;
  const invoiceDtlId = invoice.invoiceDetails.map((invoiceDetailId, index) => {
    return invoiceDetailId.invoiceDtlId;
  });

  const detailHandler = (event) => {
    let detail = details;
    detail[event.target.name] = event.target.value;
    setDetails(detail);
  };
  useEffect(() => {
    let dtls = [];
    let amts = [];
    let ids = [];
    invoice.invoiceDetails.map((invoiceDetail, index) => {
      amts[index] = invoiceDetail.amount;
      dtls[index] = invoiceDetail.detail;
      ids[index] = invoiceDetail.invoiceDtlId;
    });

    setAmount(amts);
    setDetails(dtls);
    setDetailId(ids);
  }, []);

  //amount handler
  const amountHandler = (event) => {
    setAmount(
      amount.filter((item, index) => {
        if (index === event.target.name) {
          item = event.target.value;
        }
        return item;
      })
    );
    console.log(amount);
  };

  //get years
  let rangeYears = [];
  for (let i = 12; i > 0; i--) {
    rangeYears.push(new Date().getFullYear() - i);
  }
  for (let i = 0; i < 12; i++) {
    rangeYears.push(new Date().getFullYear() + i);
  }

  const onSubmitHandler = (event) => {
    event.preventDefault();
    let invDtl = invoiceDetails;
    details.map((item, index) => {
      let amt = amount[index];
      invDtl[index] = { detail: item, amount: amt };
    });
    setInvoiceDetails(invDtl);
    editInvoiceHandler(
      invoiceId,
      invoiceDtlId,
      invoiceNumber,
      clientId,
      month,
      year,
      invoiceAmount,
      paymentStatus,
      paymentMode,
      paymentDate,
      invoiceDetails
    );
  };
  return (
    <Modal
      show={showEditInvoice}
      onHide={closeEditInvoice}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Edit Invoice</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={onSubmitHandler}>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Invoice Number</Form.Label>
                <span style={{ color: "red" }}>*</span>
                <Form.Control
                  type="text"
                  placeholder="Invoice number"
                  value={invoiceNumber}
                  onChange={(event) => setInvoiceNumber(event.target.value)}
                  required readOnly
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Client</Form.Label>
                <span style={{ color: "red" }}>*</span>
                <Form.Control
                  as="select"
                  placeholder="contact person"
                  value={clientId}
                  onChange={(event) => setClientId(event.target.value)}
                  required
                >
                  <option value="">Select</option>
                  {clients.map((client, index) => {
                    return (
                      <option key={index} value={client.id}>
                        {client.clientName}
                      </option>
                    );
                  })}
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Month</Form.Label>
                <span style={{ color: "red" }}>*</span>
                <Form.Control
                  as="select"
                  value={month}
                  onChange={(event) => setMonth(event.target.value)}
                  required
                >
                  <option value="">Select</option>
                  <option>01</option>
                  <option>02</option>
                  <option>03</option>
                  <option>04</option>
                  <option>05</option>
                  <option>06</option>
                  <option>07</option>
                  <option>08</option>
                  <option>09</option>
                  <option>10</option>
                  <option>11</option>
                  <option>12</option>
                </Form.Control>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Year</Form.Label>
                <span style={{ color: "red" }}>*</span>
                <Form.Control
                  as="select"
                  value={year}
                  onChange={(event) => setYear(event.target.value)}
                  required
                >
                  <option value="">Select</option>
                  {rangeYears.map((rangeYear, index) => {
                    return <option key={index}>{rangeYear}</option>;
                  })}
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Invoice Amount</Form.Label>
                <span style={{ color: "red" }}>*</span>
                <Form.Control
                  type="text"
                  placeholder="invoice amount"
                  value={invoiceAmount}
                  onChange={(event) => setInvoiceAmount(event.target.value)}
                  required
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Payment Status</Form.Label>
                <span style={{ color: "red" }}>*</span>
                <Form.Control
                  as="select"
                  value={paymentStatus}
                  onChange={(event) => setPaymentStatus(event.target.value)}
                  required
                >
                  <option value="">Select</option>
                  <option>Paid</option>
                  <option>Pending</option>
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Payment Mode</Form.Label>
                <span style={{ color: "red" }}>*</span>
                <Form.Control
                  type="text"
                  placeholder="payment mode"
                  value={paymentMode}
                  onChange={(event) => setPaymentMode(event.target.value)}
                  required
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Payment Date</Form.Label>
                <span style={{ color: "red" }}>*</span>
                <Form.Control
                  type="date"
                  placeholder="payment date"
                  value={paymentDate}
                  onChange={(event) => setPaymentDate(event.target.value)}
                  required
                />
              </Form.Group>
            </Col>
          </Row>

          <Form.Group>
            <Form.Label>Invoice Details</Form.Label>
            <span style={{ color: "red" }}>*</span>
            <Table className="table table-bordered">
              <thead>
                <tr>
                  <th>Details</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <Form.Control
                      type="text"
                      placeholder="detail"
                      value={details[0]}
                      name={0}
                      onChange={detailHandler}
                      required
                    />
                  </td>
                  <td>
                    <Form.Control
                      type="text"
                      placeholder="amount"
                      value={amount[0]}
                      name={0}
                      onChange={amountHandler}
                      required
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <Form.Control
                      type="text"
                      placeholder="detail"
                      value={details[1]}
                      name={1}
                      onChange={detailHandler}
                    />
                  </td>
                  <td>
                    <Form.Control
                      type="text"
                      placeholder="amount"
                      value={amount[1]}
                      name={1}
                      onChange={amountHandler}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <Form.Control
                      type="text"
                      placeholder="detail"
                      value={details[2]}
                      name={2}
                      onChange={detailHandler}
                    
                    />
                  </td>
                  <td>
                    <Form.Control
                      type="text"
                      placeholder="amount"
                      value={amount[2]}
                      name={2}
                      onChange={amountHandler}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <Form.Control
                      type="text"
                      placeholder="detail"
                      value={details[3]}
                      name={3}
                      onChange={detailHandler}
                    />
                  </td>
                  <td>
                    <Form.Control
                      type="text"
                      placeholder="amount"
                      value={amount[3]}
                      name={3}
                      onChange={amountHandler}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <Form.Control
                      type="text"
                      placeholder="detail"
                      value={details[4]}
                      name={4}
                      onChange={detailHandler}
                    />
                  </td>
                  <td>
                    <Form.Control
                      type="text"
                      placeholder="amount"
                      value={amount[4]}
                      name={4}
                      onChange={amountHandler}
                    />
                  </td>
                </tr>
              </tbody>
            </Table>
          </Form.Group>

          <hr />
          {
            message?message:""
          }
          <Button type="submit" className="box secondary">
            Update
          </Button>
          <Button
            style={{ marginLeft: "25px" }}
            className="box"
            onClick={closeEditInvoice}
          >
            Close
          </Button>
        </Form>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
};

//copy invoice

const CopyInvoice = ({
  showCopyInvoice,
  closeCopyInvoice,
  copyInvoiceHandler,
  invoice,
  clients,
  message
}) => {
  const [invoiceNumber, setInvoiceNumber] = useState(invoice.invoiceNumber);
  const [clientId, setClientId] = useState(invoice.clientId);
  let monthYears = invoice.monthYear.split("/");
  let getMonth = monthYears[0];
  let getYear = monthYears[1];
  const [month, setMonth] = useState(getMonth);
  const [year, setYear] = useState(getYear);
  const [invoiceAmount, setInvoiceAmount] = useState(invoice.invoiceAmount);
  const [paymentStatus, setPaymentStatus] = useState(invoice.paymentStatus);
  const [paymentMode, setPaymentMode] = useState(invoice.paymentMode);
  const [paymentDate, setPaymentDate] = useState(invoice.paymentDate);
  const [details, setDetails] = useState([]);
  const [amount, setAmount] = useState([]);
  const [invoiceDetails, setInvoiceDetails] = useState([]);

  // detail handler
  const detailHandler = (event) => {
    let detail = details;
    detail[event.target.name] = event.target.value;
    setDetails(detail);
  };

  //amount handler
  const amountHandler = (event) => {
    let amt = amount;
    amt[event.target.name] = event.target.value;
    setAmount(amt);
  };

  //get years
  let rangeYears = [];
  for (let i = 12; i > 0; i--) {
    rangeYears.push(new Date().getFullYear() - i);
  }
  for (let i = 0; i < 12; i++) {
    rangeYears.push(new Date().getFullYear() + i);
  }
  useEffect(() => {
    let dtls = [];
    let amts = [];
    invoice.invoiceDetails.map((invoiceDetail, index) => {
      amts[index] = invoiceDetail.amount;
      dtls[index] = invoiceDetail.detail;
    });
    setAmount(amts);
    setDetails(dtls);
  }, []);

  const onSubmitHandler = (event) => {
    event.preventDefault();
    let invDtl = invoiceDetails;
    details.map((item, index) => {
      let amt = amount[index];
      invDtl[index] = { detail: item, amount: amt };
    });
    setInvoiceDetails(invDtl);
    copyInvoiceHandler(
      invoiceNumber,
      clientId,
      month,
      year,
      invoiceAmount,
      paymentStatus,
      paymentMode,
      paymentDate,
      invoiceDetails
    );
  };
  return (
    <Modal
      show={showCopyInvoice}
      onHide={closeCopyInvoice}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Copy Invoice</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={onSubmitHandler}>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Invoice Number</Form.Label>
                <span style={{ color: "red" }}>*</span>
                <Form.Control
                  type="text"
                  placeholder="Invoice number"
                  value={invoiceNumber}
                  onChange={(event) => setInvoiceNumber(event.target.value)}
                  required
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Client</Form.Label>
                <span style={{ color: "red" }}>*</span>
                <Form.Control
                  as="select"
                  placeholder="contact person"
                  value={clientId}
                  onChange={(event) => setClientId(event.target.value)}
                  required
                >
                  <option value="">Select</option>
                  {clients.map((client, index) => {
                    return (
                      <option key={index} value={client.id}>
                        {client.clientName}
                      </option>
                    );
                  })}
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Month</Form.Label>
                <span style={{ color: "red" }}>*</span>
                <Form.Control
                  as="select"
                  value={month}
                  onChange={(event) => setMonth(event.target.value)}
                  required
                >
                  <option value="">Select</option>
                  <option>01</option>
                  <option>02</option>
                  <option>03</option>
                  <option>04</option>
                  <option>05</option>
                  <option>06</option>
                  <option>07</option>
                  <option>08</option>
                  <option>09</option>
                  <option>10</option>
                  <option>11</option>
                  <option>12</option>
                </Form.Control>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Year</Form.Label>
                <span style={{ color: "red" }}>*</span>
                <Form.Control
                  as="select"
                  value={year}
                  onChange={(event) => setYear(event.target.value)}
                  required
                >
                  <option value="">Select</option>
                  {rangeYears.map((rangeYear, index) => {
                    return <option key={index}>{rangeYear}</option>;
                  })}
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Invoice Amount</Form.Label>
                <span style={{ color: "red" }}>*</span>
                <Form.Control
                  type="text"
                  placeholder="invoice amount"
                  value={invoiceAmount}
                  onChange={(event) => setInvoiceAmount(event.target.value)}
                  required
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Payment Status</Form.Label>
                <span style={{ color: "red" }}>*</span>
                <Form.Control
                  as="select"
                  value={paymentStatus}
                  onChange={(event) => setPaymentStatus(event.target.value)}
                  required
                >
                  <option value="">Select</option>
                  <option>Paid</option>
                  <option>Pending</option>
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Payment Mode</Form.Label>
                <span style={{ color: "red" }}>*</span>
                <Form.Control
                  type="text"
                  placeholder="payment mode"
                  value={paymentMode}
                  onChange={(event) => setPaymentMode(event.target.value)}
                  required
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Payment Date</Form.Label>
                <span style={{ color: "red" }}>*</span>
                <Form.Control
                  type="date"
                  placeholder="payment date"
                  value={paymentDate}
                  onChange={(event) => setPaymentDate(event.target.value)}
                  required
                />
              </Form.Group>
            </Col>
          </Row>

          <Form.Group>
            <Form.Label>Invoice Details</Form.Label>
            <span style={{ color: "red" }}>*</span>
            <Table className="table table-bordered">
              <thead>
                <tr>
                  <th>Details</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <Form.Control
                      type="text"
                      placeholder="detail"
                      value={details[0]}
                      name={0}
                      onChange={detailHandler}
                      required
                    />
                  </td>
                  <td>
                    <Form.Control
                      type="text"
                      placeholder="amount"
                      value={amount[0]}
                      name={0}
                      onChange={amountHandler}
                      required
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <Form.Control
                      type="text"
                      placeholder="detail"
                      value={details[1]}
                      name={1}
                      onChange={detailHandler}
                    />
                  </td>
                  <td>
                    <Form.Control
                      type="text"
                      placeholder="amount"
                      value={amount[1]}
                      name={1}
                      onChange={amountHandler}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <Form.Control
                      type="text"
                      placeholder="detail"
                      value={details[2]}
                      name={2}
                      onChange={detailHandler}
                    
                    />
                  </td>
                  <td>
                    <Form.Control
                      type="text"
                      placeholder="amount"
                      value={amount[2]}
                      name={2}
                      onChange={amountHandler}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <Form.Control
                      type="text"
                      placeholder="detail"
                      value={details[3]}
                      name={3}
                      onChange={detailHandler}
                    />
                  </td>
                  <td>
                    <Form.Control
                      type="text"
                      placeholder="amount"
                      value={amount[3]}
                      name={3}
                      onChange={amountHandler}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <Form.Control
                      type="text"
                      placeholder="detail"
                      value={details[4]}
                      name={4}
                      onChange={detailHandler}
                    />
                  </td>
                  <td>
                    <Form.Control
                      type="text"
                      placeholder="amount"
                      value={amount[4]}
                      name={4}
                      onChange={amountHandler}
                    />
                  </td>
                </tr>
              </tbody>
            </Table>
          </Form.Group>

          <hr />
          {
            message?message:""
          }
          <Button type="submit" className="box secondary">
            Add
          </Button>
          <Button
            style={{ marginLeft: "25px" }}
            className="box"
            onClick={closeCopyInvoice}
          >
            Close
          </Button>
        </Form>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
};
// shows all employee daily reports

const InvoiceItems = ({
  invoice,
  clients,
  fetchInvoices,
  downloadClientPdf,
  setMessage,
  message
}) => {

  //Edit invoice
  const [showEditInvoice, setShowEditInvoice] = useState(false);
  const closeEditInvoice = () => {
    setShowEditInvoice(false);
  };
  const openEditInvoice = () => {
    setShowEditInvoice(true);
  };

    //copy invoice
    const [showCopyInvoice, setShowCopyInvoice] = useState(false);
  const closeCopyInvoice = () => {
    setShowCopyInvoice(false);
  };
  const openCopyInvoice = () => {
    setShowCopyInvoice(true);
  };

  //edit invoice api
  const editInvoiceHandler = (
    invoiceId,
    invoiceDtlId,
    invoiceNumber,
    clientId,
    month,
    year,
    invoiceAmount,
    paymentStatus,
    paymentMode,
    paymentDate,
    invoiceDetails
  ) => {
    let content = {
      invoiceNumber: invoiceNumber,
      clientId: clientId,
      monthYear: month + "/" + year,
      invoiceAmount: invoiceAmount,
      paymentStatus: paymentStatus,
      paymentMode: paymentMode,
      paymentDate: paymentDate,
      invoiceDetails: invoiceDetails,
      invoiceDtlId: invoiceDtlId,
    };

    api
      .put(`invoice/?id=${invoiceId}`, content, getRequestConfig())
      .then((response) => {
        setShowEditInvoice(true);
        fetchInvoices();
        setMessage(<Alert variant="success">Invoice Edited Successfully</Alert>)
        
      }).catch((ex)=>{
        setMessage(<Alert variant="danger">There is some error</Alert>)
      });
  };

  //copty invoice api
  const copyInvoiceHandler = (
    invoiceNumber,
    clientId,
    month,
    year,
    invoiceAmount,
    paymentStatus,
    paymentMode,
    paymentDate,
    invoiceDetails
  ) => {
    let content = {
      invoiceNumber: invoiceNumber,
      clientId: clientId,
      monthYear: month + "/" + year,
      invoiceAmount: invoiceAmount,
      paymentStatus: paymentStatus,
      paymentMode: paymentMode,
      paymentDate: paymentDate,
      invoiceDetails: invoiceDetails,
    };

    api.post("invoice/", content, getRequestConfig()).then((response) => {
      setShowCopyInvoice(true);
      fetchInvoices();
      setMessage(<Alert variant="success">Invoice Added Successfully</Alert>)
        
      }).catch((ex)=>{
        setMessage(<Alert variant="danger">There is some error</Alert>)
      });
  };
  return (
    <>
      <tr>
        <td>{invoice.invoiceNumber} </td>
        <td>{invoice.client.clientName} </td>
        <td>{invoice.monthYear} </td>
        <td>{invoice.invoiceAmount} </td>
        <td>{invoice.paymentStatus} </td>
        <td>{invoice.paymentMode} </td>
        <td>{invoice.paymentDate} </td>
        <td>
        <ButtonGroup aria-label="Basic example">
  
            <Button  onClick={openEditInvoice}>
                Edit
              </Button>
         
            <Button
            
            onClick={() => downloadClientPdf(invoice)}
            >
              Download
            </Button>
            <Button
            onClick={openCopyInvoice}
            >
              Copy
            </Button>
            </ButtonGroup>
        </td>
      </tr>

      <EditInvoice
        showEditInvoice={showEditInvoice}
        closeEditInvoice={closeEditInvoice}
        editInvoiceHandler={editInvoiceHandler}
        invoice={invoice}
        clients={clients}
        message = {message}
      />
      <CopyInvoice
        showCopyInvoice={showCopyInvoice}
        closeCopyInvoice={closeCopyInvoice}
        copyInvoiceHandler={copyInvoiceHandler}
        invoice={invoice}
        clients={clients}
        message = {message}
      />
    </>
  );
};

const Invoice = (userId) => {
  const [invoices, setInvoices] = useState([]);
  const [clients, setClients] = useState([]);

  const [filterClient, setFilterClient] = useState(localStorage.getItem(`client3${userId}`)?localStorage.getItem(`client3${userId}`):[]);
  const [filterMonth, setFilterMonth] = useState(localStorage.getItem(`month3${userId}`)?localStorage.getItem(`month3${userId}`):[]);
  const [filterYear, setFilterYear] = useState(localStorage.getItem(`year3${userId}`)?localStorage.getItem(`year3${userId}`):[]);
  const [filterPaymentStatus, setFilterPaymentStatus] = useState(localStorage.getItem(`status3${userId}`)?localStorage.getItem(`status3${userId}`):[]);
  const [showAddInvoice, setShowAddInvoice] = useState(false);
  const [message, setMessage] = useState("");
  useEffect(() => {
    fetchClients();

    fetchInvoices();
  }, []);

  useEffect(() => {
 
    fetchInvoices(filterClient, filterMonth, filterYear, filterPaymentStatus);
  }, [filterClient, filterMonth, filterYear, filterPaymentStatus]);

  const resetFilter = () => {
    fetchInvoices([]);
    localStorage.setItem(`client3${userId}`,"");
    setFilterClient(localStorage.getItem(`client3${userId}`));
    localStorage.setItem(`month3${userId}`,"");
    setFilterMonth(localStorage.getItem(`month3${userId}`));
    localStorage.setItem(`year3${userId}`,"");
    setFilterYear(localStorage.getItem(`year3${userId}`));
    localStorage.setItem(`status3${userId}`,"");
    setFilterPaymentStatus(localStorage.getItem(`status3${userId}`));
  };

  const sessionHandler = (event)=>{
      let name = event.target.name;
      let value=event.target.value;
      if(name=="client")
      {
          localStorage.setItem(`client3${userId}`, value);
          setFilterClient(localStorage.getItem(`client3${userId}`));
      }
      else if(name=="month")
      {
        localStorage.setItem(`month3${userId}`, value);
        setFilterMonth(localStorage.getItem(`month3${userId}`));
      }
      else if(name=="year")
      {
        localStorage.setItem(`year3${userId}`, value);
        setFilterYear(localStorage.getItem(`year3${userId}`));
      }
      else if(name=="status")
      {
        localStorage.setItem(`status3${userId}`, value);
        setFilterPaymentStatus(localStorage.getItem(`status3${userId}`));
      }

  }
  //get task
  const fetchInvoices = (
    filterClient,
    filterMonth,
    filterYear,
    filterPaymentStatus
  ) => {
    api
      .get(
        `invoice/?client=${filterClient ? filterClient : localStorage.getItem(`client3${userId}`)}&month=${
          filterMonth ? filterMonth : localStorage.getItem(`month3${userId}`)
        }&year=${filterYear ? filterYear : localStorage.getItem(`year3${userId}`)}&paymentStatus=${
          filterPaymentStatus ? filterPaymentStatus : localStorage.getItem(`status3${userId}`)
        }`,
        getRequestConfig()
      )
      .then((response) => {
        console.log(response.data);
        setInvoices([]);
        setInvoices(response.data);
      });
  };
  //get task
  const fetchClients = () => {
    api.get("client/", getRequestConfig()).then((response) => {
      setClients(response.data);
    });
  };

  //get years
  let rangeYears = [];
  for (let i = 12; i > 0; i--) {
    rangeYears.push(new Date().getFullYear() - i);
  }
  for (let i = 0; i < 12; i++) {
    rangeYears.push(new Date().getFullYear() + i);
  }

  const closeAddInvoice = () => {
    setShowAddInvoice(false);
  };
  const openAddInvoice = () => {
    setShowAddInvoice(true);
  };

  const addInvoiceHandler = (
    invoiceNumber,
    clientId,
    month,
    year,
    invoiceAmount,
    paymentStatus,
    paymentMode,
    paymentDate,
    invoiceDetails
  ) => {
    let content = {
      invoiceNumber: invoiceNumber,
      clientId: clientId,
      monthYear: month + "/" + year,
      invoiceAmount: invoiceAmount,
      paymentStatus: paymentStatus,
      paymentMode: paymentMode,
      paymentDate: paymentDate,
      invoiceDetails: invoiceDetails,
    };

    api.post("invoice/", content, getRequestConfig()).then((response) => {
      setShowAddInvoice(true);
      fetchInvoices();
      setMessage(<Alert variant="success">Invoice Added Successfully</Alert>)
        
      }).catch((ex)=>{
        setMessage(<Alert variant="danger">There is some error</Alert>)
      });
  };
  const downloadPdf = (invoices) => {
    invoicePdf(invoices);
  };

  const downloadClientPdf = (invoice) => {
    clientInvoicePdf(invoice);
  };
  setTimeout(()=>{
    setMessage("");
    
  },10000);
  return (
    <div>
      <Row>
        <Col sm={12} md={6} lg={6} style={{ padding: "5px" }}>
          {" "}
          <h3>Invoice</h3>
        </Col>
        <Col sm={12} md={6} lg={6} style={{ padding: "5px" }}>
          <Button onClick={openAddInvoice} style={{ float: "right" }}>
            Add Invoice
          </Button>
        </Col>
      </Row>
      <Row>
        <Col sm={12} md={2} lg={2} style={{ padding: "5px" }}>
          <Form.Group>
            <Form.Label>Client</Form.Label>
            <Form.Control
              as="select"
              value={filterClient}
              name="client"
              onChange={sessionHandler}
            >
              <option value="">Select</option>
              {clients.map((client, index) => {
                return (
                  <option key={index} value={client.id}>
                    {client.clientName}
                  </option>
                );
              })}
            </Form.Control>
          </Form.Group>
        </Col>
        <Col sm={12} md={2} lg={2} style={{ padding: "5px" }}>
          <Form.Group>
            <Form.Label>Month</Form.Label>
            <Form.Control
              as="select"
              value={filterMonth}
              name="month"
              onChange={sessionHandler}
              required
            >
              <option value="">Select</option>
              <option>01</option>
              <option>02</option>
              <option>03</option>
              <option>04</option>
              <option>05</option>
              <option>06</option>
              <option>07</option>
              <option>08</option>
              <option>09</option>
              <option>10</option>
              <option>11</option>
              <option>12</option>
            </Form.Control>
          </Form.Group>
        </Col>
        <Col sm={12} md={2} lg={2} style={{ padding: "5px" }}>
          <Form.Group>
            <Form.Label>Year</Form.Label>
            <Form.Control
              as="select"
              value={filterYear}
              name="year"
              onChange={sessionHandler}
              required
            >
              <option value="">Select</option>
              {rangeYears.map((rangeYear, index) => {
                return <option key={index}>{rangeYear}</option>;
              })}
            </Form.Control>
          </Form.Group>
        </Col>
        <Col sm={12} md={2} lg={2} style={{ padding: "5px" }}>
          <Form.Group>
            <Form.Label>Status</Form.Label>
            <Form.Control
              as="select"
              value={filterPaymentStatus}
              name="status"
              onChange={sessionHandler}
              required
            >
              <option value="">Select</option>
              <option>Paid</option>
              <option>Pending</option>
            </Form.Control>
          </Form.Group>
        </Col>
        <Col sm={12} md={2} lg={2} style={{ padding: "12px" }}>
          <br />
          <Button
            style={{ marginLeft: "10px" }}
            variant="dark"
            onClick={resetFilter}
          >
            Reset
          </Button>
        </Col>
        <Col sm={12} md={2} lg={2} style={{ padding: "12px" }}>
        <br/>
        <Button onClick={() => downloadPdf(invoices)} style={{ marginLeft: "10px" }}>
        Download Pdf
      </Button>
        </Col>
      </Row>

     

      <br />
      <Table className="table table-bordered">
        {invoices.length === 0 && <h3>No Record Found</h3>}
        <thead>
          <tr>
            <th>Invoice Number</th>
            <th>Client</th>
            <th>Month Year</th>
            <th>Invoice Amount</th>
            <th>Payment Status</th>
            <th>Payment Mode</th>
            <th>Payment_date</th>
            <th>Edit</th>
          </tr>
        </thead>
        <tbody>
          {invoices.length !== 0 &&
            invoices.map((invoice, index) => (
              <InvoiceItems
                key={index}
                invoice={invoice}
                clients={clients}
                downloadClientPdf={downloadClientPdf}
                fetchInvoices={fetchInvoices}
                setMessage={setMessage}
                message={message}
              />
            ))}
        </tbody>
      </Table>
      <AddInvoice
        showAddInvoice={showAddInvoice}
        closeAddInvoice={closeAddInvoice}
        addInvoiceHandler={addInvoiceHandler}
        clients={clients}
        message={message}
      />
    </div>
  );
};
export default Invoice;
