import React, { useState } from "react";
import { BrowserRouter as Router,Route,Switch } from "react-router-dom";
import "./App.css";
import Login from "./Login";
import EmployeeDashbord from "./employee/Dashboard";
import AdminDashboard from "./admin/Dashboard";
const App = () => {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("loggedUser")));
  
  let role = JSON.parse(user)?JSON.parse(user).employee.role:"";

  return (
    <Router>
      {role === "employee" ? (
        <EmployeeDashbord user={user} setUser={setUser} />
      ) :role === "admin" ? (
        <AdminDashboard user={user} setUser={setUser} />
      ) : (
        <Login setUser={setUser} />
      )}
     
    </Router>
  );
};

export default App;
