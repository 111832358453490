import React, { useState } from "react";
import { Row, Col, Nav } from "react-bootstrap";
import { Link, Route, Switch } from "react-router-dom";
import Home from "./Home";
import Attendance from "./Attendance";
import Client from "./Client";
import DailyReport from "./DailyReport";
import Department from "./Department";
import Location from "./Location";
import Project from "./Project";
import Module from "./Module";
import QualityControl from "../QualityControl";
import LeaveManagement from "./LeaveManagement";
import Employee from "./Employee";
import Invoice from "./Invoice";
import PaySlip from "./PaySlip";
import Header from "../Header";
const Dashboard = ({ user, setUser }) => {
  const [selection, setSelection] = useState("Dashboard");
  const userId = JSON.parse(user).employee.employee_id;
  return (
    <>
      <Header user={user} setUser={setUser} />

      <Row>
        <Col lg={1} className="side-nav" style={{textAlign:"center"}}>
          <Nav defaultActiveKey="/home" className="flex-column">
            <Link
              to="/home"
              className={selection == "Dashboard" ? "text current" : "text"}
              onClick={() => setSelection("Dashboard")}
            >
              <i className="fa fa-tachometer fa-3x"></i><br/>
              <span>Dashboard</span>
            </Link>
            <Link
              to="/attendance"
              className={selection == "Attendance" ? "text current" : "text"}
              onClick={() => setSelection("Attendance")}
            >
              <i class="fa fa-check-square-o fa-3x"></i><br/>
              <span>Attendance</span>
            </Link>
            <Link
              to="/client"
              className={selection == "Client" ? "text current" : "text"}
              onClick={() => setSelection("Client")}
            >
              <i class="fa fa-handshake-o fa-3x"></i><br/>
              <span>Client</span>
            </Link>
            <Link
              to="/dailyReport"
              className={selection == "Daily Report" ? "text current" : "text"}
              onClick={() => setSelection("Daily Report")}
            >
              <i class="fa fa-bar-chart fa-3x"></i><br/>
              <span>Daily Report</span>
            </Link>
            <Link
              to="/department"
              className={selection == "Department" ? "text current" : "text"}
              onClick={() => setSelection("Department")}
            >
              <i class="fa fa-cubes fa-3x"></i><br/>
              <span>Department</span>
            </Link>
            <Link
              to="/employee"
              className={selection == "Employee" ? "text current" : "text"}
              onClick={() => setSelection("Employee")}
            >
             <i class="fa fa-users fa-3x"></i><br/>
              <span>Employee</span>
            </Link>
            <Link
              to="/invoice"
              className={selection == "Invoice" ? "text current" : "text"}
              onClick={() => setSelection("Invoice")}
            >
             <i class="fa fa-file-text fa-3x"></i><br/>
              <span>Invoice</span>
            </Link>
            <Link
              to="/location"
              className={selection == "Location" ? "text current" : "text"}
              onClick={() => setSelection("Location")}
            >
              <i class="fa fa-map-marker fa-3x"></i><br/>
              <span>Location</span>
            </Link>
            <Link
              to="/project"
              className={selection == "Project" ? "text current" : "text"}
              onClick={() => setSelection("Project")}
            >
              <i class="fa fa-object-ungroup fa-3x"></i><br/>
              <span>Project</span>
            </Link>
            <Link
              to="/module"
              className={selection == "Module" ? "text current" : "text"}
              onClick={() => setSelection("Module")}
            >
            <i class="fa fa-cube fa-3x"></i><br/>
              <span>Module</span>
            </Link>
            <Link
              to="/qualityControl"
              className={selection == "QC" ? "text current" : "text"}
              onClick={() => setSelection("QC")}
            >
              <i class="fa fa-tasks fa-3x"></i><br/>
              <span>Task</span>
            </Link>
            <Link
              to="/leaveRequest"
              className={selection == "LMS" ? "text current" : "text"}
              onClick={() => setSelection("LMS")}
            >
              <i class="fa fa-calendar-times-o fa-3x"></i><br/>
              <span>LMS</span>
            </Link>
            <Link
              to="/paySlip"
              className={selection == "Payslip" ? "text current" : "text"}
              onClick={() => setSelection("Payslip")}
            >
              <i class="fa fa-money fa-3x"></i><br/>
              <span>Payslip</span>
            </Link>
            <br/>
          </Nav>
        </Col>
        <Col lg={11} className="main">
          <Switch>
            <Route exact path="/home" component={Home} />
            <Route exact path="/attendance" component={()=>Attendance(userId)} />
            <Route exact path="/client" component={Client} />
            <Route exact path="/employee" component={Employee} />
            <Route exact path="/dailyReport" component={()=>DailyReport(userId)} />
            <Route exact path="/department" component={Department} />
            <Route exact path="/location" component={Location} />
            <Route exact path="/project" component={Project} />
            <Route exact path="/module" component={Module} />
            <Route exact path="/qualityControl" component={()=>QualityControl(userId)} />
            <Route exact path="/leaveRequest" component={()=>LeaveManagement(userId)} />
            <Route exact path="/invoice" component={()=>Invoice(userId)} />
            <Route exact path="/paySlip" component={()=>PaySlip(userId)} />
          </Switch>
        </Col>
      </Row>
    </>
  );
};
export default Dashboard;
