import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Table,
  span,
  Nav,
  Button,
  Modal,
  Form,
  FormGroup,
  Alert
} from "react-bootstrap";
import api from "../../config/api";
import { getRequestConfig } from "../../utils/getRequestConfig";
// add daily report
const AddDepartment = ({
  showAddDepartment,
  closeAddDepartment,
  addDepartmentHandler,
  message
}) => {
  const [departmentName, setDepartmentName] = useState("");
  const onSubmitHandler = (event) => {
    event.preventDefault();
    addDepartmentHandler(departmentName);
  };
  return (
    <Modal
      show={showAddDepartment}
      onHide={closeAddDepartment}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Add Department</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={onSubmitHandler}>
          <Form.Group>
            <Form.Label>Department Name</Form.Label>
            <span style={{ color: "red" }}>*</span>
            <Form.Control
              type="text"
              placeholder="Department name"
              onChange={(event) => setDepartmentName(event.target.value)}
              required
            />
          </Form.Group>

          <hr />
          {
            message?message:""
          }
          <Button type="submit" className="box secondary">
            Add
          </Button>
          <Button
            style={{ marginLeft: "25px" }}
            className="box"
            onClick={closeAddDepartment}
          >
            Close
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

//edit daily report
const EditDepartment = ({
  showEditDepartment,
  closeEditDepartment,
  editDepartmentHandler,
  department,
  message
}) => {
  const [departmentName, setDepartmentName] = useState(
    department.departmentName
  );

  const departmentId = department.id;

  const onSubmitHandler = (event) => {
    event.preventDefault();
    editDepartmentHandler(departmentId, departmentName);
  };
  return (
    <Modal
      show={showEditDepartment}
      onHide={closeEditDepartment}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Edit Department</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={onSubmitHandler}>
          <Form.Group>
            <Form.Label>Department Name</Form.Label>
            <span style={{ color: "red" }}>*</span>
            <Form.Control
              type="text"
              placeholder="department"
              value={departmentName}
              onChange={(event) => setDepartmentName(event.target.value)}
              required
            />
          </Form.Group>
          <hr />
          {
            message?message:""
          }
          <Button type="submit" className="box secondary">
            Update
          </Button>
          <Button
            style={{ marginLeft: "25px" }}
            className="box"
            onClick={closeEditDepartment}
          >
            Close
          </Button>
        </Form>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
};

// shows all employee daily reports

const DepartmentItems = ({ department, fetchDepartments,setMessage,message }) => {
  const [showEditDepartment, setShowEditDepartment] = useState(false);
  const closeEditDepartment = () => {
    setShowEditDepartment(false);
  };
  const openEditDepartment = () => {
    setShowEditDepartment(true);
  };

  const editDepartmentHandler = (departmentId, departmentName) => {
    let content = { departmentName: departmentName };
    api
      .put(`department/?id=${departmentId}`, content, getRequestConfig())
      .then((response) => {
        setShowEditDepartment(true);
        fetchDepartments();
        setMessage(<Alert variant="success">Department Edited Successfully</Alert>)
    
    }).catch((ex)=>{
      setMessage(<Alert variant="danger">There is some error</Alert>)
    });
  };
  return (
    <>
      <tr>
        <td>{department.departmentName} </td>
        <td>
          <Button style={{ marginRight: "10px" }} onClick={openEditDepartment}>
            Edit Department
          </Button>
        </td>
      </tr>

      <EditDepartment
        showEditDepartment={showEditDepartment}
        closeEditDepartment={closeEditDepartment}
        editDepartmentHandler={editDepartmentHandler}
        department={department}
        message={message}
      />
    </>
  );
};

const Department = () => {
  const [departments, setDepartments] = useState([]);
  const [showAddDepartment, setShowAddDepartment] = useState(false);
  const [message, setMessage] = useState("");
  useEffect(() => {
    fetchDepartments();
  }, []);

  //get task
  const fetchDepartments = () => {
    api.get("department/", getRequestConfig()).then((response) => {
      setDepartments(response.data);
      console.log(response.data);
    });
  };

  const closeAddDepartment = () => {
    setShowAddDepartment(false);
  };
  const openAddDepartment = () => {
    setShowAddDepartment(true);
  };

  const addDepartmentHandler = (departmentName) => {
    let content = { departmentName: departmentName };
    api.post("department/", content, getRequestConfig()).then((response) => {
      setShowAddDepartment(true);
      fetchDepartments();
      setMessage(<Alert variant="success">Department Added Successfully</Alert>)
    
    }).catch((ex)=>{
      setMessage(<Alert variant="danger">There is some error</Alert>)
    });
  };

  setTimeout(()=>{
    setMessage("");
    
  },10000);
  return (
    <div>
      <Row>
        <Col sm={12} md={6} lg={6} style={{ padding: "5px" }}>
          {" "}
          <h3>Department</h3>
        </Col>
        <Col sm={12} md={6} lg={6} style={{ padding: "5px" }}>
          <Button onClick={openAddDepartment} style={{ float: "right" }}>
            Add Department
          </Button>
        </Col>
      </Row>

      <br />
       <Table className="table table-bordered">
        {departments.length === 0 && <h3>Loading...</h3>}
        <thead>
          <tr>
            <th>Department</th>

            <th>Edit</th>
          </tr>
        </thead>
        <tbody>
          {departments.length !== 0 &&
            departments.map((department, index) => (
              <DepartmentItems
                key={index}
                department={department}
                fetchDepartments={fetchDepartments}
                setMessage={setMessage}
                message = {message}
              />
            ))}
        </tbody>
      </Table>
      <AddDepartment
        showAddDepartment={showAddDepartment}
        closeAddDepartment={closeAddDepartment}
        addDepartmentHandler={addDepartmentHandler}
        message = {message}
      />
    </div>
  );
};
export default Department;
